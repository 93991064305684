<template>
  <div
    class="footer-area-wrapper"
    :class="FooterStyle"
    style="background-color: rgb(32, 38, 95); color: #ffffff"
  >
    <div class="footer-area section-space--ptb_30">
      <div class="container">
        <div class="row footer-widget-wrapper">
          <div class="col-lg-2 col-md-2 footer-widget">
            <div style="text-align: center">
              <router-link to="/">
              <!--- <img :src="setting.logo" class="logo-dark" alt="" /> -->
              <!--   <img :src="setting.logo" class="logo-light" alt="" /> --->
                <img
                  src="../assets/img/logo/wilor_logo.png"
                  width="150"
                  height="150"
                  alt="Wilor Education"
                /> 
              </router-link>
            </div>
          </div>
          <div class="col-lg-5 col-md-5 footer-widget">
            <ul class="footer-widget__list">
              <li>{{ setting.address }}</li>
              <li>
                <a href="#" class="hover-style-link">{{ setting.email }}</a>
              </li>
              <li>
                <a href="#" class="hover-style-link">{{ setting.contact }}</a>
              </li>
              <li>
                <a :href="setting.web" class="hover-style-link">{{
                  setting.web
                }}</a>
              </li>
            </ul>
          </div>
          <!--
          <div class="col-lg-2 col-md-2 footer-widget">
            <h6 class="footer-widget__title mb-20">Why</h6>
            <ul class="footer-widget__list">
              <li>
                <router-link :to="{ path: 'contact' }" class="hover-style-link"
                  >Contact Us</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ path: 'admission-process' }"
                  class="hover-style-link"
                  >Application Process</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ path: 'global-branches' }"
                  class="hover-style-link"
                  >Branch</router-link
                >
              </li>
              <li><a href="#" class="hover-style-link">FAQ</a></li>
            </ul>
          </div>
          -->

          <div class="col-lg-2 col-md-2 footer-widget">
            <h6 class="footer-widget__title mb-20" style="color: #ffffff">
              Quick links
            </h6>
            <ul class="footer-widget__list">
              <li>
                <router-link :to="{ path: 'courses' }" class="hover-style-link"
                  >Find A Course</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ path: 'universities' }"
                  class="hover-style-link"
                  >Find An University</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ path: 'scholarship' }"
                  class="hover-style-link"
                  >Scholarship</router-link
                >
              </li>
              <!-- <li><a href="#" class="hover-style-link">Where to Find Us</a></li> -->
            </ul>
          </div>

          <div class="col-lg-3 col-md-3 footer-widget"></div>

          <!-- <div class="col-lg-3 col-md-6 offset-lg-1 footer-widget">
            <div class="twitter-feed-slider">
              <div class="swiper-container twitter-feed-slider__container">
                <div class="swiper-wrapper twitter-feed-slider__wrapper">
                  <div class="swiper-slide twitter-feed-slider__single">
                    <div class="tweet">
                      <div class="tweet__text">
                        Nile Business Centre, 56-60 Nelson Street,2nd floor,
                        suite 221, London, E1 2DE , United Kingdon,Tel:
                        +442070181970
                      </div>
                      <div class="tweet__info">
                        <h6 class="tweet__heading">Wilor</h6>
                        <span class="tweet__date">May 07, 2020</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div
      class="footer-copyright-area border-top section-space--ptb_10"
      style="background-color: rgb(30 136 229; color: #000000"
    >
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 text-center text-md-left">
            <span class="copyright-text">{{ setting.footer_text }}</span>
          </div>
          <div class="col-md-6 text-center text-md-right">
            <ul class="list ht-social-networks solid-rounded-icon">
              <li class="item">
                <!--  <a
                  :href="setting.facebook_link"
                  target="_blank"
                  class="social-link"
                >
                --->
                <!--   <a href="https://www.facebook.com/Wiloreducation"
                    target="_blank"
                    class="social-link"
                  >

                  <i class="fab fa-facebook social-link-icon"></i>
                </a>
              </li> --->
              </li>

              <li class="item">
                <!--  <a
                  :href="setting.twitter_link"
                  target="_blank"
                  class="social-link"
                > --->

                <!--  <a href="https://twitter.com/EducationWilor" target="_blank" class="social-link">

                  <i class="fab fa-twitter social-link-icon"></i>
                </a> --->
              </li>
              <li class="item">
                <!--  <a
                  :href="setting.instagram_link"
                  target="_blank"
                  class="social-link"
                > --->
                <!--  <a href="https://www.instagram.com/wilor_education/" target="_blank" class="social-link">
                  <i class="fab fa-instagram social-link-icon"></i>
                </a> --->
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- social media  -->
    <div class="icon-bar-right" style="z-index: 100" id="desktop">
      <a
        href="https://www.facebook.com/Wiloreducation"
        class="facebook"
        style="background-color: #3b5998"
        target="_blank"
        ><img src="../assets/img/social/fb_icon.png"
      /></a>
      <a
        href="https://twitter.com/EducationWilor"
        style="background-color: #1da1f2"
        target="_blank"
        ><img src="../assets/img/social/twitter_icon.png"
      /></a>
      <a
        href="https://www.instagram.com/wilor_education/"
        style="background-color: #3f729b"
        target="_blank"
        ><img src="../assets/img/social/instagram_icon.png"
      /></a>

      <a
        href="https://www.messenger.com/t/615110659142025"
        style="background-color: rgb(30, 136, 229)"
        target="_blank"
        ><svg
          class="ico_d"
          width="39"
          height="39"
          viewBox="0 0 39 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style="transform: rotate(0deg)"
        >
          <circle
            class="color-element"
            cx="19.4395"
            cy="19.4395"
            r="19.4395"
            fill="#1E88E5"
          ></circle>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 9.63934C0 4.29861 4.68939 0 10.4209 0C16.1524 0 20.8418 4.29861 20.8418 9.63934C20.8418 14.98 16.1524 19.2787 10.4209 19.2787C9.37878 19.2787 8.33673 19.1484 7.42487 18.8879L3.90784 20.8418V17.1945C1.56311 15.3708 0 12.6353 0 9.63934ZM8.85779 10.1604L11.463 13.0261L17.1945 6.90384L12.1143 9.76959L9.37885 6.90384L3.64734 13.0261L8.85779 10.1604Z"
            transform="translate(9.01854 10.3146)"
            fill="white"
          ></path></svg
      ></a>

      <a
        href="https://api.whatsapp.com/send?phone=447421991681&fbclid=IwAR2OE4Yz4czNZZsxi7XUC1C_AtNhvouHu7Ahy7vPF5_c_FwkUXLWArKCMmw"
        style="background-color: rgb(73, 230, 112)"
        target="_blank"
        ><svg
          class="ico_d"
          width="39"
          height="39"
          viewBox="0 0 39 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style="transform: rotate(0deg)"
        >
          <circle
            class="color-element"
            cx="19.4395"
            cy="19.4395"
            r="19.4395"
            fill="#49E670"
          ></circle>
          <path
            d="M12.9821 10.1115C12.7029 10.7767 11.5862 11.442 10.7486 11.575C10.1902 11.7081 9.35269 11.8411 6.84003 10.7767C3.48981 9.44628 1.39593 6.25317 1.25634 6.12012C1.11674 5.85403 2.13001e-06 4.39053 2.13001e-06 2.92702C2.13001e-06 1.46351 0.83755 0.665231 1.11673 0.399139C1.39592 0.133046 1.8147 1.01506e-06 2.23348 1.01506e-06C2.37307 1.01506e-06 2.51267 1.01506e-06 2.65226 1.01506e-06C2.93144 1.01506e-06 3.21063 -2.02219e-06 3.35022 0.532183C3.62941 1.19741 4.32736 2.66092 4.32736 2.79397C4.46696 2.92702 4.46696 3.19311 4.32736 3.32616C4.18777 3.59225 4.18777 3.59224 3.90858 3.85834C3.76899 3.99138 3.6294 4.12443 3.48981 4.39052C3.35022 4.52357 3.21063 4.78966 3.35022 5.05576C3.48981 5.32185 4.18777 6.38622 5.16491 7.18449C6.42125 8.24886 7.39839 8.51496 7.81717 8.78105C8.09636 8.91409 8.37554 8.9141 8.65472 8.648C8.93391 8.38191 9.21309 7.98277 9.49228 7.58363C9.77146 7.31754 10.0507 7.1845 10.3298 7.31754C10.609 7.45059 12.2841 8.11582 12.5633 8.38191C12.8425 8.51496 13.1217 8.648 13.1217 8.78105C13.1217 8.78105 13.1217 9.44628 12.9821 10.1115Z"
            transform="translate(12.9597 12.9597)"
            fill="#FAFAFA"
          ></path>
          <path
            d="M0.196998 23.295L0.131434 23.4862L0.323216 23.4223L5.52771 21.6875C7.4273 22.8471 9.47325 23.4274 11.6637 23.4274C18.134 23.4274 23.4274 18.134 23.4274 11.6637C23.4274 5.19344 18.134 -0.1 11.6637 -0.1C5.19344 -0.1 -0.1 5.19344 -0.1 11.6637C-0.1 13.9996 0.624492 16.3352 1.93021 18.2398L0.196998 23.295ZM5.87658 19.8847L5.84025 19.8665L5.80154 19.8788L2.78138 20.8398L3.73978 17.9646L3.75932 17.906L3.71562 17.8623L3.43104 17.5777C2.27704 15.8437 1.55796 13.8245 1.55796 11.6637C1.55796 6.03288 6.03288 1.55796 11.6637 1.55796C17.2945 1.55796 21.7695 6.03288 21.7695 11.6637C21.7695 17.2945 17.2945 21.7695 11.6637 21.7695C9.64222 21.7695 7.76778 21.1921 6.18227 20.039L6.17557 20.0342L6.16817 20.0305L5.87658 19.8847Z"
            transform="translate(7.7758 7.77582)"
            fill="white"
            stroke="white"
            stroke-width="0.2"
          ></path>
        </svg>
      </a>
    </div>
    <!-- social media  -->
  </div>
</template>

<script>
export default {
  name: "FooterMain",
  props: ["FooterStyle"],

  data() {
    return {
      setting: {},
    };
  },

  mounted() {
    this.getSetting();
  },
  methods: {
    getSetting() {
      axios.get("setting").then((response) => {
        this.setting = response.data.data.setting;
      });
    },
  },
};
</script>
<style scoped>
.footer-widget__logo img {
  width: 120px;
}
@media screen and (min-width: 720px) {
  #desktop {
    display: block;
  }
  .icon-bar-right {
    position: fixed;
    top: 90%;
    right: 0%;
    -webkit-transform: translateY(-99%);
    -ms-transform: translateY(-99%);
    transform: translateY(-99%);
  }

  .icon-bar-right a:hover {
    background-color: #000;
  }
}

.icon-bar-right a {
  display: block;
  text-align: center;
  padding: 7px 10px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

/* .facebook {
} */

.icon-bar-right a[data-v-7ba5bd90] {
  display: block;
  text-align: center;
  padding: 7px 10px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}
</style>
