<template>
  <div>
    <HeaderElement />
    <div class="banner-cutom">
      <img
        src="../assets/img/bg/wilor-blog-and-news.jpeg"
        alt=""
        width="100%"
      />
    </div>
    <div class="section-title-wrapper text-center mb-1 mt-3">
      <h2 class="section-title mb-30">Blog & News</h2>
    </div>
    <div class="container">
      <div class="blog-pages-wrapper section-space--ptb_100 border-bottom">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 mr-auto ml-auto">
              <div class="main-blog-wrap">
                <div
                  v-for="(value, index) in articles"
                  :key="index"
                  class="
                    single-blog-item
                    lg-blog-item
                    border-bottom
                    wow
                    move-up
                  "
                  style="visibility: visible"
                >
                  <div class="post-feature blog-thumbnail">
                    <img
                      :src="value.image_path"
                      alt="Blog Images"
                      class="img-fluid"
                    />
                  </div>
                  <div class="post-info lg-blog-post-info">
                    <h4 class="post-title font-weight--light">
                      <router-link
                        :to="{ name: 'BlogDetails', params: { id: value.id } }"
                      >
                        {{ value.title }}
                      </router-link>
                    </h4>
                    <div class="post-meta mt-20">
                      <div
                        class="post-author"
                        style="padding: 5px; margin-top: -5px"
                      >
                        <a href="#">{{ value.author }}</a>
                      </div>
                      <div class="post-date">
                        <span class="far fa-calendar meta-icon"></span>
                        {{ value.created_at | formatDate }}
                      </div>
                    </div>
                    <div class="post-excerpt mt-15">
                      <div v-html="value.short_details"></div>
                    </div>
                    <div class="post-read-more mt-20 mb-20">
                      <router-link
                        :to="{ name: 'BlogDetails', params: { id: value.id } }"
                        class="ht-btn ht-btn-sm"
                        ><span class="btn-text">Read more </span>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <div
                class="
                  page-sidebar-content-wrapper page-sidebar-right
                  small-mt__40
                  tablet-mt__40
                "
              >
                <div
                  class="sidebar-widget widget-blog-post wow move-up"
                  style="visibility: visible"
                >
                  <h5 class="widget-title font-weight--light">Categories</h5>
                  <hr />
                  <div
                    v-for="(cat, index) in categories"
                    :key="index"
                    class="post-item"
                  >
                    <div class="post-info">
                      <h6 class="post-title">
                        <router-link :to="`/blog-news?category=${cat.id}`">
                          {{ cat.category_name }}
                        </router-link>
                      </h6>
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- footer section -->
    <FooterMain />

    <!-- offcanvas search component -->
    <OffcanvasSearchBox />
  </div>
</template>

<script>
import HeaderElement from "@/components/HeaderElementOthers";
import CourseBoxOne from "@/components/CourseBoxOne";
import CourseBoxTwo from "@/components/CourseBoxTwo";
import UniversityTab from "@/components/UniversityTab";
import Newsletter from "@/components/Newsletter";
import FooterMain from "@/components/FooterMain";
import OffcanvasSearchBox from "@/components/OffcanvasSearchBox";
import Accordion from "@/components/AdmissionAcordin";
import SideBarArea from "@/components/SideBarArea";
import moment from "moment";
export default {
  components: {
    HeaderElement,
    CourseBoxOne,
    CourseBoxTwo,
    UniversityTab,
    Newsletter,
    FooterMain,
    OffcanvasSearchBox,
    Accordion,
    SideBarArea,
  },
  data() {
    return {
      categories: [],
      articles: null,
      propular_articles: null,
    };
  },
  watch: {
    $route(to, from) {
      // react to route changes...
      this.getBlogs();
    },
  },
  mounted() {
    this.getBlogs();
    this.getCategory();
  },
  methods: {
    getBlogs() {
      axios
        .get("articles?category=" + this.$route.query.category || "")
        .then((response) => {
          this.articles = response.data.data.articles;
          this.propular_articles = response.data.data.popular_articles;
        });
    },
    getCategory() {
      axios.get("articale-category").then((response) => {
        this.categories = response.data.data.category;
      });
    },
  },
};
</script>

<style scoped>
.section-title-wrapper h2 {
  font-size: 2.3rem !important;
}
.single-blog-item .post-info .post-meta {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.page-sidebar-right {
  padding-left: 30px;
}
.sidebar-widget .sidebar-widget {
  margin-top: 40px;
}
.single-blog-item .post-info {
  margin-top: 20px;
}
.single-blog-item .post-info .post-title {
  margin-bottom: 15px;
}
</style>
