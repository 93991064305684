<template>
  <div class="ht-tab-wrap">
    <UniversityTabContentUkUni />
  </div>
</template>

<script>
import UniversityTabContentUkUni from "@/components/UniversityTabContentUkUni";
import UniversityTabContentAusCanada from "@/components/UniversityTabContentAusCanada";
import UniversityTabContentUkColleges from "@/components/UniversityTabContentUkColleges";

export default {
  name: "TabOne",
  components: {
    UniversityTabContentUkUni,
    UniversityTabContentAusCanada,
    UniversityTabContentUkColleges,
  },
  data() {
    return {
      active: "active",
    };
  },
};
</script>