<template>
  <div>
    <HeaderElement />
    <div class="banner-cutom" >
      <img src="../assets/img/bg/wilor-study-abroad.jpeg" alt="" width="100%"  />
    </div>
    <div class="section-title-wrapper text-center mb-1 mt-3">
      <h2 class="section-title mb-30">Find A Course</h2>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <p>
            {{ text_content.value }}
          </p>
          <div v-for="(value, index) in bullet_content" :key="index">
            <p>
              <b>{{ value.title }}</b>
            </p>
            <ul class="check-list green-check-list section-space--mb_50">
              <li
                class="list-item"
                v-for="bullet in value.clist"
                :key="bullet.id + 'bullet'"
              >
                {{ bullet.value }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="pricing-table-area section-space--ptb_70">
            <div class="pricing-table-content-area">
              <div class="container">
                <!-- pricing component -->
                <CourseBoxOne />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- footer section -->
    <FooterMain />

    <!-- offcanvas search component -->
    <OffcanvasSearchBox />
  </div>
</template>

<script>
import HeaderElement from "@/components/HeaderElementOthers";
import CourseBoxOne from "@/components/CourseBoxOne";
import CourseBoxTwo from "@/components/CourseBoxTwo";
import CourseTab from "@/components/CourseTab";
import Newsletter from "@/components/Newsletter";
import FooterMain from "@/components/FooterMain";
import OffcanvasSearchBox from "@/components/OffcanvasSearchBox";
export default {
  components: {
    HeaderElement,
    CourseBoxOne,
    CourseBoxTwo,
    CourseTab,
    Newsletter,
    FooterMain,
    OffcanvasSearchBox,
  },
  data() {
    return {
      text_content: { value: "" },
      bullet_content: [],
    };
  },
  mounted() {
    this.getTextContent();
    this.getBulletContent();
  },
  methods: {
    getTextContent() {
      axios.get("course-content?is_point=0").then((response) => {
        this.text_content = response.data.data.course_content;
      });
    },
    getBulletContent() {
      axios.get("course-content?is_point=1").then((response) => {
        this.bullet_content = response.data.data.course_content; 
      });
    },
  },
};
</script>

<style scoped>
.section-title-wrapper h2 {
  font-size: 2.3rem !important;
}
</style>
