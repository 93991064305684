<template>
  <div>
    <HeaderElement />
    <div class="banner-cutom">
      <img src="../assets/img/bg/whyus.jpeg" alt="" width="100%" />
    </div>
    <div class="section-title-wrapper text-center mb-1 mt-3">

    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-4">
            <div class="ht-team-member text-center">

              <div>
                  <!-- team-image Start -->
                  <div class="team-image">
                    <img
                      class="img-fluid"
                      src="../assets/img/team/wilor-rajib.png"
                      alt="avatar 01"
                    />
                  </div>
                  <!-- team-image End -->
                  <!-- team-info Start -->
                  <div class="team-info text-center">
                    <h5 class="name">Rajib Ahmed</h5>
                    <div class="social-networks-rounded mt-10">
                      <ul class="list ht-social-networks solid-rounded-icon">
                        <li class="item">
                          <a
                            target="_blank"
                            href="https://facebook.com/Wiloreducation/"
                            class="social-link hint--bounce hint--top hint--primary"
                            aria-label="Facebook"
                            ><i class="fab fa-facebook"></i
                          ></a>
                        </li>
                        <li class="item">
                          <a
                            target="_blank"
                            href="https://twitter.com/educationwilor"
                            class="social-link hint--bounce hint--top hint--primary"
                            aria-label="Twitter"
                            ><i class="fab fa-twitter"></i
                          ></a>
                        </li>
                        <li class="item">
                          <a
                            target="_blank"
                            href="https://www.instagram.com/wilor_education/"
                            class="social-link hint--bounce hint--top hint--primary"
                            aria-label="Instagram"
                            ><i class="fab fa-instagram"></i
                          ></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- team-info End -->
                </div>
              </div>
            </div>
            <div class="col-md-8 mb-5" style="font-size: large;  color: #000000;">
            <h4 class="section-title mb-30">Message from Managing Director

            </h4>
              <p align="justify">
              Wilor Education is committed to providing excellent service to students who wish to enrol
              themselves in universities and colleges around the world.
              </p>
              <p align="justify">We provide a reliable service as we have an excellent team who have prior knowledge and experience
              in the education sector. Thus, we are able to support students in every aspect during their student admission. </p>
              <p>We are driven to providing excellent and reliable service because we “believe in success”. </p>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 text-center mb-3">
      <a data-v-04e266c5="" href="/contact" class="ht-btn ht-btn-md"
        >Request a call back</a
      >
    </div>

    <!-- footer section -->
    <FooterMain />

    <!-- offcanvas search component -->
    <OffcanvasSearchBox />
  </div>
</template>

<script>
import HeaderElement from "@/components/HeaderElementOthers";
import CourseBoxOne from "@/components/CourseBoxOne";
import CourseBoxTwo from "@/components/CourseBoxTwo";
import UniversityTab from "@/components/UniversityTab";
import Newsletter from "@/components/Newsletter";
import FooterMain from "@/components/FooterMain";
import OffcanvasSearchBox from "@/components/OffcanvasSearchBox";
import Accordion from "@/components/AdmissionAcordin";
import SideBarArea from "@/components/SideBarArea";
export default {
  components: {
    HeaderElement,
    CourseBoxOne,
    CourseBoxTwo,
    UniversityTab,
    Newsletter,
    FooterMain,
    OffcanvasSearchBox,
    Accordion,
    SideBarArea,
  },
};
</script>

<style scoped>
.section-title-wrapper h2 {
  font-size: 2.3rem !important;
}
</style>
