<template>
  <div>
    <HeaderElement />
    <div class="banner-cutom">
      <img src="../assets/img/bg/whyus.jpeg" alt="" width="100%" />
    </div>
    <div class="section-title-wrapper text-center mb-1 mt-3">

    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-4">
              <div class="ht-team-member text-center">

                <div>
                  <!-- team-image Start -->
                  <div class="team-image">
                    <img
                      class="img-fluid"
                      src="../assets/img/team/wilor-hasan.png"
                      alt="avatar 01"
                      height="150"
                    />
                  </div>
                  <!-- team-image End -->
                  <!-- team-info Start -->
                  <div class="team-info text-center">
                    <h5 class="name">Mohammad Hasan</h5>
                    <div class="social-networks-rounded mt-10">
                      <ul class="list ht-social-networks solid-rounded-icon">
                        <li class="item">
                          <a
                            target="_blank"
                            href="https://facebook.com/Wiloreducation/"
                            class="social-link hint--bounce hint--top hint--primary"
                            aria-label="Facebook"
                            ><i class="fab fa-facebook"></i
                          ></a>
                        </li>
                        <li class="item">
                          <a
                            target="_blank"
                            href="https://twitter.com/educationwilor"
                            class="social-link hint--bounce hint--top hint--primary"
                            aria-label="Twitter"
                            ><i class="fab fa-twitter"></i
                          ></a>
                        </li>
                        <li class="item">
                          <a
                            target="_blank"
                            href="https://www.instagram.com/wilor_education/"
                            class="social-link hint--bounce hint--top hint--primary"
                            aria-label="Instagram"
                            ><i class="fab fa-instagram"></i
                          ></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- team-info End -->
                </div>
              </div>
            </div>
            <div class="col-md-8 mb-5" style="font-size: large;  color: #000000;">
            <h4 class="section-title mb-30">Message from CEO

            </h4>

              <p align="justify">
                Education is the only way to develop as a human being and to become civilised in modern society.
                The course of history from the prehistoric age to today’s digital world presents evolution that has been achieved
                through education. Wilor Education plates an important part in education by connecting students with many educational
                institutions around the world.
              </p>
              <p align="justify">
              We are committed to recruiting students all over the world and enrolling them to the many universities and colleges that
              we are associated with, in order for the student to gain practical and insightful experience.  </p>
              <p align="justify">
                It is our responsibility to present to students with many opportunities so that they can get the best education and achieve
                the best careers that they can.
              </p>
              <p align="justify">Finally, our motto, “Believe in Success” is a testimony to our values and commitments. We put the student
              and their needs first.</p>



            </div>



          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 text-center mb-3">
      <a data-v-04e266c5="" href="/contact" class="ht-btn ht-btn-md"
        >Request a call back</a
      >
    </div>

    <!-- footer section -->
    <FooterMain />

    <!-- offcanvas search component -->
    <OffcanvasSearchBox />
  </div>
</template>

<script>
import HeaderElement from "@/components/HeaderElementOthers";
import CourseBoxOne from "@/components/CourseBoxOne";
import CourseBoxTwo from "@/components/CourseBoxTwo";
import UniversityTab from "@/components/UniversityTab";
import Newsletter from "@/components/Newsletter";
import FooterMain from "@/components/FooterMain";
import OffcanvasSearchBox from "@/components/OffcanvasSearchBox";
import Accordion from "@/components/AdmissionAcordin";
import SideBarArea from "@/components/SideBarArea";
export default {
  components: {
    HeaderElement,
    CourseBoxOne,
    CourseBoxTwo,
    UniversityTab,
    Newsletter,
    FooterMain,
    OffcanvasSearchBox,
    Accordion,
    SideBarArea,
  },
};
</script>

<style scoped>
.section-title-wrapper h2 {
  font-size: 2.3rem !important;
}
</style>
