<template>
    <div class="team-member-wrapper section-space--pt_0">
        <div class="container">

            <div class="row">

        <!--

        <div class="col-lg-3 col-md-6">
                <div class="grid-item">
                    <div class="ht-team-member text-center">
                        <div class="team-image">
                            <img class="img-fluid" src="../assets/img/team/wilor-shilpi-ceo.png" alt="">
                        </div>
                        <div class="team-info text-center">
                            <h5 class="name"> Nurjahan Shilpi</h5>
                            <div class="position">CEO</div>
                            <div class="social-networks">
                                <div class="inner">
                                    <a href="https://www.facebook.com/Wiloreducation" target="_blank" class=" hint--bounce hint--top  hint--primary" aria-label="Facebook"><i class="fab fa-facebook"></i></a>
                                    <a href="https://twitter.com/EducationWilor" target="_blank" class=" hint--bounce hint--top hint--primary" aria-label="Twitter"><i class="fab fa-twitter"></i></a>
                                    <a href="https://www.instagram.com/wilor_education/" target="_blank" class=" hint--bounce hint--top hint--primary" aria-label="Instagram"><i class="fab fa-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            -->

                <div class="col-lg-3 col-md-6">
                    <div class="grid-item">
                        <div class="ht-team-member text-center">
                            <div class="team-image">
                                <img class="img-fluid" src="../assets/img/team/wilor-hasan.png" alt="">
                            </div>
                            <div class="team-info text-center">
                                <h5 class="name">Mohammad Hasan</h5>
                                <div class="position">CEO</div>
                                <div class="social-networks">
                                    <div class="inner">
                                        <a href="https://www.facebook.com/Wiloreducation" target="_blank" class=" hint--bounce hint--top  hint--primary" aria-label="Facebook"><i class="fab fa-facebook"></i>
                                        </a>
                                        <a href="https://twitter.com/EducationWilor" target="_blank" class=" hint--bounce hint--top hint--primary" aria-label="Twitter"><i class="fab fa-twitter"></i>
                                        </a>
                                        <a href="https://www.instagram.com/wilor_education/" target="_blank" class=" hint--bounce hint--top hint--primary" aria-label="Instagram"><i class="fab fa-instagram"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="grid-item">
                        <div class="ht-team-member text-center">
                            <div class="team-image">
                                <img class="img-fluid" src="../assets/img/team/wilor-rajib.png" alt="">
                            </div>
                            <div class="team-info text-center">
                                <h5 class="name">Rajib Ahmed</h5>
                                <div class="position"> Managing Director  </div>
                                <div class="social-networks">
                                    <div class="inner">
                                        <a href="https://www.facebook.com/Wiloreducation" class=" hint--bounce hint--top  hint--primary" aria-label="Facebook"><i class="fab fa-facebook"></i></a>
                                        <a href="https://twitter.com/EducationWilor" target="_blank" class=" hint--bounce hint--top hint--primary" aria-label="Twitter"><i class="fab fa-twitter"></i></a>
                                        <a href="https://www.instagram.com/wilor_education/" target="_blank" class=" hint--bounce hint--top hint--primary" aria-label="Instagram"><i class="fab fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="grid-item">
                        <div class="ht-team-member text-center">
                            <div class="team-image">
                                <img class="img-fluid" src="../assets/img/team/wilor-saif.png" alt="">
                            </div>
                            <div class="team-info text-center">
                                <h5 class="name">Saif Bhuiyan </h5>
                                <div class="position">Head of Operation</div>
                                <div class="social-networks">
                                    <div class="inner">
                                       <a href="https://www.facebook.com/Wiloreducation" target="_blank" class=" hint--bounce hint--top  hint--primary" aria-label="Facebook"><i class="fab fa-facebook"></i></a>
                                        <a href="https://twitter.com/EducationWilor" target="_blank" class=" hint--bounce hint--top hint--primary" aria-label="Twitter"><i class="fab fa-twitter"></i></a>
                                        <a href="https://www.instagram.com/wilor_education/" target="_blank" class=" hint--bounce hint--top hint--primary" aria-label="Instagram"><i class="fab fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

<!---
                <div class="col-lg-3 col-md-6">
                    <div class="grid-item">
                        <div class="ht-team-member text-center">
                            <div class="team-image">
                                <img class="img-fluid" src="../assets/img/team/wilor-nadia.png" alt="">
                            </div>
                            <div class="team-info text-center">
                                <h5 class="name">Nadia Sultana</h5>
                                <div class="position">Senior Education Consultant</div>
                                <div class="social-networks">
                                    <div class="inner">
                                       <a href="https://www.facebook.com/Wiloreducation" target="_blank" class=" hint--bounce hint--top  hint--primary" aria-label="Facebook"><i class="fab fa-facebook"></i></a>
                                        <a href="https://twitter.com/EducationWilor" target="_blank" class=" hint--bounce hint--top hint--primary" aria-label="Twitter"><i class="fab fa-twitter"></i></a>
                                        <a href="https://www.instagram.com/wilor_education/" target="_blank" class=" hint--bounce hint--top hint--primary" aria-label="Instagram"><i class="fab fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

--->
                <div class="col-lg-3 col-md-6">
                    <div class="grid-item">
                        <div class="ht-team-member text-center">
                            <div class="team-image">
                                <img class="img-fluid" src="../assets/img/team/wilor-damian.png" alt="">
                            </div>
                            <div class="team-info text-center">
                                <h5 class="name">Damian Duda </h5>
                                <div class="position">Education Consultant</div>
                                <div class="social-networks">
                                    <div class="inner">
                                       <a href="https://www.facebook.com/Wiloreducation" target="_blank" class=" hint--bounce hint--top  hint--primary" aria-label="Facebook"><i class="fab fa-facebook"></i></a>
                                        <a href="https://twitter.com/EducationWilor" target="_blank" class=" hint--bounce hint--top hint--primary" aria-label="Twitter"><i class="fab fa-twitter"></i></a>
                                        <a href="https://www.instagram.com/wilor_education/" target="_blank" class=" hint--bounce hint--top hint--primary" aria-label="Instagram"><i class="fab fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="col-lg-3 col-md-6">
                    <div class="grid-item">
                        <div class="ht-team-member text-center">
                            <div class="team-image">
                                <img class="img-fluid" src="../assets/img/team/wilor-maria.png" alt="">
                            </div>
                            <div class="team-info text-center">
                                <h5 class="name">Maria Nusa Gingu </h5>
                                <div class="position">Marketing Officer</div>
                                <div class="social-networks">
                                    <div class="inner">
                                       <a href="https://www.facebook.com/Wiloreducation" target="_blank" class=" hint--bounce hint--top  hint--primary" aria-label="Facebook"><i class="fab fa-facebook"></i></a>
                                        <a href="https://twitter.com/EducationWilor" target="_blank" class=" hint--bounce hint--top hint--primary" aria-label="Twitter"><i class="fab fa-twitter"></i></a>
                                        <a href="https://www.instagram.com/wilor_education/" target="_blank" class=" hint--bounce hint--top hint--primary" aria-label="Instagram"><i class="fab fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                                <div class="col-lg-3 col-md-6">
                                    <div class="grid-item">
                                        <div class="ht-team-member text-center">
                                            <div class="team-image">
                                                <img class="img-fluid" src="../assets/img/team/rajib-wilor.png" alt="">
                                            </div>
                                            <div class="team-info text-center">
                                                <h5 class="name">Rajib Khan</h5>
                                                <div class="position">Digital Marketing Assistant</div>
                                                <div class="social-networks">
                                                    <div class="inner">
                                                       <a href="https://www.facebook.com/Wiloreducation" target="_blank" class=" hint--bounce hint--top  hint--primary" aria-label="Facebook"><i class="fab fa-facebook"></i></a>
                                                        <a href="https://twitter.com/EducationWilor" target="_blank" class=" hint--bounce hint--top hint--primary" aria-label="Twitter"><i class="fab fa-twitter"></i></a>
                                                        <a href="https://www.instagram.com/wilor_education/" target="_blank" class=" hint--bounce hint--top hint--primary" aria-label="Instagram"><i class="fab fa-instagram"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>




            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TeamOne',
    }
</script>
