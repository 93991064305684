<template>
  <div>
    <Toolbar />
    <b-navbar
      toggleable="xl"
      fixed="top"
      v-b-scrollspy:nav-scroller
      class="header-area"
      :class="{ 'is-sticky header-area-scroll': scrolled }"
    >
      <div class="container-fluid container-fluid--cp-150" style="background-color: rgb(14, 16, 64) !important;">
        <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
        <b-navbar-brand class="navbar-brand" to="/">
          <!-- dynamic logo here  -->
          <!-- <img :src="logo_path" alt="logo"/> -->

          <!-- static logo here  -->
          <img src="../assets/img/logo/wilor logo alt new - Copy.png" alt="logo"  style="width: 130px !important; "/>
        </b-navbar-brand>
        <!-- <b-navbar-brand class="navbar-brand logo-dark" to="/branding">
                <img :src='"../assets/img/logo/logo-dark.png"' alt="logo">
            </b-navbar-brand> -->
        <b-collapse
          class="default-nav justify-content-center"
          is-nav
          id="nav_collapse"
        >
          <b-navbar-nav class="navbar-nav main-menu text-white">
            <b-nav-item to="/"><span style="color: rgb(0, 123, 255);">HOME</span></b-nav-item>
            <b-nav-item-dropdown toggle-class="text-primary" text="STUDY ABROAD">
              <b-dropdown-item to="/courses">Find A Course</b-dropdown-item>
              <b-dropdown-item to="/universities"
                >Find A University</b-dropdown-item
              >
              <b-dropdown-item to="/scholarship">Scholarship</b-dropdown-item>
              <b-dropdown-item to="/help-support"
                >Help & Support</b-dropdown-item
              >
              <b-dropdown-item to="/partner-university-services"
                >University Ranking</b-dropdown-item
              >
              <b-dropdown-item to="/admission-process"
                >Application Process</b-dropdown-item
              >
            </b-nav-item-dropdown>
            <b-nav-item-dropdown toggle-class="text-primary" text="SERVICES">
              <b-dropdown-item
                v-for="value in serviceList"
                :key="value.title"
                :to="{ name: 'AllService', params: { id: value.id } }"
                >{{ value.title }}</b-dropdown-item
              >
            </b-nav-item-dropdown>
            <b-nav-item-dropdown toggle-class="text-primary" text="WHY US">
              <!-- <b-dropdown-item to="/" >Who We Are</b-dropdown-item> -->
              <b-dropdown-item to="/message-from-the-ceo"
                >Message from the CEO</b-dropdown-item
              >
              <b-dropdown-item to="/message-from-the-director"
                >Message from the Managing Director</b-dropdown-item
              >
              <!-- <b-dropdown-item to="/message-from-the-director" >Message from the Compliance Director</b-dropdown-item> -->
              <!-- <b-dropdown-item to="/message-from-the-adviser" >Message from our Adviser</b-dropdown-item> -->
              <!--  <b-dropdown-item to="/job-opportunity"
              >Job Opportunity</b-dropdown-item
            > --->
              <b-dropdown-item to="/who-we-are">Who We are</b-dropdown-item>
              <b-dropdown-item to="/team-member">Team Members</b-dropdown-item>
              <!---    <b-dropdown-item to="/achivement">Achievements</b-dropdown-item> ---->
              <!-- <b-dropdown-item to="/" >Our Partner List</b-dropdown-item> -->
              <!---  <b-dropdown-item to="/testimonials">Testimonials</b-dropdown-item> --->
              <b-dropdown-item to="/professional-recognition"
                >Professional Recognitions</b-dropdown-item
              >
              <!---    <b-dropdown-item to="#">Legal Aid</b-dropdown-item> ---->
            </b-nav-item-dropdown>

            <b-nav-item-dropdown toggle-class="text-primary" text="BRANCHES">
              <b-dropdown-item to="/uk-head-office"
                >UK Head Office</b-dropdown-item
              >
              <b-dropdown-item to="/global-branches"
                >Branches of Wilor Education</b-dropdown-item
              >
            </b-nav-item-dropdown>
            <b-nav-item to="/blog-news"><span style="color: rgb(0, 123, 255);">BLOG & NEWS</span></b-nav-item>
            <b-nav-item to="/contact"><span style="color: rgb(0, 123, 255);">CONTACT US</span></b-nav-item>
          </b-navbar-nav>
        </b-collapse>
        <b-btn-group class="header-button button--white d-none d-xl-block">
        </b-btn-group>
      </div>
    </b-navbar>
  </div>
</template>

<script>
import Toolbar from "@/components/Toolbar";

export default {
  name: "Navbar",
  components: {
    Toolbar,
  },
  data() {
    return {
      load: false,
      limitPosition: 200,
      scrolled: false,
      lastPosition: 500,
      logo_path: "",
      serviceList: [],
    };
  },
  mounted() {
    // this.printElement(),
    this.getLogo();
    this.getServiceList();

    (function () {
      scrollTo();
    })();

    function scrollTo() {
      const links = document.querySelectorAll(".scroll > a");
      links.forEach((each) => (each.onclick = scrollAnchors));
    }

    function scrollAnchors(e, respond = null) {
      const distanceToTop = (el) => Math.floor(el.getBoundingClientRect().top);
      e.preventDefault();
      var targetID = respond
        ? respond.getAttribute("href")
        : this.getAttribute("href");
      const targetAnchor = document.querySelector(targetID);
      if (!targetAnchor) return;
      const originalTop = distanceToTop(targetAnchor);
      window.scrollBy({ top: originalTop, left: 0, behavior: "smooth" });
      const checkIfDone = setInterval(function () {
        const atBottom =
          window.innerHeight + window.pageYOffset >=
          document.body.offsetHeight - 190;
        if (distanceToTop(targetAnchor) === 0 || atBottom) {
          targetAnchor.tabIndex = "-1";
          targetAnchor.focus();
          //window.history.pushState('', '', targetID);
          clearInterval(checkIfDone);
        }
      }, 800);
    }
  },
  methods: {
    getLogo() {
      axios.get("setting").then((response) => {
        this.logo_path = response.data.data.setting.logo;
      });
    },
    getServiceList() {
      axios.get("service-page").then((response) => {
        this.serviceList = response.data.data.service_page;
      });
    },
    printElement() {
      var el = document.getElementsByAttributeName("[aria-expanded = false]");
      console.log("element", el);
    },
    // sticky menu script
    handleScroll() {
      if (
        this.lastPosition < window.scrollY &&
        this.limitPosition < window.scrollY
      ) {
        this.scrolled = true;
        // move up!
      }

      if (this.lastPosition > window.scrollY) {
        this.scrolled = true;
        // move down
      }

      this.lastPosition = window.scrollY;
      this.scrolled = window.scrollY > 50;
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style scoped>
.navbar-light.text-white .main-menu .nav-item .nav-link {
  color: rgba(0, 0, 0, 0.7) !important;
}
.header-area-scroll {
  background: rgba(255, 255, 255, 0.7) !important;
}
.header-area {
  background: rgb(14, 16, 64) !important;
  top: 30px;
}


button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
    cursor: pointer;
    background-color: rgb(90, 234, 254) !important;
}
</style>
