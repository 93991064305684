<template>
  <div>
    <HeaderElement/>
    <div class="banner-cutom">
      <img src="../assets/my-img/home/banner.png" alt="" width="100%">
    </div>
    <div class="section-title-wrapper text-center mb-1 mt-3">
        <h2 class="section-title mb-30">Services Offered by WILOR Education Ltd</h2>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <p class="mt-2 mb-5">Our services are open and never ends at a certain point. AHZ Associates will ensure that the services and help will be continued even after completion of course in the UK if needed. We can provide additional support and guideline about future study plan in the UK for a student, Institutions, Companies or parents who took one of our free services. AHZ Associates goal is to completely satisfy the clients with the service from the very beginning to the end.</p>
          
        </div> 
    </div>
    </div> 

      <!-- footer section -->
      <FooterMain />

      <!-- offcanvas search component -->
      <OffcanvasSearchBox />
  </div>
</template>

<script>
import HeaderElement from '@/components/HeaderElementOthers'
import CourseBoxOne from '@/components/CourseBoxOne'
import CourseBoxTwo from '@/components/CourseBoxTwo'
import UniversityTab from '@/components/UniversityTab'
import Newsletter from '@/components/Newsletter'
import FooterMain from '@/components/FooterMain'
import OffcanvasSearchBox from '@/components/OffcanvasSearchBox'
import Accordion from '@/components/AdmissionAcordin'
import SideBarArea from '@/components/SideBarArea'
export default {
  components:{
    HeaderElement,
    CourseBoxOne,
    CourseBoxTwo,
    UniversityTab,
    Newsletter,
    FooterMain,
    OffcanvasSearchBox,
    Accordion,
    SideBarArea,
  }
}
</script>

<style scoped>
  .section-title-wrapper h2{
    font-size: 2.3rem !important;
  }
</style>