<template>
  <div class="row pricing-table-one">
    <div class="col-md-12 mb-2">
      <button
        v-for="(value, index) in countryList"
        :key="index + 'cnt'"
        :class="
          value.country == country
            ? 'ht-btn ht-btn-md mb-1 mr-1 btn-active'
            : 'ht-btn ht-btn-md mb-1 mr-1 '
        "
        @click="setCountry(value.country)"
      >
        {{ value.country }}
      </button>
    </div>
    <div
      class="col-md-6 col-xl-3 pricing-table wow move-up"
      v-for="(uni, index) in uniList"
      :key="index"
    >
      <div class="pricing-table__inner px-0 pt-0">
         <router-link :to="{ name: 'UniversitiesDetails', params: { id: uni.id }}">
         <img :src="uni.logo" class="img-fluid" :alt="uni.name" />
        <div class="uni-table__footer px-1">
         
          <p class="text-center">{{ uni.name }}</p>
          <!-- <a href="#" class="btn btn-block" :class="{'ht-btn--outline': pricing.active}">{{pricing.btutton}}</a> -->
        </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pricing",
  data() {
    return {
      countryList: [],
      uniList: [],
      country: "",
    };
  },

  mounted() {
    this.getCountryList();
    this.universityList();
  },
  methods: {
    getCountryList() {
      axios.get("country-list").then((response) => {
        this.countryList = response.data.data;
      });
    },
    universityList() {
      axios.get("university-list?country=" + this.country).then((response) => {
        this.uniList = response.data.data.all_university;
      });
    },
    setCountry(country) {
      this.country = country;
      this.universityList();
    },
  },
};
</script>

<style scoped>
.pricing-table__inner img {
  min-height: 150px !important;
}
.btn-active {
  box-shadow: 0px 5px 10px rgb(68, 66, 66);
}
</style>