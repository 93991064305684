<template>
  <div>
    <HeaderElement />
    <div class="banner-cutom">
      <img src="../assets/img/bg/whyus.jpeg" alt="" width="100%" />
    </div>
    <div class="section-title-wrapper text-center mb-1 mt-3">
    <h2 class="section-title mb-30">Meet the Team
    </h2>
    </div>
    <div class="container-fluid px-0">
      <div class="row">
        <div class="col-md-12">
          <!-- <div class="team-member-wrapper section-space--ptb_120 default-bg">
            <div class="container">
                <TeamTwo />
            </div>
        </div> -->
          <TeamOne />
        </div>
        <div class="col-md-12 text-center mb-3">
          <a data-v-04e266c5="" href="/contact" class="ht-btn ht-btn-md"
            >Request a call back</a
          >
        </div>
      </div>
    </div>

    <!-- footer section -->
    <FooterMain />

    <!-- offcanvas search component -->
    <OffcanvasSearchBox />
  </div>
</template>

<script>
import HeaderElement from "@/components/HeaderElementOthers";
import CourseBoxOne from "@/components/CourseBoxOne";
import CourseBoxTwo from "@/components/CourseBoxTwo";
import UniversityTab from "@/components/UniversityTab";
import Newsletter from "@/components/Newsletter";
import FooterMain from "@/components/FooterMain";
import OffcanvasSearchBox from "@/components/OffcanvasSearchBox";
import Accordion from "@/components/AdmissionAcordin";
import TeamOne from "@/components/TeamOne";
import TeamTwo from "@/components/TeamTwo";
export default {
  components: {
    HeaderElement,
    CourseBoxOne,
    CourseBoxTwo,
    UniversityTab,
    Newsletter,
    FooterMain,
    OffcanvasSearchBox,
    Accordion,
    TeamTwo,
    TeamOne,
  },
};
</script>

<style scoped>
.section-title-wrapper h2 {
  font-size: 2.3rem !important;
}
</style>
