<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="section-title text-center section-space--mb_60">
                <h2 class="font-weight--light text-white mb-15 wow move-up">Our experience experts</h2>
                <span class="section-text text-white wow move-up">We’ll help you achieve your marketing & business goals</span>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="swiper-container team-slider__container">
                <swiper :options="swiperOption">
                    <div class="swiper-slide">
                        <div class="grid-item wow move-up">
                            <!-- ht-team-member Start -->
                            <div class="ht-team-member text-center">
                                <div class="ht-team-member--inner">
                                    <!-- team-image Start -->
                                    <div class="team-image">
                                        <img class="img-fluid" src="../assets/img/team/avatar-01-140x140.png" alt="avatar 01">
                                    </div>
                                    <!-- team-image End -->
                                    <!-- team-info Start -->
                                    <div class="team-info text-center">
                                        <h5 class="name">Chad Brewer</h5>

                                        <div class="position">Director</div>

                                        <div class="social-networks-rounded mt-10">
                                            <ul class="list ht-social-networks solid-rounded-icon">
                                                <li class="item">
                                                    <a target="_blank" href="#" class="social-link hint--bounce hint--top  hint--primary" aria-label="Facebook"><i class="fab fa-facebook"></i></a>
                                                </li>
                                                <li class="item">
                                                    <a target="_blank" href="#" class="social-link hint--bounce hint--top hint--primary" aria-label="Twitter"><i class="fab fa-twitter"></i></a>
                                                </li>
                                                <li class="item">
                                                    <a target="_blank" href="#" class="social-link hint--bounce hint--top hint--primary" aria-label="Instagram"><i class="fab fa-instagram"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <!-- team-info End -->
                                </div>
                            </div>
                            <!-- ht-team-member End -->
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="grid-item wow move-up">
                            <!-- ht-team-member Start -->
                            <div class="ht-team-member text-center">
                                <div class="ht-team-member--inner">
                                    <!-- team-image Start -->
                                    <div class="team-image">
                                        <img class="img-fluid" src="../assets/img/team/avatar-02-140x140.png" alt="avatar 02">
                                    </div>
                                    <!-- team-image End -->
                                    <!-- team-info Start -->
                                    <div class="team-info text-center">
                                        <h5 class="name">Erik Murray</h5>

                                        <div class="position">Designer</div>

                                        <div class="social-networks-rounded mt-10">
                                            <ul class="list ht-social-networks solid-rounded-icon">
                                                <li class="item">
                                                    <a target="_blank" href="#" class="social-link hint--bounce hint--top  hint--primary" aria-label="Facebook"><i class="fab fa-facebook"></i></a>
                                                </li>
                                                <li class="item">
                                                    <a target="_blank" href="#" class="social-link hint--bounce hint--top hint--primary" aria-label="Twitter"><i class="fab fa-twitter"></i></a>
                                                </li>
                                                <li class="item">
                                                    <a target="_blank" href="#" class="social-link hint--bounce hint--top hint--primary" aria-label="Instagram"><i class="fab fa-instagram"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <!-- team-info End -->
                                </div>
                            </div>
                            <!-- ht-team-member End -->
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="grid-item wow move-up">
                            <!-- ht-team-member Start -->
                            <div class="ht-team-member text-center">
                                <div class="ht-team-member--inner">
                                    <!-- team-image Start -->
                                    <div class="team-image">
                                        <img class="img-fluid" src="../assets/img/team/avatar-06-140x140.png" alt="avatar 06">
                                    </div>
                                    <!-- team-image End -->
                                    <!-- team-info Start -->
                                    <div class="team-info text-center">
                                        <h5 class="name">Cynthia Casey </h5>

                                        <div class="position">Director</div>

                                        <div class="social-networks-rounded mt-10">
                                            <ul class="list ht-social-networks solid-rounded-icon">
                                                <li class="item">
                                                    <a target="_blank" href="#" class="social-link hint--bounce hint--top  hint--primary" aria-label="Facebook"><i class="fab fa-facebook"></i></a>
                                                </li>
                                                <li class="item">
                                                    <a target="_blank" href="#" class="social-link hint--bounce hint--top hint--primary" aria-label="Twitter"><i class="fab fa-twitter"></i></a>
                                                </li>
                                                <li class="item">
                                                    <a target="_blank" href="#" class="social-link hint--bounce hint--top hint--primary" aria-label="Instagram"><i class="fab fa-instagram"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <!-- team-info End -->
                                </div>
                            </div>
                            <!-- ht-team-member End -->
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="grid-item wow move-up">
                            <!-- ht-team-member Start -->
                            <div class="ht-team-member text-center">
                                <div class="ht-team-member--inner">
                                    <!-- team-image Start -->
                                    <div class="team-image">
                                        <img class="img-fluid" src="../assets/img/team/avatar-05-140x140.png" alt="avatar 05">
                                    </div>
                                    <!-- team-image End -->
                                    <!-- team-info Start -->
                                    <div class="team-info text-center">
                                        <h5 class="name">Chad Brewer</h5>

                                        <div class="position">Founder / CEO</div>

                                        <div class="social-networks-rounded mt-10">
                                            <ul class="list ht-social-networks solid-rounded-icon">
                                                <li class="item">
                                                    <a target="_blank" href="#" class="social-link hint--bounce hint--top  hint--primary" aria-label="Facebook"><i class="fab fa-facebook"></i></a>
                                                </li>
                                                <li class="item">
                                                    <a target="_blank" href="#" class="social-link hint--bounce hint--top hint--primary" aria-label="Twitter"><i class="fab fa-twitter"></i></a>
                                                </li>
                                                <li class="item">
                                                    <a target="_blank" href="#" class="social-link hint--bounce hint--top hint--primary" aria-label="Instagram"><i class="fab fa-instagram"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <!-- team-info End -->
                                </div>
                            </div>
                            <!-- ht-team-member End -->
                        </div>
                    </div>
                </swiper>
                <!-- Add Arrows -->
                <div class="swiper-pagination swiper-pagination-2 section-space--mt_50"></div>
            </div>
        </div>
    </div>
                
</template>

<script>
    export default {
        name: 'TeamTwo',
        data () {
            return {
                swiperOption: {
                    speed: 800,
                    loop: true,
                    slidesPerView: 4,
                    spaceBetween : 30,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                     breakpoints: {
                        1499:{
                            slidesPerView : 3
                        },

                        991:{
                            slidesPerView : 2
                        },

                        767:{
                            slidesPerView : 1

                        },

                        575:{
                            slidesPerView : 1
                        }
                    }
                }
            }
        }
    }
</script>
