<template> 
    <div class="brand-logo-slider-area slide-bg">
        <div class="container">
            <div class="row brand-logo-wrapper" style="margin-bottom: 50px;">
                <div class="col-lg-12" >
                    <div class="brand-logo-slider__container-area wow move-up" style="height: 185px;padding-top: 0px;">
                        <div class="swiper-container brand-logo-slider__container" style="padding: 18px 0;">
                            <div class="swiper-wrapper brand-logo-slider__wrapper ">
                                <swiper :options="swiperOption">
                                    <div class="swiper-slide">
                                        <div class="brand-logo">
                                            <div class="brand-logo__image">
                                                <a href="https://www.aru.ac.uk/"><img src="../assets/my-img/universitylogo/Anglia Ruskin University London.png" class="img-fluid image-box" alt="brand logo"></a>
                                            </div>
                                            <div class="brand-logo__image-hover">
                                                <a href="https://www.aru.ac.uk/"><img src="../assets/my-img/universitylogo/Anglia Ruskin University London.png" class="img-fluid image-box" alt="brand logo"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="brand-logo">
                                            <div class="brand-logo__image">
                                                <a href="https://www.coventry.ac.uk/"> <img src="../assets/my-img/universitylogo/Coventry University.png" class="img-fluid image-box" alt="brand logo"></a>     
                                            </div>
                                            <div class="brand-logo__image-hover">
                                                <a href="https://www.coventry.ac.uk/"><img src="../assets/my-img/universitylogo/Coventry University.png" class="img-fluid image-box" alt="brand logo"></a>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="brand-logo">
                                            <div class="brand-logo__image">
                                                <a href="https://www.cranfield.ac.uk/"> <img src="../assets/my-img/universitylogo/Cranfield University.png" class="img-fluid image-box" alt="brand logo"></a>
                                               
                                            </div>
                                            <div class="brand-logo__image-hover">
                                                <a href="https://www.cranfield.ac.uk/"> <img src="../assets/my-img/universitylogo/Cranfield University.png" class="img-fluid image-box" alt="brand logo"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="brand-logo">
                                            <div class="brand-logo__image">
                                                <a href="https://www.dmu.ac.uk/home.aspx"><img src="../assets/my-img/universitylogo/De Montfort University Leicester.png" class="img-fluid image-box" alt="brand logo"></a>
                                               
                                            </div>
                                            <div class="brand-logo__image-hover">
                                                <a href="https://www.dmu.ac.uk/home.aspx"><img src="../assets/my-img/universitylogo/De Montfort University Leicester.png" class="img-fluid image-box" alt="brand logo"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="brand-logo">
                                            <div class="brand-logo__image">
                                                <a href="https://www.durham.ac.uk/"><img src="../assets/my-img/universitylogo/Durham University.png" class="img-fluid image-box" alt="brand logo"></a>
                                                
                                            </div>
                                            <div class="brand-logo__image-hover">
                                                <a href="https://www.durham.ac.uk/"><img src="../assets/my-img/universitylogo/Durham University.png" class="img-fluid image-box" alt="brand logo"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="brand-logo">
                                            <div class="brand-logo__image">
                                                <a href="https://www.mdx.ac.uk/"><img src="../assets/my-img/universitylogo/Middlesex University London.png" class="img-fluid image-box" alt="brand logo"></a>
                                                
                                            </div>
                                            <div class="brand-logo__image-hover">
                                                <a href="https://www.mdx.ac.uk/"><img src="../assets/my-img/universitylogo/Middlesex University London.png" class="img-fluid image-box" alt="brand logo"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="brand-logo">
                                            <div class="brand-logo__image">
                                                <a href="https://www.ntu.ac.uk/"><img src="../assets/my-img/universitylogo/Nottingham Trent University.png" class="img-fluid image-box" alt="brand logo"></a>
                                                
                                            </div>
                                            <div class="brand-logo__image-hover">
                                                <a href="https://www.ntu.ac.uk/"><img src="../assets/my-img/universitylogo/Nottingham Trent University.png" class="img-fluid image-box" alt="brand logo"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="brand-logo">
                                            <div class="brand-logo__image">
                                                <a href="https://www.ulster.ac.uk/"><img src="../assets/my-img/universitylogo/Ulster University.png" class="img-fluid image-box" alt="brand logo"></a>
                                                
                                            </div>
                                            <div class="brand-logo__image-hover">
                                                <a href="https://www.ulster.ac.uk/"><img src="../assets/my-img/universitylogo/Ulster University.png" class="img-fluid image-box" alt="brand logo"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="brand-logo">
                                            <div class="brand-logo__image">
                                                <a href="https://www.essex.ac.uk/"><img src="../assets/my-img/universitylogo/University of Essex.png" class="img-fluid image-box" alt="brand logo"></a>
                                                
                                            </div>
                                            <div class="brand-logo__image-hover">
                                                <a href="https://www.essex.ac.uk/"><img src="../assets/my-img/universitylogo/University of Essex.png" class="img-fluid image-box" alt="brand logo"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="brand-logo">
                                            <div class="brand-logo__image">
                                                <a href="https://www.glos.ac.uk/"><img src="../assets/my-img/universitylogo/University of Gloucestershire.png" class="img-fluid image-box" alt="brand logo"></a>
                                               
                                            </div>
                                            <div class="brand-logo__image-hover">
                                                <a href="https://www.glos.ac.uk/"><img src="../assets/my-img/universitylogo/University of Gloucestershire.png" class="img-fluid image-box" alt="brand logo"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="brand-logo">
                                            <div class="brand-logo__image">
                                                <a href="https://www.gre.ac.uk/"><img src="../assets/my-img/universitylogo/University of Greenwich.png" class="img-fluid image-box" alt="brand logo"></a>
                                                
                                            </div>
                                            <div class="brand-logo__image-hover">
                                                <a href="https://www.gre.ac.uk/"><img src="../assets/my-img/universitylogo/University of Greenwich.png" class="img-fluid image-box" alt="brand logo"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="brand-logo">
                                            <div class="brand-logo__image">
                                                <a href="https://www.reading.ac.uk/"><img src="../assets/my-img/universitylogo/University of Reading.png" class="img-fluid image-box" alt="brand logo"></a>
                                                
                                            </div>
                                            <div class="brand-logo__image-hover">
                                                <a href="https://www.reading.ac.uk/"><img src="../assets/my-img/universitylogo/University of Reading.png" class="img-fluid image-box" alt="brand logo"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="brand-logo">
                                            <div class="brand-logo__image">
                                                <a href="https://www.surrey.ac.uk/"><img src="../assets/my-img/universitylogo/University of Surrey.png" class="img-fluid image-box" alt="brand logo"></a>
                                                
                                            </div>
                                            <div class="brand-logo__image-hover">
                                                <a href="https://www.surrey.ac.uk/"><img src="../assets/my-img/universitylogo/University of Surrey.png" class="img-fluid image-box" alt="brand logo"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swiper-slide">
                                        <div class="brand-logo">
                                            <div class="brand-logo__image">
                                                <a href="https://www.uwe.ac.uk/"><img src="../assets/my-img/universitylogo/University of the West of England.png" class="img-fluid image-box" alt="brand logo"></a>
                                                
                                            </div>
                                            <div class="brand-logo__image-hover">
                                                <a href="https://www.uwe.ac.uk/"><img src="../assets/my-img/universitylogo/University of the West of England.png" class="img-fluid image-box" alt="brand logo"></a>
                                            </div>
                                        </div>
                                    </div>
                                </swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'BrandLogoCarousel',
        data (){
            return {
                swiperOption: {
                    speed: 800,
                    loop: true,
                    autoplay: true,
                    slidesPerView: 4,
                    spaceBetween : 30,
                    breakpoints: {
                        991:{
                            slidesPerView : 3
                        },

                        767:{
                            slidesPerView : 3
                        },
                        575:{
                            slidesPerView : 2
                        }
                    }
                }
            }
        }
    }
</script>
<style>
 
  .slide-bg{
      background-color: #d1d1d2!important;
  }
  .image-box{
      height: 150px;
  }
   .brand-logo-slider__container{
   padding: 18 0!important;
  }
</style>
