<template>
  <div>
    <HeaderElement />
    <div class="banner-cutom">
      <!--  <img src="../assets/my-img/home/banner.png" alt="" width="100%"> -->
      <img src="../assets/img/bg/wilor-study-abroad.jpeg" alt="" width="100%" />
    </div>
    <div class="section-title-wrapper text-center mb-1 mt-3">
      <h2 class="section-title mb-30">Help & Supports</h2>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-8 py-5">
          <p>
            <b>Welcome to International Students Information Support Centre</b>
          </p>
          <p>
            If you are a fresh graduate or wish to complete your Undergraduate
            or Postgraduate degree from one of the top-ranked University in the
            UK, please feel free to contact our help & support team who will
            guide you to find the right career path regardless the place you’re
            coming from.
          </p>
          <p><b>Where Can I ask for admission help?</b></p>
          <p>
            Please contact our local office or country representatives for
            further guidance and supports.
          </p>
          <p>
            Prompt response and 24/7 service- Alternatively you always can
            contact us via using the following option.
          </p>
          <p><b>Direct appointment with our partner universities</b></p>
          <p>
            Our expert always provides best efforts to support your admission
            enquiry most fruitfully. However, if you still need to contact
            University admission officer we are happy to find an appointment for
            you.
          </p>
          <!-- <p><b>Scholarship application guideline</b></p> --->
          <a data-v-04e266c5="" href="/contact" class="ht-btn ht-btn-md"
            >Request a call back</a
          >
        </div>
      </div>
    </div>

    <!-- footer section -->
    <FooterMain />

    <!-- offcanvas search component -->
    <OffcanvasSearchBox />
  </div>
</template>

<script>
import HeaderElement from "@/components/HeaderElementOthers";
import CourseBoxOne from "@/components/CourseBoxOne";
import CourseBoxTwo from "@/components/CourseBoxTwo";
import UniversityTab from "@/components/UniversityTab";
import Newsletter from "@/components/Newsletter";
import FooterMain from "@/components/FooterMain";
import OffcanvasSearchBox from "@/components/OffcanvasSearchBox";
import SideBarArea from "@/components/SideBarArea";
export default {
  components: {
    HeaderElement,
    CourseBoxOne,
    CourseBoxTwo,
    UniversityTab,
    Newsletter,
    FooterMain,
    OffcanvasSearchBox,
    SideBarArea,
  },
};
</script>

<style scoped>
.section-title-wrapper h2 {
  font-size: 2.3rem !important;
}
</style>
