<template>
  <div>
    <HeaderElement />
    <div class="banner-cutom">
      <img src="../assets/img/bg/wilor-blog-and-news.jpeg" alt="" width="100%" />
    </div>
    <div class="section-title-wrapper text-center mb-1 mt-3">
    <h2 class="section-title mb-30">Blog & News</h2> 
    </div>
    <div class="container">
      <div class="blog-pages-wrapper section-space--ptb_100 border-bottom">
        <div class="container">
          <div class="row">
            <div class="col-lg-8">
              <div class="main-blog-wrap">
                <div class="single-blog-item">
                  <div
                    class="post-feature blog-thumbnail  wow move-up"
                    style="visibility: visible;"
                  >
                    <img
                      :src="article.image_path"
                      alt="Blog Images"
                      class="img-fluid"
                    />
                  </div>
                  <div
                    class="post-info lg-blog-post-info  wow move-up"
                    style="visibility: visible;"
                  > 
                    <h4 class="post-title font-weight--light">
                     {{ article.title }}
                    </h4>
                    <div class="post-meta mt-20">
                      <div class="post-author" style="padding: 5px;margin-top: -5px;">
                        {{  article.author }}
                      </div>
                      <div class="post-date">
                        <span class="far fa-calendar meta-icon"></span>
                       {{  article.created_at | formatDate  }}
                      </div> 
                    </div>
                    <div class="post-excerpt mt-15">
                       <div v-html="article.body"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           <div class="col-lg-4">
                <div class="page-sidebar-content-wrapper page-sidebar-right small-mt__40 tablet-mt__40">
                  <div class="sidebar-widget widget-blog-post wow move-up" style="visibility: visible;">
                    <h5 class="widget-title font-weight--light">Popular posts</h5>
                    <hr>  
                    <div v-for="(pa, index) in propular_articles" :key="index" class="post-item">
                      <div class="post-info"> 
                      <h6 class="post-title">
                      <router-link :to="{ name: 'BlogDetails', params: { id: pa.id }}" > 
                        {{ pa.title}}
                      </router-link> 
                      </h6>
                      </div>
                      <hr> 
                    </div>

                  </div>
                </div>  
              </div>
          </div>
        </div>
      </div>
    </div>

    <!-- footer section -->
    <FooterMain />

    <!-- offcanvas search component -->
    <OffcanvasSearchBox />
  </div>
</template>

<script>
import HeaderElement from "@/components/HeaderElementOthers";
import CourseBoxOne from "@/components/CourseBoxOne";
import CourseBoxTwo from "@/components/CourseBoxTwo";
import UniversityTab from "@/components/UniversityTab";
import Newsletter from "@/components/Newsletter";
import FooterMain from "@/components/FooterMain";
import OffcanvasSearchBox from "@/components/OffcanvasSearchBox";
import Accordion from "@/components/AdmissionAcordin";
import SideBarArea from "@/components/SideBarArea";
export default {
  components: {
    HeaderElement,
    CourseBoxOne,
    CourseBoxTwo,
    UniversityTab,
    Newsletter,
    FooterMain,
    OffcanvasSearchBox,
    Accordion,
    SideBarArea,
  }, 
  data() {
    return {
      id: this.$route.params.id,  
      article : null,
      propular_articles:null
    };
  },
    watch: {
    $route(to, from) {
      this.id = this.$route.params.id;
      this.getBlogDetails();
    },
  },

  created() {
    this.getBlogDetails();
  },

  methods: {
    getBlogDetails() {
      axios.get("articles-details/" + this.id).then((response) => {
        this.article = response.data.data.article; 
        this.propular_articles = response.data.data.popular_articles;
      });
    },
  },
};
</script>

<style scoped>
.section-title-wrapper h2 {
  font-size: 2.3rem !important;
}
.single-blog-item .post-info .post-meta{
  display: flex;
flex-wrap: wrap;
margin: 0 -10px;

}
.page-sidebar-right{
  padding-left: 30px;
}
.sidebar-widget  .sidebar-widget{
margin-top: 40px;
}
.single-blog-item .post-info{
  margin-top: 20px;
}
.single-blog-item .post-info .post-title{
  margin-bottom: 15px;
}
</style>
