<template>
  <div>
    <HeaderElement/>
    <div class="banner-cutom">
      <img src="../assets/my-img/home/banner.png" alt="" width="100%">
    </div>
    <div class="section-title-wrapper text-center mb-1 mt-3">
        <h2 class="section-title mb-30">Study Abroad Admission Services for School, College and Universities</h2>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <p>Our service is not limited to the students only but also we provide a number of service for the universities that we are associated with. Our service for universities are as follows:</p>
          <ul class="check-list green-check-list section-space--mb_50">
            <li class="list-item">Organise local & International Education Fair/Expo</li>
            <li class="list-item">Spot assessment day</li>
            <li class="list-item">University open admission day</li>
            <li class="list-item">Campus visit </li>
            <li class="list-item"> Seminar/Workshop arrangement </li>
            <li class="list-item"> Promote newly introduced courses by our partners</li>
            <li class="list-item">Study tour event</li>
            <li class="list-item">All service is completely free for students and our partners</li>
            <li class="list-item">Career Path and Opportunities </li>
            <li class="list-item">Select appropriate Course </li>
          </ul>
          <p><b>Our Promotion</b></p>
          <p>We do International Newspaper advertisement covering all regions that we worked for, Facebook advertisement, Google advertisement, Local TV advertisement, Flyer distribution, Text Marketing, Email marketing, Facebook page about 85,000+ fan, Google+, YouTube, Twitter, Instagram, Pinterest, Blog and Skype.</p>
          <p><b>CRM System</b></p>
          <p>To manage our student we have an online system for partner institutes like – EMS data management system(CRM), Centurus|One, Student & Partner Management System, Project management system, Express email marketing tool for the email campaign.</p>
        </div> 
    </div>
    </div> 

      <!-- footer section -->
      <FooterMain />

      <!-- offcanvas search component -->
      <OffcanvasSearchBox />
  </div>
</template>

<script>
import HeaderElement from '@/components/HeaderElementOthers'
import CourseBoxOne from '@/components/CourseBoxOne'
import CourseBoxTwo from '@/components/CourseBoxTwo'
import UniversityTab from '@/components/UniversityTab'
import Newsletter from '@/components/Newsletter'
import FooterMain from '@/components/FooterMain'
import OffcanvasSearchBox from '@/components/OffcanvasSearchBox'
import Accordion from '@/components/AdmissionAcordin'
import SideBarArea from '@/components/SideBarArea'
export default {
  components:{
    HeaderElement,
    CourseBoxOne,
    CourseBoxTwo,
    UniversityTab,
    Newsletter,
    FooterMain,
    OffcanvasSearchBox,
    Accordion,
    SideBarArea,
  }
}
</script>

<style scoped>
  .section-title-wrapper h2{
    font-size: 2.3rem !important;
  }
</style>