<template>
    <div class="accordion-faq wow move-up">
        <b-card no-body>
            <b-card-header header-tag="header">
                <b-button block v-b-toggle.accordion-1 class="faq-title">Consultation with counsellor</b-button>
            </b-card-header>
            <b-collapse id="accordion-1" visible accordion="my-accordion">
                <b-card-body>
                    <b-card-text>
                    Our expert counsellor will explain the complete process of applying to a UK university and discuss your study and career goals
                    </b-card-text>
                </b-card-body>
            </b-collapse>
        </b-card>

        <b-card no-body>
            <b-card-header header-tag="header">
                <b-button block v-b-toggle.accordion-2 class="faq-title"> Find a course in a suitable University</b-button>
            </b-card-header>
            <b-collapse id="accordion-2" accordion="my-accordion">
                <b-card-body>
                    <b-card-text>
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nostrum sunt consectetur id, inventore perspiciatis dignissimos! Et tenetur dicta ipsa natus.
                    </b-card-text>
                </b-card-body>
            </b-collapse>
        </b-card>

        <b-card no-body>
            <b-card-header header-tag="header">
                <b-button block v-b-toggle.accordion-3 class="faq-title"> Prepare your application</b-button>
            </b-card-header>
            <b-collapse id="accordion-3" accordion="my-accordion">
                <b-card-body>
                    <b-card-text>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis tenetur quia commodi laborum, dolor, quis voluptatum laboriosam unde alias illum nisi suscipit in accusantium error dicta cupiditate beatae temporibus laudantium magni aut illo. Corrupti perferendis ducimus error culpa ullam, quaerat, dicta blanditiis odit mollitia possimus sequi illum nam veniam eos!
                    </b-card-text>
                </b-card-body>
            </b-collapse>
        </b-card>

        <b-card no-body>
            <b-card-header header-tag="header">
                <b-button block v-b-toggle.accordion-4 class="faq-title">Receive offers from University</b-button>
            </b-card-header>
            <b-collapse id="accordion-4" accordion="my-accordion">
                <b-card-body>
                    <b-card-text>
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sapiente exercitationem voluptatem odit similique quam, accusantium dolorem voluptates nobis qui sunt eligendi repellendus fuga molestiae? Veritatis cum laborum, tempora delectus, eveniet sequi sed deleniti, saepe debitis quos aliquid aspernatur natus consequuntur ex culpa ipsa commodi. Numquam non consectetur, laboriosam assumenda error cupiditate eos sed necessitatibus fugit quas ratione asperiores libero, culpa voluptas similique commodi quam at natus ipsum qui quidem earum est? Iusto ratione officia in eveniet aliquid provident voluptatibus cupiditate quas recusandae autem corporis tenetur cum, similique quidem dolorum velit neque voluptas maiores ullam omnis alias aperiam odio tempore sequi.
                    </b-card-text>
                </b-card-body>
            </b-collapse>
        </b-card>
        <b-card no-body>
            <b-card-header header-tag="header">
                <b-button block v-b-toggle.accordion-5 class="faq-title"> Help with offer letter next steps</b-button>
            </b-card-header>
            <b-collapse id="accordion-5" accordion="my-accordion">
                <b-card-body>
                    <b-card-text>
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sapiente exercitationem voluptatem odit similique quam, accusantium dolorem voluptates nobis qui sunt eligendi repellendus fuga molestiae? Veritatis cum laborum, tempora delectus, eveniet sequi sed deleniti, saepe debitis quos aliquid aspernatur natus consequuntur ex culpa ipsa commodi. Numquam non consectetur, laboriosam assumenda error cupiditate eos sed necessitatibus fugit quas ratione asperiores libero, culpa voluptas similique commodi quam at natus ipsum qui quidem earum est? Iusto ratione officia in eveniet aliquid provident voluptatibus cupiditate quas recusandae autem corporis tenetur cum, similique quidem dolorum velit neque voluptas maiores ullam omnis alias aperiam odio tempore sequi.
                    </b-card-text>
                </b-card-body>
            </b-collapse>
        </b-card>
        <b-card no-body>
            <b-card-header header-tag="header">
                <b-button block v-b-toggle.accordion-6 class="faq-title"> Continuous support</b-button>
            </b-card-header>
            <b-collapse id="accordion-6" accordion="my-accordion">
                <b-card-body>
                    <b-card-text>
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sapiente exercitationem voluptatem odit similique quam, accusantium dolorem voluptates nobis qui sunt eligendi repellendus fuga molestiae? Veritatis cum laborum, tempora delectus, eveniet sequi sed deleniti, saepe debitis quos aliquid aspernatur natus consequuntur ex culpa ipsa commodi. Numquam non consectetur, laboriosam assumenda error cupiditate eos sed necessitatibus fugit quas ratione asperiores libero, culpa voluptas similique commodi quam at natus ipsum qui quidem earum est? Iusto ratione officia in eveniet aliquid provident voluptatibus cupiditate quas recusandae autem corporis tenetur cum, similique quidem dolorum velit neque voluptas maiores ullam omnis alias aperiam odio tempore sequi.
                    </b-card-text>
                </b-card-body>
            </b-collapse>
        </b-card>
        <b-card no-body>
            <b-card-header header-tag="header">
                <b-button block v-b-toggle.accordion-7 class="faq-title">  Secure Tier-4 student visa in the UK</b-button>
            </b-card-header>
            <b-collapse id="accordion-7" accordion="my-accordion">
                <b-card-body>
                    <b-card-text>
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sapiente exercitationem voluptatem odit similique quam, accusantium dolorem voluptates nobis qui sunt eligendi repellendus fuga molestiae? Veritatis cum laborum, tempora delectus, eveniet sequi sed deleniti, saepe debitis quos aliquid aspernatur natus consequuntur ex culpa ipsa commodi. Numquam non consectetur, laboriosam assumenda error cupiditate eos sed necessitatibus fugit quas ratione asperiores libero, culpa voluptas similique commodi quam at natus ipsum qui quidem earum est? Iusto ratione officia in eveniet aliquid provident voluptatibus cupiditate quas recusandae autem corporis tenetur cum, similique quidem dolorum velit neque voluptas maiores ullam omnis alias aperiam odio tempore sequi.
                    </b-card-text>
                </b-card-body>
            </b-collapse>
        </b-card>
        <b-card no-body>
            <b-card-header header-tag="header">
                <b-button block v-b-toggle.accordion-8 class="faq-title"> Continuous support</b-button>
            </b-card-header>
            <b-collapse id="accordion-8" accordion="my-accordion">
                <b-card-body>
                    <b-card-text>
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sapiente exercitationem voluptatem odit similique quam, accusantium dolorem voluptates nobis qui sunt eligendi repellendus fuga molestiae? Veritatis cum laborum, tempora delectus, eveniet sequi sed deleniti, saepe debitis quos aliquid aspernatur natus consequuntur ex culpa ipsa commodi. Numquam non consectetur, laboriosam assumenda error cupiditate eos sed necessitatibus fugit quas ratione asperiores libero, culpa voluptas similique commodi quam at natus ipsum qui quidem earum est? Iusto ratione officia in eveniet aliquid provident voluptatibus cupiditate quas recusandae autem corporis tenetur cum, similique quidem dolorum velit neque voluptas maiores ullam omnis alias aperiam odio tempore sequi.
                    </b-card-text>
                </b-card-body>
            </b-collapse>
        </b-card>
    </div>
</template>

<script>
    export default {
        name: 'Accordion',
    };
</script>