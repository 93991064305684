<template>
  <div>
<!-- partial -->

    <HeaderElement />
    <div class="banner-cutom" >
      <img src="../assets/img/bg/wilor-study-abroad.jpeg" alt="" width="100%"  />
    </div>
    <div class="section-title-wrapper text-center mb-1 mt-3">
      <h4 class="section-title mb-30"> {{ course_category_details.title}} </h4>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-8">
        <h5 class="text-left"> Overview </h5>
        <p> <div v-html="course_category_details.overview"></div>  </p>
          </hr>
            <b-card >
                <b-tabs pills  vertical >

                <b-tab active >
                    <template #title>
                    <b-icon icon="trophy-fill"></b-icon>   <strong>Entry Requirements</strong>
                    </template>
                   <b-card-text>
                   <h5 class="font-italic mb-4">Entry Requirements</h5>
                    <p class="p-3">
                                <div v-html="course_category_details.entry_requirements"></div>
                    </p>
                    </b-card-text>
                </b-tab>


                <b-tab >
                    <template #title class="mytabs">
                    <b-icon icon="slack"></b-icon>   <strong>Fees & Scholarships</strong>
                    </template>
                    <b-card-text>
                      <h5 class="font-italic mb-4">Fees & Scholarships</h5>
                        <p class="p-3">
                        <div v-html="course_category_details.fees_scholarships"></div>
                        </p>
                    </b-card-text>
                </b-tab>

                <b-tab>
                <template #title>
                    <b-icon icon="briefcase-fill"></b-icon>   <strong>Employability</strong>
                    </template>
                <b-card-text>
                <h5 class="font-italic mb-4">Employability</h5>
                <p class="p-3">
                    <div v-html="course_category_details.employability"></div>
                </p>
                </b-card-text>
                </b-tab>

                </b-tabs>
            </b-card>
            </br>
            </hr>
            <h3 class="mb-5 text-center">Offered By</h3>
           <div style="overflow-x:auto;">
          <table>
          <thead>

          </thead>
          <tbody>
              <tr v-for="(uni, index) in courseUniversity" :key="index">
                <td>{{ uni.uk_ranking }}</td>
                <td> <img :src="uni.logo" class="img-fluid" :alt="uni.name" /></td>
                <td>{{ uni.name }}</td>
                <td>{{ uni.country	}}</td>
                <td>
                <router-link :to="{ name: 'UniversitiesDetails', params: { id: uni.institution_id }}"  class="ht-btn ht-btn-md" >View Details</router-link >
              <!--  <router-link :to="{ path: 'admission-process' }" class="ht-btn ht-btn-md" >Apply Now</router-link > ---></td>
              </tr>
          </tbody>
          </table>
          </div>
        </div>
        <div class="col-md-4" >
       <ApplicationProcess />
        </div>
      </div>
    </div>
    <!-- footer section -->
    <FooterMain />

    <!-- offcanvas search component -->
    <OffcanvasSearchBox />
  </div>
</template>

<script>
import HeaderElement from "@/components/HeaderElementOthers";
import FooterMain from "@/components/FooterMain";
import OffcanvasSearchBox from "@/components/OffcanvasSearchBox";
import ApplicationProcess from "@/components/ApplicationProcess";
export default {
  components: {
    HeaderElement,
    FooterMain,
    OffcanvasSearchBox,
    ApplicationProcess
  },
  data() {
    return {
      toggle: true,
      course_category_details : '',
      id: this.$route.params.id,
      courseUniversity: []
    };

  },
   watch: {
    $route(to, from) {
      this.id = this.$route.params.id;
      this.getCourseCategoryDetails();
    },
  },

  mounted() {
    this.getCourseCategoryDetails()
  },
  methods: {
    getCourseCategoryDetails() {
      axios.get("course-categories-details/" + this.id).then((response) => {
        this.courseUniversity = response.data.data.courseUniversity;
        this.course_category_details = response.data.data.course_category_details;
      });
    },
  },
};
</script>

<style scoped>
 .nav-pills-custom .nav-link.active{
    background-color: #1e2b5e!important;
}
.nav-pills{
    background-color: white!important;
}
.nav-item{
background-color: #a4a4a5!important;
}
.card{
  border:none;
  padding: 0.75rem 1.25rem;
margin-bottom: 0;
background-color: white !important;;
}
table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding-bottom: 10;
  width: 100%;
  table-layout: fixed;
  margin-bottom: 20px;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }

  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}
  .tab-title-class{
  background-color: #a4a4a5;
  padding: 10px;
  }
  .mytabs {
  background-color: #aabbcc !important;
}
li .nav-item{
 padding: 10px;
}
</style>
