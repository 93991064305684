<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="testimonial-slider">
        <div class="swiper-container testimonial-slider__container">
          <div class="swiper-wrapper testimonial-slider__wrapper">
            <swiper :options="swiperOption">
              <div class="swiper-slide ">
                <div  class="testimonial-slider__single wow move-up boxstd"  style="background-color: rgb(32, 38, 95);" >
                  <div class="app-landing-testimonial-slider__wrap">
                  <div class="post-thumbnail" style="margin-right: 10px;margin-left: -30px;">
                    <img src="../assets/img/students/anita.png" class="img-fluid" alt="" width="250" height="250" />
                  </div>

                    <div class="testimonial-slider--info">
                    <div class="testimonial-slider__text" style="color: #ffffff;text-align: justify;">
                      Hi, my name is Anita, and I enrolled in Nescot College in HND in Business through Wilor Education. Their professional staff helped me a lot in terms of registering for College. I am a second-year student now and heading towards enrolling at the university to complete my graduation.
                    </div>
                    <div class="testimonial-slider__author">
                      <h6 class="name" style="color: #ffffff; margin-bottom: 10px;">Anita</h6>
                      <p>
                        HND in Business at Nescot College
                      </p>
                    </div>
                  </div>

                </div>
                </div>
              </div>
               <div class="swiper-slide ">
                <div  class="testimonial-slider__single wow move-up boxstd"  style="background-color: rgb(32, 38, 95);" >
                  <div class="app-landing-testimonial-slider__wrap">
                  <div class="post-thumbnail" style="margin-right: 10px;margin-left: -30px;">
                    <img src="../assets/img/students/victoria.png" class="img-fluid" alt="" width="250" height="250" />
                  </div>

                    <div class="testimonial-slider--info">
                    <div class="testimonial-slider__text" style="color: #ffffff;text-align: justify;">
                       Hi, I am Victoria. I went to Wilor Education, and they asses my previous qualification and they prepared me for enrolling in the foundation course in London School of Commerce and IT which is affiliated with Bucks New University. Their staff is really helpful and gave me their full attention to enrol to this school.
                    </div>
                    <div class="testimonial-slider__author">
                      <h6 class="name" style="color: #ffffff; margin-bottom: 10px;">Victoria</h6>
                      <p>BA (Hons) Business Management with Foundation Year at Bucks New University.</p>
                    </div>
                  </div>

                </div>
                </div>
              </div>
              <div class="swiper-slide ">
                <div  class="testimonial-slider__single wow move-up boxstd"  style="background-color: rgb(32, 38, 95);" >
                  <div class="app-landing-testimonial-slider__wrap">
                  <div class="post-thumbnail" style="margin-right: 10px;margin-left: -30px;">
                    <img src="../assets/img/students/parisha.png" class="img-fluid" alt="" width="250" height="250" />
                  </div>

                    <div class="testimonial-slider--info">
                    <div class="testimonial-slider__text" style="color: #ffffff;text-align: justify;">
                      Hi, I am Parisa. I just finished the first year of International Business Management at ARU London. Wilor Education is excellent in helping and preparing students to get enrolled in universities. I recommend them because of their professionalism and being helpful to students.
                    </div>
                    <div class="testimonial-slider__author">
                      <h6 class="name" style="color: #ffffff; margin-bottom: 10px;">Parisa</h6>
                       <p>
                      International Business Management at Anglia Ruskin University (London campus)
                    </p>
                    </div>
                  </div>

                </div>
                </div>
              </div>
            </swiper>
          </div>
        </div>
        <div
          class="swiper-pagination swiper-pagination-1 section-space--mt_60"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TestimonialTwo",
  data() {
    return {
      swiperOption: {
        speed: 800,
        loop: true,
        slidesPerView: 1,
        spaceBetween: 30,
        autoplay: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          1499: {
            slidesPerView: 1,
          },

          991: {
            slidesPerView: 1,
          },

          767: {
            slidesPerView: 1,
          },
        },
      },
    };
  },
};
</script>
<style scoped>
@media screen and (min-width: 720px) {
  .testimonial-slider__text {
    font-size: 16px;
  }
}
.app-landing-testimonial-slider__wrap {
  max-width: none !important;
}
.boxstd {
  border-radius: 50px;
  padding: 20px;
}
</style>
