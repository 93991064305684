<template>
  <div>
    <HeaderElement/>
    <div class="banner-cutom">
      <img src="../assets/my-img/home/banner.png" alt="" width="100%" id="banner-image">
    </div>
    <div class="section-title-wrapper text-center mb-1 mt-3">
        <h2 class="section-title mb-30">STUDENT ADMISSION SERVICES</h2>
    </div>
    <div class="accordion-wrapper section-space--pb_90">
        <div class="container">
          <div class="row">
            <div class="col-md-8 mt-5">
              <p>Our admission services are mainly structured for international and EU student. We also offer UK University admission services to the Home students with limited options. Check out our wide range of free services below.</p>
                <div class="faq-wrapper section-space--pt_90">
                    <div class="section-elements-title mb-30">
                        <h4>Our Free Student Admission Services</h4>
                    </div>
                    <!-- accordion component -->
                    <Accordion />
                </div>
            </div>
            <SideBarArea />
          </div>
        </div>
    </div>
    <!-- newsletter section -->
      <Newsletter /> 

      <!-- footer section -->
      <FooterMain />

      <!-- offcanvas search component -->
      <OffcanvasSearchBox />
  </div>
</template>

<script>
import HeaderElement from '@/components/HeaderElementOthers'
import CourseBoxOne from '@/components/CourseBoxOne'
import CourseBoxTwo from '@/components/CourseBoxTwo'
import UniversityTab from '@/components/UniversityTab'
import Newsletter from '@/components/Newsletter'
import FooterMain from '@/components/FooterMain'
import OffcanvasSearchBox from '@/components/OffcanvasSearchBox'
import Accordion from '@/components/AdmissionAcordin'
import SideBarArea from '@/components/SideBarArea'
export default {
  components:{
    HeaderElement,
    CourseBoxOne,
    CourseBoxTwo,
    UniversityTab,
    Newsletter,
    FooterMain,
    OffcanvasSearchBox,
    Accordion,
    SideBarArea,
  }
}
</script>

<style scoped>
  .section-title-wrapper h2{
    font-size: 2.3rem !important;
  }
</style>