<template>
  <div>
    <HeaderElement />
    <div class="banner-cutom">
    <!---  <img src="../assets/my-img/home/banner.png" alt="" width="100%" />  --->
      <img src="../assets/img/bg/wilor-study-abroad.jpeg" alt="" width="100%" />
    </div>
    <div class="section-title-wrapper text-center mb-1 mt-3">
      <h2 class="section-title mb-30 text-uppercase">
        UK University Application Process
      </h2>
      <p>
        <b>We can help you choose from thousands of UK courses and hundreds of
          universities and colleges located all over the UK with our free
          university application process.</b>
      </p>
    </div>
    <div class="accordion-wrapper section-space--pb_90">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="faq-wrapper section-space--pt_90">
              <div class="section-elements-title mb-30">
                <h4 style="font-size: 1.7rem">
                  How does our application process work?
                </h4>
              </div>
              <!-- accordion component -->
              <div class="accordion-faq wow move-up">
                <b-card
                  v-for="(ad_step, index) in processList"
                  :key="ad_step.id + 'add'"
                  no-body
                >
                  <b-card-header header-tag="header">
                    <b-button
                      block
                      v-b-toggle="'accordion-' + index"
                      class="faq-title"
                      >{{ ad_step.title }}</b-button
                    >
                  </b-card-header>
                  <b-collapse
                    :id="'accordion-' + index"
                    visible
                    accordion="my-accordion"
                  >
                    <b-card-body>
                      <b-card-text>
                        {{ ad_step.value }}
                      </b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <ApplicationProcess />
          </div>
        </div>
      </div>
    </div>

    <!-- footer section -->
    <FooterMain />

    <!-- offcanvas search component -->
    <OffcanvasSearchBox />
  </div>
</template>

<script>
import HeaderElement from "@/components/HeaderElementOthers";
import CourseBoxOne from "@/components/CourseBoxOne";
import CourseBoxTwo from "@/components/CourseBoxTwo";
import UniversityTab from "@/components/UniversityTab";
import Newsletter from "@/components/Newsletter";
import FooterMain from "@/components/FooterMain";
import OffcanvasSearchBox from "@/components/OffcanvasSearchBox";
import Accordion from "@/components/AplicationAcordin";
import ApplicationProcess from "@/components/ApplicationProcess";
export default {
  components: {
    HeaderElement,
    CourseBoxOne,
    CourseBoxTwo,
    UniversityTab,
    Newsletter,
    FooterMain,
    OffcanvasSearchBox,
    Accordion,
    ApplicationProcess,
  },
  data() {
    return {
      processList: [],
    };
  },
  mounted() {
    this.getProcess();
  },
  methods: {
    getProcess() {
      axios.get("admission-process-step").then((response) => {
        this.processList = response.data.data.admission_step;
      });
    },
  },
};
</script>

<style scoped>
.section-title-wrapper h2 {
  font-size: 2.3rem !important;
}
</style>
