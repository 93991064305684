<template>
  <div>
    <HeaderElement/>
    <div class="banner-cutom">
      <img src="../assets/my-img/home/banner.png" alt="" width="100%">
    </div>
    <div class="section-title-wrapper text-center mb-1 mt-3">
        <h2 class="section-title mb-30">Top Universities in the United 	Kingdom</h2>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-offset-2">
          <p>  It is very important to do enough research before choosing the right university for higher education. And University ranking is one of the biggest factors to consider while choosing a UK university for an international student. Everyone wants to study in high-ranked university but location plays also important role while choosing the most suitable one. So, all factors would come into consideration when moving to a new city come into play. </p>
<!--<h4>Ranking of UK Universities in 2021</h4><br> --->
         <div style="overflow-x:auto;">
          <table>
          <thead>

          </thead>
          <tbody>
              <tr v-for="(uni, index) in uniList" :key="index">
                <td>{{ uni.uk_ranking }}</td>
                <td> <img :src="uni.logo" class="img-fluid" :alt="uni.name" /></td>
                <td>{{ uni.name }}</td>
                <td>{{ uni.country	}}</td>
                <td><router-link :to="{ path: 'admission-process' }" class="ht-btn ht-btn-md" >Apply Now</router-link ></td>
              </tr>
          </tbody>
          </table>
          </div>
          <br>
        </div>
      </div>
    </div>
  <FooterMain/>
  </div>
</template>

<script>
import HeaderElement from '@/components/HeaderElementOthers'
import CourseBoxOne from '@/components/CourseBoxOne'
import CourseBoxTwo from '@/components/CourseBoxTwo'
import UniversityTab from '@/components/UniversityTab'
import Newsletter from '@/components/Newsletter'
import FooterMain from '@/components/FooterMain'
import OffcanvasSearchBox from '@/components/OffcanvasSearchBox'
import Accordion from '@/components/AdmissionAcordin'
import SideBarArea from '@/components/SideBarArea'
export default {
  components:{
    HeaderElement,
    CourseBoxOne,
    CourseBoxTwo,
    UniversityTab,
    Newsletter,
    FooterMain,
    OffcanvasSearchBox,
    Accordion,
    SideBarArea,
  },
    data() {
    return {
      countryList: [],
      uniList: [],
      country: "",
    };
  },
    mounted() {
    this.universityList();
  },
  methods: {
    universityList() {
      axios.get("uk-university-list").then((response) => {
        this.uniList = response.data.data.all_university;
      });
    },
    setCountry(country) {
      this.country = country;
      this.universityList();
    },
  },
}
</script>

<style scoped>
  .section-title-wrapper h2{
    font-size: 2.3rem !important;
  }
table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }

  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}
</style>
