<template>
    <div class="ht-tab-wrap">
        <b-tabs class="ht-tab-menu wow move-up">
            <b-tab title="All" active>
                <!-- tab content one component -->
                <div class="pricing-table-content-area pt-4">
                    <div class="container">
                        <!-- pricing component -->
                        <CourseTabContentOne />
                    </div>
                </div>
            </b-tab>
            <b-tab title="BSc in Accounting">
                <!-- tab content two component -->
                <div class="pricing-table-content-area pt-4">
                    <div class="container">
                        <!-- pricing component -->
                        <CourseTabContentOne />
                    </div>
                </div> 
            </b-tab>
            <b-tab title="Business & Management">
                <!-- tab content third component -->
                <div class="pricing-table-content-area pt-4">
                    <div class="container">
                        <!-- pricing component -->
                        <CourseTabContentOne />
                    </div>
                </div>
            </b-tab>
            <b-tab title="Law">
                <!-- tab content third component -->
                <div class="pricing-table-content-area pt-4">
                    <div class="container">
                        <!-- pricing component -->
                        <CourseTabContentOne />
                    </div>
                </div>
            </b-tab>
            <b-tab title="Masters">
                <!-- tab content third component -->
                <div class="pricing-table-content-area pt-4">
                    <div class="container">
                        <!-- pricing component -->
                        <CourseTabContentOne />
                    </div>
                </div>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
    import CourseTabContentOne from '@/components/CourseTabContentOne'
    import TabContentTwo from '../components/TabContentTwo'
    import TabContentThree from '../components/TabContentThree'

    export default {
        name: 'TabOne',
        components: {
            CourseTabContentOne,
            TabContentTwo,
            TabContentThree
        }
    };
</script>