<template>
  <div>
    <HeaderElement/>
    <div class="banner-cutom">
      <img src="../assets/my-img/home/banner.png" alt="" width="100%">
    </div>
    <div class="section-title-wrapper text-center mb-1 mt-3">
        <h2 class="section-title mb-30">Student Accommodation Service</h2>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-8 pb-5">
          <img src="../assets/my-img/accomodation.jpg" width="100%" alt="">
          <p>Wilor Education is proud to announce the extra service that is providing to its students. Our new Partnership with Casita & Uniplaces Accommodation is here to make your life easier.</p>
          <img width="100%" src="https://ahzassociates.co.uk/wp-content/uploads/2020/08/Brand3_logo_horizontal_blue-300x70.png" alt="">
          <p>Uniplaces is an innovative online accommodation platform helping people find a home in all European cities, North America and Australia. It’s fast to book, safe to pay, and lets you browse through thousands of trusted properties around the globe. The online booking process makes it possible to safely rent a room or apartment from local landlords, making sure you have all the information you need to make a safe and informed decision without the need of flying over weeks in advance! With more than 10 million nights booked, Uniplaces is creating the ideal rental experience for everyone and has become a trusted platform for people looking to safely rent a place abroad. Uniplaces has its sights firmly set on becoming the number one mid- to long-term accommodation platform in the world.</p>
          <img width="100%" src="https://ahzassociates.co.uk/wp-content/uploads/2020/08/casita-logo.png" alt="">
          <p>Casita helps university students find accommodation overseas. Casita is a UK and Australian based university student service, with management and customer support teams in London and Melbourne who have been helping students study and live at overseas universities for many years. Their objective is to help students to find the best accommodation closest to their university, through a professional, efficient and free service.</p>
        </div>
        <SideBarArea />
      </div>
    </div>
    <!-- newsletter section -->
      <Newsletter /> 

      <!-- footer section -->
      <FooterMain />

      <!-- offcanvas search component -->
      <OffcanvasSearchBox />
  </div>
</template>

<script>
import HeaderElement from '@/components/HeaderElementOthers'
import CourseBoxOne from '@/components/CourseBoxOne'
import CourseBoxTwo from '@/components/CourseBoxTwo'
import UniversityTab from '@/components/UniversityTab'
import Newsletter from '@/components/Newsletter'
import FooterMain from '@/components/FooterMain'
import OffcanvasSearchBox from '@/components/OffcanvasSearchBox'
import Accordion from '@/components/AdmissionAcordin'
import SideBarArea from '@/components/SideBarArea'
export default {
  components:{
    HeaderElement,
    CourseBoxOne,
    CourseBoxTwo,
    UniversityTab,
    Newsletter,
    FooterMain,
    OffcanvasSearchBox,
    Accordion,
    SideBarArea,
  }
}
</script>

<style scoped>
  .section-title-wrapper h2{
    font-size: 2.3rem !important;
  }
</style>