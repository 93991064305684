<template>
    <div class="tab-mission-wrap section-space--mt_80">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="tab-mission-image">
                    <img src="../assets/img/banners/aeroland-tab-content-image-06.png" alt="" class="img-fluid">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="tab-content-inner">
                    <div class="ht-service-box--three mb-40">
                        <div class="service-box-wrap text-left">
                            <div class="image__media">
                                <img src="../assets/img/icons/aeroland-tab-content-image-icon-01.png" class="img-fluid" alt="aeroland-payment-box-icon-01">
                            </div>
                            <div class="content">
                                <h6 class="sub-heading mb-10 text-uppercase">Hotline</h6>
                                <h4 class="heading"><a href="tel:">0122 8899900 </a></h4>
                                <div class="service_text">24/7 phone and email support</div>
                            </div>
                        </div>
                    </div>
                    <div class="ht-service-box--three">
                        <div class="service-box-wrap">
                            <div class="image__media">
                                <img src="../assets/img/icons/aeroland-tab-content-image-icon-02.png" class="img-fluid" alt="aeroland-payment-box-icon-01">
                            </div>
                            <div class="content">
                                <h6 class="sub-heading mb-10 text-uppercase">Give your Feedback</h6>
                                <h4 class="heading"><a href="#">hello@seopro.com</a></h4>
                                <div class="service_text">24/7 phone and email support</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TabContentThree'
    };
</script>

