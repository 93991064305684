<template>
  <div class="main-container">
    <!-- Navbar section -->
    <!-- <Navbar /> -->
    <HeaderElement />
    <!-- hero section -->
    <HeroDigitalAgency />
    <!-- about section -->
    <!-- <div class="box-image-area section-space--ptb_120" id="about">
            <div class="container">
                <div class="section-title-wrapper text-center section-space--mb_60 wow move-up">
                    <h2 class="section-title mb-15">Expand Your Business</h2>
                    <span class="section-text">Plan ahead to make progress and lead the trend.</span>
                </div>
                <AboutExpand />
            </div>
        </div> -->

    <!-- business analytics start -->
    <div class="feature-icon-list-area section-space--ptb_80">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <div class="section-title-wrapper text-center section-space--mb_50">
              <br />
              <div>
                <h3 class="section-title mb-30">
                  <font color="rgb(32, 38, 95);">Welcome to Wilor Education Ltd</font>
                </h3>
                <h5 style="margin-top: -20px;">Your Gateway to Global Learning Excellence!</h5>
                <br>
              </div>
              <div
                class="text-justify"
                style="
                  /* background-color: rgb(175, 179, 220); */
                  color: rgb(251, 250, 250);
                  font-size: large;
                  padding: 10px;
                "
              >
                <p>
                  At Wilor Education Ltd,
                   we take pride in being a leading international educational consultancy firm committed to shaping the future of education. 
                   With a focus on providing comprehensive solutions, 
                   we offer a diverse range of services, including educational institution management, 
                   English language courses, and expert guidance for UK and EU nationals seeking educational opportunities.
                </p>
                <router-link :to="{ path: 'admission-process' }" class="ht-btn ht-btn-md">read more</router-link>
              </div>
            </div>
          </div>
          <div>
            
          </div>
          <div class="col-md-4">
            <div class="row" style="margin-top: 40px">
              <div class="col-md-12">
                <img
                  src="../assets/img/welcome/welcome image.jpg"
                  class="img-fluid img-thumbnail"
                />
              </div>
              <!--- <div class="col-md-12">
                        <img
                          src="../assets/img/welcome/2.jpg"
                          class="img-fluid img-thumbnail mt-4"
                        />
                      </div>
                --->
              <!---    <div class="col-md-12">
                <img
                  src="../assets/img/welcome/3.jpg"
                  class="img-fluid img-thumbnail"
                />
                </div> --->
              <!---      <div class="col-md-12">
                <img
                  src="../assets/img/welcome/4.jpg"
                  class="img-fluid img-thumbnail mt-4"
                />

              </div>
              --->
            </div>
          </div>
        </div>
      </div>

      <div style="background-color: rgb(212 212 245); padding: 40px;">
      <h4 style="text-align: center;">We are ready to help you <span style="color: #8b2121;">24/7</span></h4>
      <br>
      <p style="text-align: center; font-size: large;">We are here to assist you at all times. 
        Reach out to us and seize the chance for a free consultation to fulfil your dream your goal.</p>
      </div>
      <br>


      <div style="margin-left : 15px;
  width: 100%;
  padding: 10px;">
<div class="row" style="margin-left: auto;">
      <div class="col-md-4 col-sm-4">
        <div class="card mb-3" style="max-width: 18rem; height: 25rem; border-color: rgb(212 212 245); border-width: 2cap;">
        <div class="card-header" style="text-align: center;"><i class="fa fa-phone fa-lg" aria-hidden="true"></i></div>
        <div class="card-body text-info">
          <h5 class="card-title" style="text-align: center;">Worldwide Support</h5>
          <p style="color: #7e7e7e;text-align: center">Our international staffs is available to assist students from any countries who wish to continue their education abroad at one of our many international partner universities and colleges.</p>
        </div>
      </div>
      </div>
      <div class="col-md-4 col-sm-4">
        <div class="card  mb-3" style="max-width: 18rem; height: 25rem; border-color: rgb(212 212 245); border-width: 2cap;">
        <div class="card-header" style="text-align: center;"><i class="fa fa-signal fa-lg" aria-hidden="true"></i></div>
        <div class="card-body text-info">
          <h5 class="card-title"  style="text-align: center;">Our values</h5>
          <p style="color: #7e7e7e; text-align: center;">Highest level of professionalism, transparency, inspiration, and honesty in handling the issues in an ethical and mutually trusting manner.</p>
        </div>
      </div>
      </div>
      
      <div class="col-md-4 col-sm-4">
        <div class="card  mb-3" style="max-width: 18rem; height: 25rem; border-color: rgb(212 212 245); border-width: 2cap;">
        <div class="card-header" style="text-align: center;"><i class="fa fa-crosshairs fa-lg" aria-hidden="true"></i></div>
        <div class="card-body text-info">
          <h5 class="card-title" style="text-align: center;">Our Mission</h5>
          <p style="color: #7e7e7e; text-align: center;">Inspiring Global Citizens through Innovative Education and Cultural Exchange, Shaping a Future of Unity, Diversity, and Excellence.</p>
        </div>
      </div>
      </div>

      <!-- <div class="col-md-3 col-sm-3">
        <div class="card  mb-3" style="max-width: 18rem; height: 25rem; border-color: rgb(212 212 245); border-width: 2cap;">
        <div class="card-header" style="text-align: center;"><i class="fa fa-binoculars fa-lg" aria-hidden="true"></i></div>
        <div class="card-body text-info">
          <h5 class="card-title" style="text-align: center;">Our Vision</h5>
          <p style="color: #7e7e7e; text-align: center;">this is our vision.</p>
        </div>
      </div>
      </div> -->
     </div>
      </div>
     

      <div style="background-color: rgb(212 212 245); padding: 40px;">
      <h4 style="text-align: center;">Our Motto</span></h4>
      <br>
      <h4 style="text-align: center;  color: #8b2121">Believe in Success.</h4>
      </div>
      <br>

  
      <!-- feature list component -->
      <!-- <FeatureList /> -->
      <SassFeature />
    </div>

    <!-- requirement section-->
    <!-- <RequireList /> -->

    <!-- feature carousel -->
    <!-- <FeatureTwo /> -->

    <!-- feature list progress bar -->
    <div class="feature-list-progress-area pb-30">
      <div class="container">
        <!-- progress feature component -->
        <!-- <ProgressCircle /> -->
      </div>
    </div>
    <!-- feature list progress bar -->

    <!-- pricing section start-->
    <CurveShape>
      <template v-slot:bgcolor>
        <path
          style="fill: #20265f; stroke-miterlimit: 10"
          d="M3360.5,97.739c-242,0-480-48.375-480-48.375
                        S2647.5,0.5,2400.5,0.5s-480,48.375-480,48.375s-238,48.864-480,48.864s-480-48.375-480-48.375S727.5,0.5,480.5,0.5
                        S0.5,48.875,0.5,48.875V108h1920h1920V48.875C3840.5,48.875,3602.5,97.739,3360.5,97.739z"
        />
      </template>
    </CurveShape>
    <div
      class="pricing-table-area bg-gray-2 pt-30"
      id="pricing"
      style="background-color: #20265f"
    >
      <div class="pricing-table-title-area position-relative">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div
                class="section-title-wrapper text-center section-space--mb_60"
              >
                <h2
                  class="section-title font-width-light mb-15 wow move-up"
                  style="color: white"
                >
                  Your One Stop Solution for Higher Education
                </h2>
                <span class="section-text wow move-up"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pricing-table-content-area section-space--pb_30">
        <div class="container">
          <!-- Pricing component -->
          <Pricing />
        </div>
      </div>
    </div>
    <!-- pricing section end-->
    <!-- fun facts section -->
    <br>
    <div class="fun-fact-wrapper section-space--ptb_25" style="background-color: rgb(212 212 245); color: #000000; font-size: large">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title text-center section-space--mb_10">
              <h2 class="font-weight--light mb-15 move-up">
                <br>
                <strong> No stories, facts only </strong>
              </h2>
              <span class="section-text wow move-up"></span>
            </div>
          </div>
        </div>
        <!-- fun fact component -->
        <FunFactOne />
      </div>
    </div>
    <!-- testimonial section -->
    <div
      class="testimonial-slider-area section-space--ptb_120 bg-gray"
      id="testimonial"
    >
      <div class="container" style="background-color: rgb(226, 230, 226)">
        <div class="section-title text-center section-space--mb_10">
          <h2 class="section-title font-weight--light wow move-up">
            A Glimpse of Our Happy Students
          </h2>
        </div>
        <TestimonialTwo />
      </div>
    </div>

    <!-- brand logo carousel -->
    <!-- <BrandLogoCarousel /> -->

    <!-- partner client section -->
    <div class="brand-logo-area section-space--ptb_10" id="partner">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title-wrapper text-center mb-1">
              <h2 class="section-title mb-15 wow move-up">
                University Partners
              </h2>
              <!-- <span class="section-text wow move-up">It is about us being able to offer help with the branding campaign, product presentation, and advertisement running across social media.</span> -->
            </div>
          </div>
        </div>
        <!-- brandlogo component -->
        <BrandLogoCarousel />
        <!-- <BrandLogo /> -->
      </div>
    </div>

    <!-- newsletter section -->

    <div class="col-md-12 mx-auto text-center">
      <img
        src="../assets/img/logo/bc.png"
        style="padding: 10px"
        height="70"
        alt="British Council"
      />

      <img
        src="../assets/img/logo/ico.jpg"
        style="padding: 10px"
        height="70"
        alt="ICO"
      />

      <img
        src="../assets/img/logo/icef.png"
        style="padding: 10px"
        height="70"
        alt="ICEF"
      />

      <img
        src="../assets/img/logo/qisan.jpg"
        style="padding: 10px"
        height="70"
        alt="ICEF"
      />
    </div>

    <div class="col-md-12 text-center mb-3">
      <a data-v-04e266c5="" href="/contact" class="ht-btn ht-btn-md"
        >Request a call back</a
      >
    </div>

    <!-- footer section -->
    <FooterMain />

    <!-- offcanvas search component -->
    <OffcanvasSearchBox />
  </div>
</template>

<script>
// import Navbar from '../components/Navbar'
//import Toolbar from "@/components/Toolbar";
import HeaderElement from "@/components/HeaderElement";
import HeroDigitalAgency from "@/components/sections/HeroDigitalAgency";
import AboutExpand from "../components/AboutExpand";
import FeatureList from "../components/FeatureList";
import RequireList from "../components/RequireList";
import FeatureTwo from "../components/FeatureTwo";
import ProgressCircle from "../components/ProgressCircle";
import CurveShape from "../components/CurveShape";
import Pricing from "../components/Pricing";
import BrandLogo from "../components/BrandLogo";
import Newsletter from "../components/Newsletter";
import FooterMain from "../components/FooterMain";
import OffcanvasSearchBox from "../components/OffcanvasSearchBox";
import FunFactOne from "../components/FunFactOne";
import TestimonialTwo from "../components/TestimonialTwo";
import BrandLogoCarousel from "../components/BrandLogoCarousel";
import SassFeature from "../components/SassFeature";
import ProductOverview from "../components/sections/ProductOverview";

export default {
  name: "app",
  components: {
    //Toolbar,
    // Navbar,
    HeaderElement,
    HeroDigitalAgency,
    AboutExpand,
    FeatureList,
    RequireList,
    FeatureTwo,
    ProgressCircle,
    CurveShape,
    Pricing,
    BrandLogo,
    Newsletter,
    FooterMain,
    OffcanvasSearchBox,
    FunFactOne,
    TestimonialTwo,
    BrandLogoCarousel,
    SassFeature,
    ProductOverview,
  },
};
</script>
