<template>
  <div>
    <HeaderElement />
    <div class="banner-cutom">
      <img src="../assets/img/bg/wilor-study-abroad.jpeg" alt="" width="100%" />
    </div>
    <div class="section-title-wrapper text-center mb-1 mt-3">
      <h2 class="section-title mb-30">
        UK University Scholarships for International Students
      </h2>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12 py-5">
          <div
            v-for="(value, index) in scolarship_content"
            :key="'content' + index"
          >
            <p>
              <b>{{ value.title }}</b>
            </p>
            <ul class="check-list green-check-list section-space--mb_50">
              <li
                class="list-item"
                v-for="(bullet, b_index) in value.clist"
                :key="'service' + b_index"
              >
                {{ bullet.value }}
              </li>
            </ul>
          </div>
          <a data-v-04e266c5="" href="/contact" class="ht-btn ht-btn-md"
            >Request a call back</a
          >
        </div>

      </div>
    </div>
    <!-- footer section -->
    <FooterMain />

    <!-- offcanvas search component -->
    <OffcanvasSearchBox />
  </div>
</template>

<script>
import HeaderElement from "@/components/HeaderElementOthers";
import CourseBoxOne from "@/components/CourseBoxOne";
import CourseBoxTwo from "@/components/CourseBoxTwo";
import UniversityTab from "@/components/UniversityTab";
import Newsletter from "@/components/Newsletter";
import FooterMain from "@/components/FooterMain";
import OffcanvasSearchBox from "@/components/OffcanvasSearchBox";
import SideBarArea from "@/components/SideBarArea";
export default {
  components: {
    HeaderElement,
    CourseBoxOne,
    CourseBoxTwo,
    UniversityTab,
    Newsletter,
    FooterMain,
    OffcanvasSearchBox,
    SideBarArea,
  },
  data() {
    return {
      scolarship_content: [],
    };
  },

  mounted() {
    this.getScolarshipContent();
  },
  methods: {
    getScolarshipContent() {
      axios.get("scholarships-content").then((response) => {
        this.scolarship_content = response.data.data.scholarship_content;
      });
    },
  },
};
</script>

<style scoped>
.section-title-wrapper h2 {
  font-size: 2.3rem !important;
}
</style>
