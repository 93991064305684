<template>
  <div>
    <HeaderElement/>
    <div class="banner-cutom">
      <img src="../assets/my-img/home/banner.png" alt="" width="100%">
    </div>
    <div class="section-title-wrapper text-center mb-1 mt-3">
        <h2 class="section-title mb-30">Advice for Parents of UK Study Aspirantss</h2>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <p>We offer such encouragement for parents who are interested to gain in-depth knowledge about UK Education and Admission process for their sons/daughters.</p>
          <p>Our friendly team is happy to discuss parental concerns regards to the UK studies such as</p>
          <ul class="check-list green-check-list section-space--mb_50">
            <li class="list-item">Why studying abroad is important</li>
            <li class="list-item">Safety issues abroad</li>
            <li class="list-item">Communication</li>
            <li class="list-item">Accommodation</li>
            <li class="list-item">Future outcome of their kids</li>
            <li class="list-item">Courses/Institutions</li>
            <li class="list-item">Student facilities</li>
            <li class="list-item">Study abroad fees and so on</li>
          </ul>
          <p class="mb-5">Considering those parental issues we have high experienced advisers which you can contact for free advice. You can visit our head office and talk to face-to-face with our advisers if you are living within the UK or if you live outside we have many global offices around the world where you can go and talk to our experienced member of staff. You can view the list of our offices from “International Branches” page.</p>
        </div>
        <SideBarArea />
    </div>
    </div>
      <!-- newsletter section -->
      <Newsletter /> 

      <!-- footer section -->
      <FooterMain />

      <!-- offcanvas search component -->
      <OffcanvasSearchBox />
  </div>
</template>

<script>
import HeaderElement from '@/components/HeaderElementOthers'
import CourseBoxOne from '@/components/CourseBoxOne'
import CourseBoxTwo from '@/components/CourseBoxTwo'
import UniversityTab from '@/components/UniversityTab'
import Newsletter from '@/components/Newsletter'
import FooterMain from '@/components/FooterMain'
import OffcanvasSearchBox from '@/components/OffcanvasSearchBox'
import Accordion from '@/components/AdmissionAcordin'
import SideBarArea from '@/components/SideBarArea'
export default {
  components:{
    HeaderElement,
    CourseBoxOne,
    CourseBoxTwo,
    UniversityTab,
    Newsletter,
    FooterMain,
    OffcanvasSearchBox,
    Accordion,
    SideBarArea,
  }
}
</script>

<style scoped>
  .section-title-wrapper h2{
    font-size: 2.3rem !important;
  }
</style>