<template>
  <div>
    <HeaderElement />
    <div class="banner-cutom">
      <img src="../assets/img/bg/contact.jpeg" alt="" width="100%" />
    </div>
    <div class="section-title-wrapper text-center mb-1 mt-3">
      <h2 class="section-title mb-30">Contact Us</h2>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto">
          <div class="row mb-2">
            <div
              class="col-md-4 contact-title"

            >
              
              
              <p>UK Head office : 253 Commercial Road, Office 1 , Cheltenham House, London, United Kingdom, E1 2BT</p>
              <p>info@wiloreducation.co.uk</p>
              <p>Tel: +442070181970</p>
              <p>https://www.wiloreducation.co.uk/</p>
            </div>
            <div class="col-md-4 contact-title">

            </div>
            <div class="col-md-4 contact-title">
            <img src="../assets/img/portfolio/wilor-contact.gif" alt="" height="100%" width="100%" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mb-5">
              <hr />
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- footer section -->
    <FooterMain />

    <!-- offcanvas search component -->
    <OffcanvasSearchBox />
  </div>
</template>

<script>
import HeaderElement from "@/components/HeaderElementOthers";
import CourseBoxOne from "@/components/CourseBoxOne";
import CourseBoxTwo from "@/components/CourseBoxTwo";
import CourseTab from "@/components/CourseTab";
import Newsletter from "@/components/Newsletter";
import FooterMain from "@/components/FooterMain";
import OffcanvasSearchBox from "@/components/OffcanvasSearchBox";
import ContactForm from "@/components/ContactForm";
export default {
  components: {
    HeaderElement,
    CourseBoxOne,
    CourseBoxTwo,
    CourseTab,
    Newsletter,
    FooterMain,
    OffcanvasSearchBox,
    ContactForm,
  },
  data() {
    return {
      branchList: [],
    };
  },
  mounted() {
    this.getBranch();
  },

  methods: {
    getBranch() {
      axios.get("country-branch-list").then((response) => {
        this.branchList = response.data.application.country_branch_list;
      });
    },
  },
};
</script>

<style scoped>
.section-title-wrapper h2 {
  font-size: 2.3rem !important;
}
.contact-title h2 {
  font-size: 1.5rem !important;
}
.contact-title p {
  margin-bottom: 0px !important;
}
.contact-title img {
  width: 60%;
}
</style>
