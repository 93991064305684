<template>
  <div class="container-fluid toolbar fixed-top">
    <div id="toolbar" class="toolbar">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <a href="#" class="text-white f-12">
              <b-icon icon="envelope"></b-icon>
              <span class="mx-md-2 d-inline-block"></span>
              <span class="d-md-inline-block">{{ setting.email }}</span>
            </a>
            <span class="mx-md-2 d-inline-block"></span>
            <a href="#" class="text-white f-12 m-d-10">
              <b-icon icon="telephone-outbound"></b-icon>
              <span class="mx-md-2 d-inline-block"></span>
              <span class="d-md-inline-block">{{ setting.contact }}</span>
            </a>
            <div class="d-none d-md-inline-block float-right">
              <a
                href="https://www.facebook.com/Wiloreducation"
                target="_blank"
                class="text-white"
              >
                <b-icon icon="facebook"></b-icon>
              </a>
              <span class="mx-md-2 d-inline-block"></span>
              <a
                href="https://twitter.com/EducationWilor"
                target="_blank"
                class="text-white"
              >
                <b-icon icon="twitter"></b-icon>
              </a>
              <span class="mx-md-2 d-inline-block"></span>
              <a
                href="https://www.instagram.com/wilor_education/"
                target="_blank"
                class="text-white"
              >
                <b-icon icon="instagram"></b-icon>
              </a>
              <span class="mx-md-2 d-inline-block"></span>
              <a href="#" class="text-white">
                <i class="fab fa-whatsapp"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Toolbar",
  data() {
    return {
      setting: {},
    };
  },
  mounted() {
    this.getSetting();
  },
  methods: {
    getSetting() {
      axios.get("setting").then((response) => {
        this.setting = response.data.data.setting;
      });
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style scoped>
#toolbar {
  line-height: 30px;
  font-weight: 700;
  height: 30px;
}
.toolbar {
  background-color: #1e2a5e;
}
@media only screen and (max-width: 600px) {
  .f-12 {
    font-size: 12px;
  }
  .m-d-10 {
    margin-left: 10px;
  }
}
</style>
