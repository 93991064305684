<template> 
  <div>
<!-- partial --> 
    <HeaderElement />
    <div class="banner-cutom" >
      <img :src="university_details.banner" alt="" width="100%" height="448"  />
    </div>
    
    <div class="container">
      <div class="row">
        <div class="col-md-8"> 
        <div style="text-align:center">
            <img :src="university_details.logo" class="img-fluid" :alt="university_details.name" height="100"  />
        </div>
         
        <div class="section-title-wrapper text-center mb-1 mt-3">
            <h3 class="section-title mb-30 text-uppercase"> {{ university_details.name}}  </h3> 
        </div> 

          <h5 class="text-left"> Overview </h5>
            <div v-html="university_details.overview"></div>

      
        </div>
        <div class="col-md-4" >
       <ApplicationProcess />
        </div>
      <div class="col-md-12">
          
          
            <h4 class="mb-5 text-center">Popular Courses</h4>
            
              <div class="row pricing-table-one">
      <div class="col-md-6 col-xl-4 pricing-table wow move-up"
        v-for="(course, index) in courseList"
        :key="index"
        :class="{ 'pricing-table--popular__bg': course.active }"
      >
        <div
          class="pricing-table__inner"
          :class="{ 'bg-gradient': course.active }"
        >
          <div class="pricing-table__feature-mark" v-if="course.isPopular">
            <span>Popular Choice</span>
          </div>
          <div class="pricing-table__header"> 
            <h5 class="pricing-table__title">{{ course.title }}</h5> 
          </div>
          <div class="pricing-table__body">
            <ul class="pricing-table__list">
              <li><strong>Intake: </strong>{{ course.intake }}</li>
              <li>
                <strong>Mode of Study: </strong>{{ course.mode_of_study }}
              </li>
              <li><strong>Course Duration: </strong>{{ course.duration }}</li>
              <li><strong>Fee Range: </strong>{{ course.fee_range }}</li>
            </ul>
          </div>
          <div class="pricing-table__footer">  
             <router-link :to="{ name: 'CoursesDetails', params: { id: course.course_category_id }}"
              class="ht-btn ht-btn--outline ht-btn-custom"
              :class="{ 'btn--white': course.active }"
              >Check Your Eligibility</router-link>
          </div>
        </div>
      </div>
    </div>
       </div>
      </div> 
    </div>
    <!-- footer section -->
    <FooterMain />

    <!-- offcanvas search component -->
    <OffcanvasSearchBox />
  </div>
</template>

<script>
import HeaderElement from "@/components/HeaderElementOthers"; 
import FooterMain from "@/components/FooterMain";
import OffcanvasSearchBox from "@/components/OffcanvasSearchBox";
import ApplicationProcess from "@/components/ApplicationProcess";
export default {
  components: {
    HeaderElement, 
    FooterMain,
    OffcanvasSearchBox,
    ApplicationProcess
  },
  data() {
    return { 
      university_details : '',
      id: this.$route.params.id,  
      courseList: []
    };
    
  },
   watch: {
    $route(to, from) {
      this.id = this.$route.params.id;
      this.getUniversityDetails();
    },
  },

  mounted() { 
    this.getUniversityDetails()
  },
  methods: { 
    getUniversityDetails() {
      axios.get("university-details/" + this.id).then((response) => {
        this.university_details = response.data.data.university_details; 
        this.courseList = response.data.data.related_course;
      });
    },
  },
};
</script>

<style scoped> 
 
</style>