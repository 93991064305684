<template>
  <div>
    <HeaderElement/>
    <div class="banner-cutom" >
        <img src="../assets/img/bg/wilor-study-abroad.jpeg" alt="" width="100%" />
    </div>
    <div class="section-title-wrapper text-center mb-1 mt-3">
        <h2 class="section-title mb-30">University List</h2>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12 py-5">
          <UniversityTab/>
        </div>
      </div>
    </div>

      <!-- footer section -->
      <FooterMain />

      <!-- offcanvas search component -->
      <OffcanvasSearchBox />
  </div>
</template>

<script>
import HeaderElement from '@/components/HeaderElementOthers'
import CourseBoxOne from '@/components/CourseBoxOne'
import CourseBoxTwo from '@/components/CourseBoxTwo'
import UniversityTab from '@/components/UniversityTab'
import Newsletter from '@/components/Newsletter'
import FooterMain from '@/components/FooterMain'
import OffcanvasSearchBox from '@/components/OffcanvasSearchBox'
export default {
  components:{
    HeaderElement,
    CourseBoxOne,
    CourseBoxTwo,
    UniversityTab,
    Newsletter,
    FooterMain,
    OffcanvasSearchBox,
  }
}
</script>

<style scoped>
  .section-title-wrapper h2{
    font-size: 2.3rem !important;
  }
</style>
