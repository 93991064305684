<template>
  <div class="saas-feature section-space--ptb_90 bg-gd">
    <div class="container">
      <!-- <div class="row">
                <div class="col-lg-12">
                    <div class="section-title text-center section-space--mb_60">
                        <h2 class="font-weight--light mb-15 wow move-up">Developed for Higher Business Growth</h2>
                        <span class="section-text wow move-up">Offer your business with the best assistance for growth.</span>
                    </div>
                </div>
            </div> -->
      <div class="row">
        <div
          v-for="item in items"
          :key="item.id"
          class="col-lg-4 col-md-6 wow move-up"
        >
          <div class="service-grid">
            <div class="ht-service-box--one style-03">
              <router-link :to="item.url">
                <!----
                                <div class="image__media" style="text-align:center;">
                                    <img :src="item.icon" class="img-fluid" alt="aeroland-app-showcase-box-icon-image-01" style="height: 62px;">
                                </div>
                                --->

                <div class="content text-center">
                  <h6 class="heading">
                    <img
                      :src="item.icon"
                      class="img-fluid"
                      alt="aeroland-app-showcase-box-icon-image-01"
                      style="height: 80px; margin-left: -15px"
                    /><br />{{ item.heading }}
                  </h6>
                  <div
                    class="service_text"
                    style="font-size: 15px; text-align: justify"
                  >
                    {{ item.text }}
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default { 
  name: "SassFeature",
  data() {
    return {
      items: [
        {
          icon: require("../assets/img/icons/wilor-counselling.png"),
          heading: "FREE COUNSELLING",
          text: "We provided free counselling to our respective students by expert counsellors who have graduated from the UK.",
          url: "#",
        },
        {
          icon: require("../assets/img/icons/wilor-student-admission.png"),
          heading: "STUDENT ADMISSION SERVICES",
          text: "Our admission services are mainly structured for international and EU students, but we also offer our admission services to UK students.",
            url: "#",
        },
        {
          icon: require("../assets/img/icons/wilor-compliance.png"),
          heading: "COMPLIANCE ASSISTANCE",
          text: "We have a dedicated compliance team to guide our students through the whole visa process.",
            url: "#",
        },
        {
          icon: require("../assets/img/icons/wilor-accomodation.png"),
          heading: "STUDENT ACCOMMODATION SERVICES",
          text: "Wilor Education is proud to announce its partnership with Casita, who provide excellent student accommodation services at reasonable prices.",
            url: "#",
        },
        {
          icon: require("../assets/img/icons/wilor-partner-university.png"),
          heading: "PARTNER UNIVERSITY SERVICES",
          text: "Our services are not only limited to students, but we also provide our services for the universities that we are associated with.",
            url: "#",
        },
        {
          icon: require("../assets/img/icons/wilor-employee.png"),
          heading: "EMPLOYEE MANAGEMENT",
          text: "We are also a platform for connecting people from all over the world based on their preferences.",
            url: "#",
        },
      ],
    };
  },
};
</script>
<style scoped>
.service-grid .ht-service-box--one > a {
  padding: 10px !important;
  height: 250px !important;
}
.service-grid {
  margin-bottom: 10px;
  margin-left: -15px;
}
.bg-gd {
  background-color: #845fe3;
  background-image: linear-gradient(to bottom right, #a0a2d2 0%, #36478a 100%);
}
</style>
