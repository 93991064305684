<template>
    <div class="feature-icon-list">
        <div class="row">
            <div class="col-lg-6">
                <div class="feature-icon-list__media wow move-up">
                    <!-- <img src="../assets/img/features/aeroland-feature-icon-image.png" class="img-fluid" alt=""> -->
                    <!-- <img src="../assets/img/features/aeroland-feature-icon-image.png" class="img-fluid" alt=""> -->
                    <div class="row my-5">
                        <div class="col-md-6">
                            <img src="../assets/img/features/slide-2.png" class="img-fluid gallery-img" alt="">
                        </div>
                        <div class="col-md-6">
                            <img src="../assets/img/features/slide-1.png" class="img-fluid gallery-img" alt="">
                        </div>
                    </div>
                    <div class="row my-5">
                        <div class="col-md-6">
                            <img src="../assets/img/features/slide-1.png" class="img-fluid gallery-img" alt="">
                        </div>
                        <div class="col-md-6">
                            <img src="../assets/img/features/slide-3.png" class="img-fluid gallery-img" alt="">
                        </div>
                    </div>
                    <div class="row my-5">
                        <div class="col-md-6">
                            <img src="../assets/img/features/slide-2.png" class="img-fluid gallery-img" alt="">
                        </div>
                        <div class="col-md-6">
                            <img src="../assets/img/features/slide-1.png" class="img-fluid gallery-img" alt="">
                        </div>
                    </div>
                    <div class="row my-5">
                        <div class="col-md-6">
                            <img src="../assets/img/features/slide-1.png" class="img-fluid gallery-img" alt="">
                        </div>
                        <div class="col-md-6">
                            <img src="../assets/img/features/slide-3.png" class="img-fluid gallery-img" alt="">
                        </div>
                    </div>
                    <div class="row my-5">
                        <div class="col-md-6">
                            <img src="../assets/img/features/slide-2.png" class="img-fluid gallery-img" alt="">
                        </div>
                        <div class="col-md-6">
                            <img src="../assets/img/features/slide-1.png" class="img-fluid gallery-img" alt="">
                        </div>
                    </div>
                    <div class="row my-5">
                        <div class="col-md-6">
                            <img src="../assets/img/features/slide-1.png" class="img-fluid gallery-img" alt="">
                        </div>
                        <div class="col-md-6">
                            <img src="../assets/img/features/slide-3.png" class="img-fluid gallery-img" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="feature-icon-list__content">
                    <div class="feature-icon-list-wrapper">
                        <div v-for="feature in features" :key="feature.id" class="single-feature-icon wow move-up">
                            <div class="single-feature-icon__media">
                                <img :src="feature.icon" class="img-fluid" alt="">
                            </div>
                            <div class="single-feature-icon__content">
                                <h6 class="title">{{ feature.title }}</h6>
                                <div class="text">{{ feature.desc }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'featureList',
        data (){
            return {
                features: [
                    {
                        icon: require('../assets/img/icons/aeroland-branding-box-image-02-1.png'), 
                        title: 'FREE COUNSELLING', 
                        desc: 'We provide free counselling to our respective students by our expert counsellors who are graduated from UK Universities.'
                    },
                    {
                        icon: require('../assets/img/icons/aeroland-branding-box-image-02-1.png'), 
                        title: 'STUDENT ADMISSION SERVICES', 
                        desc: 'Our admission services are mainly structured for international and EU student but we also offer admission services to the home students with limited options.'
                    },
                    {
                        icon: require('../assets/img/icons/aeroland-branding-box-image-02-1.png'), 
                        title: 'COMPLIANCE ASSISTANCE', 
                        desc: 'We have dedicated compliance team to guide our students thourgh visa processing.'
                    },
                    {
                        icon: require('../assets/img/icons/aeroland-branding-box-image-02-1.png'), 
                        title: 'STUDENT ACCOMMODATION SERVICES', 
                        desc: 'Wilor Education is proud to announce its partnership with Casita who provides excellent student accommodation service at a reasonable price.'
                    },
                    {
                        icon: require('../assets/img/icons/aeroland-branding-box-image-02-1.png'), 
                        title: 'PARTNER UNIVERSITY SERVICES', 
                        desc: 'Our service is not limited to the students only but also we provide a number of service for the universities that we are associated with.'
                    },
                    // {
                    //     icon: require('../assets/img/icons/aeroland-branding-box-image-03-1.png'), 
                    //     title: 'END-TO-END SERVICES', 
                    //     desc: 'Our services are open and never ends at a certain point. We will ensure that the services and help will be continued even after completion of course in the UK if needed'
                    // },
                ]
            }
        }
    }
</script>

<style scoped>
    .gallery-img:hover{
        box-shadow: 0 2px 30px rgba(89, 69, 230, 0.12);
        transform: translateY(-5px);
        cursor: pointer;
        transition: all .2s;
    }
</style>