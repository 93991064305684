<template>
  <div class="hero-software hero-swiper-btn" id="home">
    <div class="container_f1">
      <div class="swiper-wrapper slider-wrap">
        <swiper :options="swiperOption">



         <!-- ------------------ slider static start ------------------  -->
         
         <div class="swiper-slide ssd" >
               <h3 class="h1 hero-content-title" style="text-align: center; margin-top: 5%; margin-left: 37%">
                
                </h3>

                <!-- <h3 class="h1 hero-content-title" style="text-align: center; font-family: Rockwell; margin-top: 10%; margin-left: 37%">
                Free Admission Services
                </h3> -->

       
              <div class="hero-item">
              <div class="hero-content text-center">
                <!-- <h1 class="h1 hero-content-title">
                  <font color="white" style="font-family: Rockwell;">Free </font><br>
                  <font color="white" style="font-family: Rockwell;">Admission </font><br>
                  <font color="white" style="font-family: Rockwell;">Services</font>
                </h1>              -->
              
                <div class="slider-button mt-20" style="text-align: start; margin-left: -70px;">
                  <br>
                  <br> 
                  <br>
                  
                 
                  <router-link
                    :to="{ path: 'admission-process' }"
                    class="btn btn-xl" style="background-color: #c7792c !important; font-size: 10px"
                    ><span style="font-size: large;">Apply</span></router-link
                  >
                </div>
              </div>
            </div>

               
               
          <!-- <img src="../../assets/img/banners/wilor_slider.jpg" alt="logo"  width="100%" height="50%"> -->
           <!-- <div class="hero-item">
              <div class="hero-content text-center">
                <h3 class="h1 hero-content-title" >
                  <font color="yellow">Free Admission Services</font>
                </h3>             
              
                <div class="slider-button mt-20" style="text-align: start;">
                  <router-link
                    :to="{ path: 'admission-process' }"
                    class="ht-btn ht-btn-md"
                    >Apply Now</router-link
                  >
                </div>
              </div>
            </div> -->
         
         </div>
       


         <!-- ----------------------- slider static end --------------------  -->









      <!-- ----------- slider dynamic start -----------  -->

          <!-- <div
            v-for="(value, index) in slider"
            :key="index"
            class="swiper-slide bg-cover"
            :style="{ backgroundImage: `url(${value.path})` }"
          >
           <div class="hero-item">
              <div class="hero-content text-center">
                <h3 class="h1 hero-content-title">
                    <font color="#FFFFFF">{{ value.title_word }}</font>
                </h3>
                <h6 class="hero-content-subtitle mt-20">
                  <strong>{{ value.title_line1 }}</strong>
                </h6>
                <div class="slider-button mt-20" style="text-align: start;">
                  <router-link
                    :to="{ path: 'admission-process' }"
                    class="ht-btn ht-btn-md"
                    >Apply Now</router-link
                  >
                </div>
              </div>
            </div>
          </div> -->


          <!-- ---------------- slider dynamic end ---------------------  -->

          <!-- Optional controls -->
          <div
            class="swiper-button-prev swiper-button-white"
            slot="button-prev"
          ></div>
          <div
            class="swiper-button-next swiper-button-white"
            slot="button-next"
          ></div>
        </swiper>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      swiperOption: {
        speed: 1500,
        loop: true,
        effect: "fade",
        autoplay: false,
        spaceBetween: 30,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      slider: {
        path: null,
      },
    };
  },
  mounted() {
    this.getSlider();
  },
  methods: {
    getSlider() {
      axios.get("sliders").then((response) => {
        this.slider = response.data.data.sliders;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/variabls.scss";
 

.ssd {
  background-image: url('../../assets/img/banners/wilor_banner.jpg');
  background-repeat: no-repeat;
  // background-attachment: fixed;
  // background-position: 50% 50%;
  background-size: cover;

  
}







.slider-wrap{
  margin-top: 130px;
  height: 450px;
  @media screen and (min-width: 1025px) and (max-width: 1280px) {
    margin-top: 180px;
    height: 350px;
  }
  @media #{$desktop-device, $tablet-device} {
    margin-top: 200px;
    height: 300px;
  }
  @media #{$large-mobile} {
    margin-top: 160px;
    height: 250px;
  }
  @media #{$extra-small-mobile} {
    margin-top: 160px;
    height: 380px;
  }
}
.hero-item {
  margin-left: 100px;
  height: 600px;
  display: table;
  margin-top: 50px;
  // responsive
  @media #{$desktop-device, $tablet-device} {
    height: 600px;
  }
  @media #{$large-mobile} {
    height: 450px;
  }
  @media #{$extra-small-mobile} {
    height: 380px;
  }
}
.hero-content {
  width: 750px;
  padding: 10px;
  margin-top: 100px;
  &-subtitle {
    color: $white;
    font-weight: 400;
    padding-bottom: 20px;
    text-align: justify;
  }
  &-title {
    color: $white;
    font-size: 40px;
    margin-bottom: 0;
    text-align: justify;
    // responsive
    @media #{$large-mobile} {
      font-size: 30px;
    }
    @media #{$small-mobile} {
      font-size: 20px;
    }
  }
  .sub-heading {
    color: $white;
    font-size: 16px;
    padding-top: 40px;
    text-align: justify;
  }
  .hero-btn {
    margin-top: 40px;
    float: left;
    .ht-btn {
      margin-right: 30px;
      @media #{$extra-small-mobile} {
        margin-right: 5px;
      }
      &:last-child {
        margin-right: 0;
      }
      &--gradient2 {
        // background: linear-gradient(90deg, rgb(240, 168, 107) 0%, rgb(225, 118, 67) 100%);
        background: #0b6faa;
      }
    }
  }
   @media screen and (min-width: 1025px) and (max-width: 1280px) {
    margin-top: 100px;
    height: 600px;

  }
  @media #{$desktop-device, $tablet-device} {
    margin-top: 100px;
    height: 600px;
  }
  @media #{$large-mobile} {
    margin-top: 10px;
    height: 450px;
  }
  @media #{$extra-small-mobile} {
    margin-top:  10px;
    height: 450px;
  }
}
</style>
