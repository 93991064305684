<template>
  <div class="row mtn-30">
    <div class="col-lg-3 col-md-6 col-sm-6 wow move-up">
      <div class="fun-fact--one mt-30 text-center">
        <h5 class="fun-fact__title">
          + Students <br />
          served
        </h5>
        <div class="fun-fact__count counter">
        536
        <!--  <ICountUp :endVal="count_report.total_student" /> --->
        </div>
        <!-- <span class="fun-fact__text">HAPPY CLIENTS</span> -->
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-6 wow move-up">
      <div class="fun-fact--one mt-30 text-center">
        <h5 class="fun-fact__title">
          + UK Education <br />
          Fair/Expo
        </h5>
        <div class="fun-fact__count counter">
        15
        <!--  <ICountUp :endVal="count_report.uk_education_fair_count" /> -->
        </div>
        <!-- <span class="fun-fact__text">BRANDING PROJECTS</span> -->
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-6 wow move-up">
      <div class="fun-fact--one mt-30 text-center">
        <h5 class="fun-fact__title">
          + Courses <br />
          Offered
        </h5>
        <div class="fun-fact__count counter">
          <ICountUp :endVal="count_report.total_course" />
        </div>
        <!-- <span class="fun-fact__text">PROFESSIONAL EXPERTS</span> -->
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-6 wow move-up">
      <div class="fun-fact--one mt-30 text-center">
        <h5 class="fun-fact__title">
          + Recruiting <br />
          Universities
        </h5>
        <div class="fun-fact__count counter">
          <ICountUp :endVal="count_report.total_university" />
        </div>
        <!-- <span class="fun-fact__text">BLOG POSTS</span> -->
      </div>
    </div>
  </div>
</template>

<script>
import ICountUp from "vue-countup-v2";
export default {
  name: "FunFactOne",
  components: {
    ICountUp,
  },
  data() {
    return {
      delay: 300,
      count_report: {
        uk_education_fair_count: 0,
        total_student: 0,
        total_course: 0,
        total_university: 0,
      },
    };
  },
  mounted() {
    this.getCount();
  },
  methods: {
    getCount() {
      axios.get("count-report").then((response) => {
        this.count_report = response.data.data;
        this.count_report.uk_education_fair_count = Number(
          response.data.data.uk_education_fair_count
        );
      });
    },
  },
};
</script>
