<template>
  <div class="contact-form-section section-space--ptb_30" id="contact">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 order-2 order-lg-1">
          <div class="section-title text-left section-space--mb_60">
            <h2
              class="font-weight--bold mb-15 wow move-up"
              style="font-size: 2rem"
            >
              Send Message
            </h2>
          </div>
          <div class="contact-from-wrapper wow move-up">
            <form
              id="contact-form"
              action="http://hasthemes.com/file/mail.php"
              method="post"
              @submit.prevent="contactSubmit"
            >
              <div class="step0">
                <div
                  class="col-md-12"
                  v-if="validation_error"
                  style="margin-top: 20px"
                >
                  <div class="form-group">
                    <div>
                      <ul>
                        <li
                          class="text-danger"
                          v-for="error in validation_error"
                        >
                          {{ error[0] }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="contact-page-form">
                <div class="contact-input">
                  <div class="contact-inner">
                    <input
                      name="con_name"
                      type="text"
                      v-model="contact.name"
                      placeholder="Name *"
                    />
                  </div>
                  <div class="contact-inner">
                    <input
                      required
                      name="con_email"
                      type="email"
                      v-model="contact.email"
                      placeholder="Email *"
                    />
                  </div>
                </div>
                <div class="contact-inner">
                  <input
                    required
                    name="con_subject"
                    type="text"
                    v-model="contact.phone"
                    placeholder="phone *"
                  />
                </div>
                <div class="contact-inner">
                  <input
                    required
                    name="con_subject"
                    type="text"
                    v-model="contact.subject"
                    placeholder="Subject *"
                  />
                </div>
                <div class="contact-inner contact-message">
                  <textarea
                    required
                    name="con_message"
                    placeholder="Message *"
                    v-model="contact.message"
                  ></textarea>
                </div>
                <div class="contact-submit-btn">
                  <p class="form-messege">
                    {{ msg }}
                  </p>
                  <button
                    class="ht-btn ht-btn-md"
                    type="submit"
                    v-if="!btnloading"
                  >
                    Send message
                  </button>
                  <button class="ht-btn ht-btn-md" v-if="btnloading">
                    <span class="spinner-border spinner-border-sm"></span>
                    Processing..
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Contact from "../gql/mutation/Contact.gql";
import Swal from "sweetalert2";
export default {
  name: "ContactForm",
  data() {
    return {
      contact: {
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      },
      btnloading: false,
      msg: "",
      validation_error: [],
    };
  },
  methods: {
    contactSubmit() {
      this.btnloading = true;
      axios
        .post("contact-form", this.contact)
        .then((response) => {
          this.btnloading = false;
          if (response.data.status === "success") {
            this.completeMessage(response.data);
            this.clearField();
          }
        })
        .catch((err) => {
          this.btnloading = false;
          if (err.response.status == 422) {
            this.validation_error = err.response.data.errors;
            this.completeMessage({
              status: "error",
              message: "validation error",
            });
          } else {
            this.completeMessage(err);
            this.btnloading = false;
          }
        });
    },

    completeMessage(data) {
      Swal.fire({
        icon: data.status,
        title: data.status == "error" ? "Oops..." : "Success",
        text: data.message,
      });
    },

    clearField() {
      this.contact = {
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      };
      this.validation_error = [];
    },
  },
};
</script>