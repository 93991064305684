<template>
  <div>
    <HeaderElement/>
    <div class="banner-cutom">
      <img src="../assets/my-img/home/banner.png" alt="" width="100%">
    </div>
    <div class="section-title-wrapper text-center mb-1 mt-3">
        <h2 class="section-title mb-30">UK Education Services for Companiess</h2>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <p>Wilor Education Ltd offer UK education services for government and private companies as well. There are many talented people working for the government and in private sector after their study. In many cases, Companies offer fund or full-free Scholarship to their employees to obtain Higher Education relevant to their field so that they can enhance company’s features and help grow the company’s future development.</p>
          <p>For those Government and Private companies, we are ready to help. We can guide your employees to get admitted to one of the most popular Universities in the UK. We will ensure they get the best quality of service from us and get all the guidance towards successful completion of their study. Our services for Government and Private Companies are as follows;</p>
          <ul class="check-list green-check-list section-space--mb_50">
            <li class="list-item">Preparation for Admission</li>
            <li class="list-item">Application process to Higher Institution in the UK</li>
            <li class="list-item">Gathering Requirements help</li>
            <li class="list-item">Personal Statement guideline </li>
            <li class="list-item"> Finding Accommodation </li>
            <li class="list-item"> Visa Guidance</li>
            <li class="list-item">Pre-arrival briefing</li>
            <li class="list-item">Airport Pick-up</li>
            <li class="list-item">Additional help if required</li>
          </ul>
        </div> 
    </div>
    </div> 

      <!-- footer section -->
      <FooterMain />

      <!-- offcanvas search component -->
      <OffcanvasSearchBox />
  </div>
</template>

<script>
import HeaderElement from '@/components/HeaderElementOthers'
import CourseBoxOne from '@/components/CourseBoxOne'
import CourseBoxTwo from '@/components/CourseBoxTwo'
import UniversityTab from '@/components/UniversityTab'
import Newsletter from '@/components/Newsletter'
import FooterMain from '@/components/FooterMain'
import OffcanvasSearchBox from '@/components/OffcanvasSearchBox'
import Accordion from '@/components/AdmissionAcordin'
import SideBarArea from '@/components/SideBarArea'
export default {
  components:{
    HeaderElement,
    CourseBoxOne,
    CourseBoxTwo,
    UniversityTab,
    Newsletter,
    FooterMain,
    OffcanvasSearchBox,
    Accordion,
    SideBarArea,
  }
}
</script>

<style scoped>
  .section-title-wrapper h2{
    font-size: 2.3rem !important;
  }
</style>