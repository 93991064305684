<template>
    <div class="row pricing-table-one">
        <div class="col-md-6 col-xl-4 pricing-table wow move-up" v-for="(pricing, index) in pricingTable" :key="index">
            <div class="pricing-table__inner px-0 pt-0">
                <img :src="pricing.thumb" class="img-fluid" :alt="pricing.alt">
                <div class="pricing-table__footer px-1">
                    <a href="#" class="btn btn-block" :class="{'ht-btn--outline': pricing.active}">{{pricing.btutton}}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'pricing',
        data (){
            return {
                pricingTable: [
                    {
                        thumb: require('../assets/img/features/slide-1.png'),
                        title: 'Free',
                        value: '0',
                        duration: 'mo',
                        alt: 'Pricing Thumb',
                        isPopular: false,
                        active: true,
                        btutton: 'The University of Sydney',
                        price: ['03 projects', 'Power And Predictive Dialing', 'Quality & Customer Experience', 'Try for free, forever!']
                    },
                    {
                        thumb: require('../assets/img/features/slide-2.png'),
                        title: 'Personal',
                        value: '19',
                        duration: 'mo',
                        alt: 'Pricing Thumb',
                        isPopular: true,
                        active: true,
                        btutton: 'Baylor University',
                        price: ['30 project', 'Power And Predictive Dialing', 'Quality & Customer Experience', '24/7 phone and email support']
                    },
                    {
                        thumb: require('../assets/img/features/slide-3.png'),
                        title: 'Group',
                        value: '29',
                        duration: 'mo',
                        alt: 'Pricing Thumb',
                        isPopular: false,
                        active: true,
                        btutton: 'University of Hartford',
                        price: ['50 projects', 'Power And Predictive Dialing', 'Quality & Customer Experience', '24/7 phone and email support']
                    },
                    {
                        thumb: require('../assets/img/features/slide-1.png'),
                        title: 'Enterprise',
                        value: '49',
                        duration: 'mo',
                        alt: 'Pricing Thumb',
                        isPopular: false,
                        active: true,
                        btutton: 'Lynn University',
                        price: ['Unlimited projects', 'Power And Predictive Dialing', 'Quality & Customer Experience', '24/7 phone and email support']
                    }
                ]
            }
        }
    }
</script>

<style scoped>
    .pricing-table__inner img{
        min-height: 150px !important;
    }
</style>