<template>
  <div>
    <HeaderElement />
    <div class="banner-cutom">
      <img
        src="../assets/img/bg/service.jpeg"
        alt=""
        width="100%"
        id="banner-image"
      />
    </div>
    <div class="section-title-wrapper text-center mb-1 mt-3">
      <h2 class="section-title mb-30">{{ title }}</h2>
    </div>
    <div class="accordion-wrapper section-space--pb_90">
      <div class="container">
        <div class="row">
          <div class="col-md-8 mx-auto mt-5" v-if="id == 1">
            <p>
              Our admission services are mainly structured for international and
              EU student. We also offer UK University admission services to the
              Home students with limited options. Check out our wide range of
              free services below.
            </p>
            <div class="faq-wrapper section-space--pt_90">
              <div class="section-elements-title mb-30">
                <h4>Our Free Student Admission Services</h4>
              </div>
              <!-- accordion component -->
              <div class="accordion-faq wow move-up">
                <b-card
                  v-for="(value, index) in serviceContent"
                  :key="index + 'acc'"
                  no-body
                >
                  <b-card-header header-tag="header">
                    <b-button
                      block
                      v-b-toggle="'accordion-' + index"
                      class="faq-title"
                      >{{ value.title }}</b-button
                    >
                  </b-card-header>
                  <b-collapse
                    :id="'accordion-' + index"
                    visible
                    accordion="my-accordion"
                  >
                    <b-card-body>
                      <b-card-text>
                        {{ value.value }}
                      </b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </div>
          </div>
          <div class="col-md-8 pb-5 mx-auto" v-else>
            <div
              v-for="(singleService, index) in serviceContent"
              :key="index + 'sc'"
            >
              <div v-if="singleService.is_point == 1">
                <p>
                  <b>{{ singleService.title }}</b>
                </p>
                <ul class="check-list green-check-list section-space--mb_50">
                  <li
                    class="list-item"
                    v-for="(list_two, index2) in singleService.list"
                    :key="index2 + 'list_two'"
                  >
                    {{ list_two.value }}
                  </li>
                </ul>
              </div>

              <div v-else>
                <p>
                  <b>{{ singleService.title }}</b>
                </p>
                <p
                  v-for="(list_two, ind) in singleService.list"
                  :key="ind + 'list_three'"
                >
                  {{ list_two }}
                </p>
              </div>
            </div>
          </div>
          <!-- <SideBarArea /> -->
        </div>
<br>
        <div class="col-md-12 text-center mb-3">
          <a data-v-04e266c5="" href="/contact" class="ht-btn ht-btn-md"
            >Request a call back</a
          >
        </div>

      </div>

    </div>


    <!-- footer section -->
    <FooterMain />

    <!-- offcanvas search component -->
    <OffcanvasSearchBox />
  </div>
</template>

<script>
import HeaderElement from "@/components/HeaderElementOthers";
import CourseBoxOne from "@/components/CourseBoxOne";
import CourseBoxTwo from "@/components/CourseBoxTwo";
import UniversityTab from "@/components/UniversityTab";
import Newsletter from "@/components/Newsletter";
import FooterMain from "@/components/FooterMain";
import OffcanvasSearchBox from "@/components/OffcanvasSearchBox";
import Accordion from "@/components/AdmissionAcordin";
import SideBarArea from "@/components/SideBarArea";
export default {
  components: {
    HeaderElement,
    CourseBoxOne,
    CourseBoxTwo,
    UniversityTab,
    Newsletter,
    FooterMain,
    OffcanvasSearchBox,
    Accordion,
    SideBarArea,
  },
  data() {
    return {
      id: this.$route.params.id,
      title: this.$route.params.title,
      serviceContent: [],
      currentIndex: 0,
    };
  },
  // beforeRouteUpdate(to, from, next) {

  //   // react to route changes...
  //   // don't forget to call next()
  //   next();
  // },

  watch: {
    $route(to, from) {
      this.id = this.$route.params.id;
      this.getServices();
    },
  },

  created() {
    this.getServices();
  },

  methods: {
    getServices() {
      axios.get("service-content?id=" + this.id).then((response) => {
        this.serviceContent = response.data.data.service_page;
        this.title = response.data.data.page_title;
      });
    },
  },
};
</script>

<style scoped>
.section-title-wrapper h2 {
  font-size: 2.3rem !important;
}
</style>
