import Vue from 'vue'
import App from './App.vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueRouter from 'vue-router'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueSilentbox from 'vue-silentbox'
import WOW from 'wow.js/dist/wow.js';


import 'bootstrap/dist/css/bootstrap.css'
import '../src/assets/css/fontawesome-all.min.css'
import 'swiper/dist/css/swiper.css'
import 'animate.css/animate.min.css'
import '../src/assets/scss/style.scss'
import 'slick-carousel/slick/slick.css'
import moment from 'moment'


Vue.use(VueSilentbox)
Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(VueAwesomeSwiper)
Vue.use(BootstrapVueIcons)



Vue.config.productionTip = false
Vue.config.productionSourceMap = false

// axios setup
window.axios = require('axios');
 window.axios.defaults.baseURL = 'https://api.wiloreducation.co.uk/api';
//window.axios.defaults.baseURL = 'http://127.0.0.1:8000/api';

import { routes } from './router/index'
import { createProvider } from './vue-apollo'

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

Vue.filter('formatDate', function (value) {

  if (value) {

    return moment(String(value)).format('MM/DD/YYYY hh:mm');

  }

});

new Vue({
  render: h => h(App),
  router,
  apolloProvider: createProvider(),

  mounted() {
    new WOW().init();
  }
}).$mount('#app')
