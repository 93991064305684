<template>
  <div>
    <HeaderElement />
    <div class="banner-cutom">
      <img src="../assets/img/bg/branch.jpeg" alt="" width="100%" />
    </div>
    <div class="section-title-wrapper text-center mb-1 mt-3">
      <h2 class="section-title mb-30">
        <img src="../assets/my-img/uk.png" alt="" srcset="" height="45" /> UK
        Head Office
      </h2>
    </div>
    <div class="container">

  <b-tabs content-class="mt-3">

    <!-- uk head office  -->
    <b-tab title="Head Office" active> 
      <div class="row">
        <div class="col-md-12 mx-auto">
          <div class="row mb-12 pb-5">        
            <div class="col-md-4 contact-title mx-auto">
              <br />
              <h2>Wilor Education</h2>
              <p>{{ setting.address }}</p>
              <p>{{ setting.email }}</p>
              <p>Tel: {{ setting.contact }}</p>
              <p>Web: {{ setting.web }}</p>
            </div>
            <div class="col-md-8 contact-title mx-auto">
              <iframe
               src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.9190060086694!2d-0.060337923089720295!3d51.51470191016949!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876039e7bb72afd%3A0x34a3a85024580a1!2sCheltenham%20House!5e0!3m2!1sen!2sbd!4v1700137527548!5m2!1sen!2sbd" width="100%"
                height="300"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"></iframe>
            </div>
          </div>
        </div>
      </div>
    </b-tab>


    <b-tab title="UK Branch Office">
      <div class="row">
        <div class="col-md-12 mx-auto">
          <div class="row mb-12 pb-5">        
            <div class="col-md-4 contact-title mx-auto">
              <br />
              <h2>Wilor Education</h2>
              <p>Office 6, 480 Larkshall, Road, London </p>
               <p>Post Code: E49HH</p> 
              <p>City: London</p>
              <p>Country: United Kingdom</p>
              <p>feroz@wiloreducation.co.uk</p>
              <p>Tel: +447368209476</p>
              <p>Web: https://www.wiloreducation.co.uk/</p>
            </div>
            <div class="col-md-8 contact-title mx-auto">
              
              <iframe
               src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2477.8073666800033!2d-0.0036198230852982627!3d51.60841810331824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761e0295d89a1d%3A0xe997b491e7b1d80e!2s480%20Larkshall%20Rd%2C%20London%20E4%209HH%2C%20UK!5e0!3m2!1sen!2sbd!4v1700394309727!5m2!1sen!2sbd" width="100%"
                height="300"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"></iframe>
            </div>
          </div>
        </div>
      </div>
    </b-tab>


    <b-tab title="College Campus" >
      <div class="row">
        <div class="col-md-12 mx-auto">
          <div class="row mb-12 pb-5">        
            <div class="col-md-4 contact-title mx-auto">
              <br />
              <h2>Wilor Education</h2>
              <p>Westlink House, 981 Great west Road, Brentford </p>
               <p>Post Code: TW89DN</p> 
              <p>City: Brentford</p>
              <p>Country: United Kingdom</p>
              <p>wiloreducation@gmail.com</p>
              <p>Tel: +447368209476</p>
              <p>Web: https://www.wiloreducation.co.uk/</p>
            </div>
            <div class="col-md-8 contact-title mx-auto">
              <iframe
               src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2484.522244953964!2d-0.3229900230910863!3d51.48528321231855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760da5a9ef5a11%3A0x97a83d2657b037db!2sWest%20Link%20House%2C%20981%20Great%20West%20Rd%2C%20Brentford%20TW8%209DN%2C%20UK!5e0!3m2!1sen!2sbd!4v1700394586304!5m2!1sen!2sbd" width="100%"
                height="300"
                style="border: 0"
                allowfullscreen=""
                loading="lazy">
              </iframe>
            </div>
          </div>
        </div>
      </div>
    </b-tab>
  </b-tabs>












    </div>

    <!-- footer section -->
    <FooterMain />

    <!-- offcanvas search component -->
    <OffcanvasSearchBox />
  </div>
</template>

<script>
import HeaderElement from "@/components/HeaderElementOthers";
import CourseBoxOne from "@/components/CourseBoxOne";
import CourseBoxTwo from "@/components/CourseBoxTwo";
import CourseTab from "@/components/CourseTab";
import Newsletter from "@/components/Newsletter";
import FooterMain from "@/components/FooterMain";
import OffcanvasSearchBox from "@/components/OffcanvasSearchBox";
export default {
  components: {
    HeaderElement,
    CourseBoxOne,
    CourseBoxTwo,
    CourseTab,
    Newsletter,
    FooterMain,
    OffcanvasSearchBox,
  },
  data() {
    return {
      setting: {},
    };
  },

  mounted() {
    this.getSetting();
  },
  methods: {
    getSetting() {
      axios.get("setting").then((response) => {
        this.setting = response.data.data.setting;
      });
    },
  },
};
</script>

<style scoped>
.section-title-wrapper h2 {
  font-size: 2.3rem !important;
}
.contact-title h2 {
  font-size: 1.5rem !important;
}
.contact-title p {
  margin-bottom: 0px !important;
}
.contact-title img {
  width: 60%;
}
</style>
