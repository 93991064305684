<template>
    <div class="tab-mission-wrap section-space--mt_60">
        <div class="row align-items-center">
            <div class="col-lg-7">
                <div class="tab-mission-image">
                    <img src="../assets/img/banners/aeroland-tab-content-image-05.png" alt="" class="img-fluid">
                </div>
            </div>
            <div class="col-lg-5">
                <div class="tab-content-inner">
                    <h4 class="font-weight--light" style="">The best ways<br>to create websites</h4>
                    <p class="dec section-space--mt_40 section-space--mb_40">We bring on like-minded and talented members to help you grow a stronger business for fiercely reaching towards higher goals every day. </p>
                    <div class="tab-button section-space--mb_40">
                        <a href="#" class="ht-btn ht-btn-md">Learn more </a>
                    </div>
                    <h6 class="sub-heading">What’s next in AeroLand? <a href="#" class="hover-style-link hover-style-link--green">Learn more</a></h6>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TabContentTwo'
    };
</script>

