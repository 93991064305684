<template>
  <div>
    <HeaderElement/>
    <div class="banner-cutom">
      <img src="../assets/img/bg/whyus.jpeg" alt="" width="100%">
    </div>
    <div class="section-title-wrapper text-center mb-1 mt-3">
        <h2 class="section-title mb-30">Job Opportunity</h2>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12" style="text-align: justify;">
          <p class="mt-2">Thank you for taking an interest in the employment opportunities at Wilor Education Limited. As you would expect from one of the largest and most diverse Educational and Legal consultancy firms in the UK, we offer a stimulating work environment with a wide variety of Job opportunities. The organisation prides itself on promoting equality policy and welcomes applications regardless of gender, race, ethnic origin, disability or religious belief.</p>
          <p class="mb-5">
            The Wilor Education Ltd is continuously looking for talented individuals with self-motivation and a driven can-do attitude to fill various positions in presentation, Marketing, HR, and IT department.
          </p>

        </div>
    </div>
    </div>


    <div class="col-md-12 text-center mb-3">
      <a data-v-04e266c5="" href="/contact" class="ht-btn ht-btn-md"
        >Request a call back</a
      >
    </div>

      <!-- footer section -->
      <FooterMain />

      <!-- offcanvas search component -->
      <OffcanvasSearchBox />
  </div>
</template>

<script>
import HeaderElement from '@/components/HeaderElementOthers'
import CourseBoxOne from '@/components/CourseBoxOne'
import CourseBoxTwo from '@/components/CourseBoxTwo'
import UniversityTab from '@/components/UniversityTab'
import Newsletter from '@/components/Newsletter'
import FooterMain from '@/components/FooterMain'
import OffcanvasSearchBox from '@/components/OffcanvasSearchBox'
import Accordion from '@/components/AdmissionAcordin'
import SideBarArea from '@/components/SideBarArea'
export default {
  components:{
    HeaderElement,
    CourseBoxOne,
    CourseBoxTwo,
    UniversityTab,
    Newsletter,
    FooterMain,
    OffcanvasSearchBox,
    Accordion,
    SideBarArea,
  }
}
</script>

<style scoped>
  .section-title-wrapper h2{
    font-size: 2.3rem !important;
  }
</style>
