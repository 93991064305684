<template>
  <div class="row pricing-table-one">
    <div
      class="col-md-6 col-xl-3 pricing-table wow move-up"
      v-for="(pricing, index) in pricingTable"
      :key="index"
    >
      <div
        class="pricing-table__inner px-0 pt-0"
        style="padding: 10px !important"
      >
        <!-- <div class="pricing-table__feature-mark" v-if="pricing.isPopular">
                    <span>Popular Choice</span>
                </div> -->
        <img :src="pricing.thumb" class="img-fluid" :alt="pricing.alt" />
        <!-- <div class="pricing-table__header">
                    <div class="pricing-table__image">
                        <img :src="pricing.thumb" class="img-fluid" :alt="pricing.alt">
                    </div>
                    <h5 class="pricing-table__title">{{ pricing.title }}</h5>
                    <div class="pricing-table__price-wrap">
                        <h6 class="currency">$</h6>
                        <h6 class="price">{{ pricing.value }}</h6>
                        <h6 class="period">/{{ pricing.duration }}</h6>
                    </div>
                </div> -->
        <!-- <div class="pricing-table__body">
                    <ul class="pricing-table__list">
                        <li v-for="(list, index) in pricing.price" :key="index">{{ list }}</li>
                    </ul>
                </div> -->
        <div class="mt-2 px-1">
          <router-link
            :to="{ path: pricing.action }"
            class="btn btn-block"
            :class="{ 'ht-btn--outline': pricing.active }"
            >{{ pricing.btutton }}</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pricing",
  data() {
    return {
      pricingTable: [
        {
          thumb: require("../assets/img/features/slide-1.png"),
          title: "Free",
          value: "0",
          duration: "mo",
          alt: "Pricing Thumb",
          isPopular: false,
          active: true,
          btutton: "Find a University",
          action: "universities",
          price: [
            "03 projects",
            "Power And Predictive Dialing",
            "Quality & Customer Experience",
            "Try for free, forever!",
          ],
        },
        {
          thumb: require("../assets/img/features/slide-2.png"),
          title: "Personal",
          value: "19",
          duration: "mo",
          alt: "Pricing Thumb",
          isPopular: true,
          active: true,
          btutton: "Find a Course",
          action: "courses",
          price: [
            "30 project",
            "Power And Predictive Dialing",
            "Quality & Customer Experience",
            "24/7 phone and email support",
          ],
        },
        {
          thumb: require("../assets/img/features/slide-3.png"),
          title: "Group",
          value: "29",
          duration: "mo",
          alt: "Pricing Thumb",
          isPopular: false,
          active: true,
          btutton: "Find a Scholarship",
          action: "scholarship",
          price: [
            "50 projects",
            "Power And Predictive Dialing",
            "Quality & Customer Experience",
            "24/7 phone and email support",
          ],
        },
        {
          thumb: require("../assets/img/features/slide-4.jpeg"),
          title: "Enterprise",
          value: "49",
          duration: "mo",
          alt: "Pricing Thumb",
          isPopular: false,
          active: true,
          btutton: "Application Process",
          action: "admission-process",
          price: [
            "Unlimited projects",
            "Power And Predictive Dialing",
            "Quality & Customer Experience",
            "24/7 phone and email support",
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
.pricing-table__inner img {
  min-height: 150px !important;
}
</style>
