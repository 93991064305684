<template>
    <div class="pricing-table-area section-space--pb_120 pt-2 theme_bg-2" id="pricing">
        <div class="pricing-table-content-area">
            <div class="container-fluid container-fluid--cp-150">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <h3 class="heading text-white text-center my-5">Schools, Faculties and Departments!</h3>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="row pricing-table-three">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pricing-table wow move-up">
                                <div class="pricing-table__inner">
                                    <div class="pricing-table__header">
                                        <!-- <div class="pricing-table__image">
                                            <img src="../assets/img/pricing/aeroland-pricing-image-06.png" class="img-fluid" alt="">
                                        </div> -->
                                        <h5 class="pricing-table__title">Business School</h5>
                                    </div>
                                    <!-- <div class="pricing-table__body">
                                        <p>Lorem ipsum dolor sit amet, consectetur elit. Suspe ndisse suscipit sagittis leo sitea rem ipsum dolor sit amet, consectetu.</p>
                                    </div> -->
                                    <!-- <div class="pricing-table__footer">
                                        <a href="#" class="ht-btn ht-btn-default">Get started</a>
                                    </div> -->
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pricing-table pricing-table--popular wow move-up">
                                <div class="pricing-table__inner">
                                    <div class="pricing-table__feature-mark">
                                        <span>Popular Choice</span>
                                    </div>
                                    <div class="pricing-table__header">
                                        <!-- <div class="pricing-table__image">
                                            <img src="../assets/img/pricing/aeroland-pricing-image-05.png" class="img-fluid" alt="">
                                        </div> -->
                                        <h5 class="pricing-table__title">Computer Science</h5>
                                    </div>
                                    <!-- <div class="pricing-table__body">
                                        <p>Lorem ipsum dolor sit amet, consectetur elit. Suspe ndisse suscipit sagittis leo sitea rem ipsum dolor sit amet, consectetu.</p>
                                    </div> -->
                                    <!-- <div class="pricing-table__footer">
                                        <a href="#" class="ht-btn ht-btn-default">Get started</a>
                                    </div> -->
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pricing-table wow move-up">
                                <div class="pricing-table__inner">
                                    <div class="pricing-table__header">
                                        <h5 class="pricing-table__title">Creative Arts</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pricing-table wow move-up">
                                <div class="pricing-table__inner">
                                    <div class="pricing-table__header">
                                        <h5 class="pricing-table__title">Education</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pricing-table wow move-up">
                                <div class="pricing-table__inner">
                                    <div class="pricing-table__header">
                                        <h5 class="pricing-table__title">Architecture</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pricing-table wow move-up">
                                <div class="pricing-table__inner">
                                    <div class="pricing-table__header">
                                        <h5 class="pricing-table__title">Economics</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pricing-table wow move-up">
                                <div class="pricing-table__inner">
                                    <div class="pricing-table__header">
                                        <h5 class="pricing-table__title">Humanities</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pricing-table wow move-up">
                                <div class="pricing-table__inner">
                                    <div class="pricing-table__header">
                                        <h5 class="pricing-table__title">Law</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pricing-table wow move-up">
                                <div class="pricing-table__inner">
                                    <div class="pricing-table__header">
                                        <h5 class="pricing-table__title">Life and Medical Sciences</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pricing-table wow move-up">
                                <div class="pricing-table__inner">
                                    <div class="pricing-table__header">
                                        <h5 class="pricing-table__title">Physics, Astronomy and Mathematics</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pricing-table wow move-up">
                                <div class="pricing-table__inner">
                                    <div class="pricing-table__header">
                                        <h5 class="pricing-table__title">Health and Social Work Science</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pricing-table wow move-up">
                                <div class="pricing-table__inner">
                                    <div class="pricing-table__header">
                                        <h5 class="pricing-table__title">Engineering And Technology</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PricingThree',
    }
</script>