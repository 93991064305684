<template>
  <div>
    <div class="row">
          <div class="col-md-4">
        <div class="form-group">
          <select
            class="form-control"
            v-model="course_category_id"
            @change="getCourse()"
          >
            <option value="">Filter By Course Category</option>
            <option
              v-for="cat in courseTypeLists"
              :key="'ca' + cat.id"
              :value="cat.id"
            >
              {{ cat.title }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <select
            class="form-control"
            v-model="university_id"
            @change="getCourse()"
          >
            <option value="">Filter By University</option>
            <option
              v-for="uni in universityList"
              :key="'uni' + uni.institution_id"
              :value="uni.institution_id"
            >
              {{ uni.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <input
            type="text"
            v-model="title"
            placeholder="Search By Course Title"
            class="form-control"
            @keyup="getCourse()"
          />
        </div>
      </div>
    </div>
    <div class="row pricing-table-one">
      <div
        class="col-md-6 col-xl-4 pricing-table wow move-up"
        v-for="(course, index) in courseList"
        :key="index"
        :class="{ 'pricing-table--popular__bg': course.active }"
      >
        <div
          class="pricing-table__inner"
          :class="{ 'bg-gradient': course.active }"
        >
          <div class="pricing-table__feature-mark" v-if="course.isPopular">
            <span>Popular Choice</span>
          </div>
          <div class="pricing-table__header">
            <!-- <div class="pricing-table__image">
                        <img :src="pricing.thumb" class="img-fluid" :alt="pricing.alt">
                    </div> -->
            <h5 class="pricing-table__title">{{ course.title }}</h5>
            <!-- <div class="pricing-table__price-wrap">
                        <h6 class="currency">$</h6>
                        <h6 class="price">{{ pricing.value }}</h6>
                        <h6 class="period">/{{ pricing.duration }}</h6>
                    </div> -->
          </div>
          <div class="pricing-table__body">
            <ul class="pricing-table__list">
              <li><strong>Intake: </strong>{{ course.intake }}</li>
              <li>
                <strong>Mode of Study: </strong>{{ course.mode_of_study }}
              </li>
              <li><strong>Course Duration: </strong>{{ course.duration }}</li>
              <li><strong>Fee Range: </strong>{{ course.fee_range }}</li>
            </ul>
          </div>
          <div class="pricing-table__footer">  
             <router-link :to="{ name: 'CoursesDetails', params: { id: course.course_category_id }}"
              class="ht-btn ht-btn--outline ht-btn-custom"
              :class="{ 'btn--white': course.active }"
              >Check Your Eligibility</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PricingOne",
  data() {
    return {
      courseTypeLists: [],
      courseList: [],
      universityList: [],
      university_id: "",
      title: "",
      course_category_id:""
    };
  },

  mounted() {
    this.getCourse();
    this.uniList();
    this.courseTypeList();
  },
  methods: {
    getCourse() {
      axios(
        "course-list?university_id=" +
          this.university_id +
          "&title=" +
          this.title+
          '&course_category_id='+
          this.course_category_id
      ).then((response) => {
        this.courseList = response.data.data.course_list;
      });
    },
    uniList() {
      axios("university-list").then((response) => {
        this.universityList = response.data.data.all_university;
      });
    },
    courseTypeList() {
      axios("course-categories").then((response) => {
        this.courseTypeLists = response.data.data.course_categories;
      });
    },
  },
};
</script>