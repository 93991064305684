import gql from 'graphql-tag'

export const ME = gql`
  query ME {
    me {
      id
      name
      email
    }
  }
`
