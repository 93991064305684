<template>
    <div class="accordion-faq wow move-up">
        <b-card no-body>
            <b-card-header header-tag="header">
                <b-button block v-b-toggle.accordion-1 class="faq-title">WE WILL HELP YOU TO FIND THE RIGHT UK UNIVERSITY</b-button>
            </b-card-header>
            <b-collapse id="accordion-1" visible accordion="my-accordion">
                <b-card-body>
                    <b-card-text>
                        At an international student’s initial stage, our experienced team will help and guide you to find out the right course and institutes according to your future plan.

                        So we advise you to visit help and guide page to have a free consultation for your admission.
                    </b-card-text>
                </b-card-body>
            </b-collapse>
        </b-card>

        <b-card no-body>
            <b-card-header header-tag="header">
                <b-button block v-b-toggle.accordion-2 class="faq-title">KNOW ABOUT THE ACADEMIC COURSE LEVEL</b-button>
            </b-card-header>
            <b-collapse id="accordion-2" accordion="my-accordion">
                <b-card-body>
                    <b-card-text>
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nostrum sunt consectetur id, inventore perspiciatis dignissimos! Et tenetur dicta ipsa natus.
                    </b-card-text>
                </b-card-body>
            </b-collapse>
        </b-card>

        <b-card no-body>
            <b-card-header header-tag="header">
                <b-button block v-b-toggle.accordion-3 class="faq-title">ONE TO ONE COUNSELLING FOR UNIVERSITY AND COURSE SELECTION</b-button>
            </b-card-header>
            <b-collapse id="accordion-3" accordion="my-accordion">
                <b-card-body>
                    <b-card-text>
                        As an international student initial stage, our experienced team will help and guide you to find you the right course and institutes according to your future plan. So we advise you please book an appointment to have a free one to one counselling for your admission.
                    </b-card-text>
                </b-card-body>
            </b-collapse>
        </b-card>

        <b-card no-body>
            <b-card-header header-tag="header">
                <b-button block v-b-toggle.accordion-4 class="faq-title">DOCUMENTS REQUIREMENT FOR UK UNIVERSITY ADMISSION</b-button>
            </b-card-header>
            <b-collapse id="accordion-4" accordion="my-accordion">
                <b-card-body>
                    <b-card-text>
                       If you are in an English speaking country you may require showing academic and financial evidence. Academic evidence will help you to show your academic sounds. And Financial evidence makes you aware of how much funding you will be required during your course of study.
                       <br>
                       If you are in a non-English speaking country you may require having following documents in addition.
                       <ul>
                           <li>Your academic sound</li>
                           <li>Your passport</li>
                           <li>All previous visa copy & immigration history form (if you studied before in the UK).</li>
                           <li>UK educational documents (CAS/visa letter, enrolment /course completion letter, award certificate.)</li>
                           <li>Personal statement /statement of purpose.</li>
                           <li>CV (include – phone number, Skype id, present & previous address, next to kin’s & reference)</li>
                           <li>At least one reference letter.</li>
                       </ul>
                    </b-card-text>
                </b-card-body>
            </b-collapse>
        </b-card>
        <b-card no-body>
            <b-card-header header-tag="header">
                <b-button block v-b-toggle.accordion-5 class="faq-title">DOCUMENTS MUST BE IN ENGLISH</b-button>
            </b-card-header>
            <b-collapse id="accordion-5" accordion="my-accordion">
                <b-card-body>
                    <b-card-text>
                      If your educational certificate /bank statements or any other important documents are not in English, you must provide a translation that can be verified by the Home Office. If you are using an overseas account, including a conversion of the amounts on your statements using http://www.oanda.com. It is acceptable to use overseas accounts when applying from inside and outside the UK.
                    </b-card-text>
                </b-card-body>
            </b-collapse>
        </b-card>
        <b-card no-body>
            <b-card-header header-tag="header">
                <b-button block v-b-toggle.accordion-6 class="faq-title">UKVI IELTS PREPARATION</b-button>
            </b-card-header>
            <b-collapse id="accordion-6" accordion="my-accordion">
                <b-card-body>
                    <b-card-text>
                      In terms of higher education in the UK for the international student, having an IELTS result is mandatory. Our adviser team can help you with various tips and information about IELTS preparation and exam. Book an appointment with us for a free IELTS preparation session.
                    </b-card-text>
                </b-card-body>
            </b-collapse>
        </b-card>
        <b-card no-body>
            <b-card-header header-tag="header">
                <b-button block v-b-toggle.accordion-7 class="faq-title">HOW TO APPLY FOR YOUR VISA?</b-button>
            </b-card-header>
            <b-collapse id="accordion-7" accordion="my-accordion">
                <b-card-body>
                    <b-card-text>
                      <div class="panel-body toggle-content fusion-clearfix">
                        <p><strong class="cf-tweet-this cf-tt-target cf-tt-enabled cf-tt-out-of-bounds cf-tt-out-of-bounds-top cf-tt-element-attached-top cf-tt-element-attached-center cf-tt-target-attached-bottom cf-tt-target-attached-center">How?</strong></p>
                        <p>How you apply depends on the country you are applying from and where your visa application will be processed. The following sections explain how to apply from both inside and outside the UK.</p>
                        <ul>
                            <li>We strongly recommend having a photocopy of all documents before you apply for a visa</li>
                            <br>
                            <li>You can apply up to three months before the start date of your program. This is the earliest that you can apply (you can apply later). It currently costs about £310 to apply for a Tier-4 visa outside the UK, £422 within the UK standard method and £822 within the UK premium method</li>
                            <br>
                            <li>Apply for a Tier-4 student visa – make sure you provide all evidence that is required for your application– including your CAS statement (Maintenance fund and English language test certificate if you need).</li>
                            <br>
                        </ul>
                        <p><strong>Where can I apply?</strong></p>
                        <p>You apply in the country where you are living. If you are just visiting another country, you will have to return home to apply for Tier-4. Check on the Home Office website to find out where to apply in your country.</p>
                        <p><a href="https://www.gov.uk/find-a-visa-application-centre">https://www.gov.uk/find-a-visa-application-centre</a></p>
                        <p>If you require our help please&nbsp;<a href="https://ahzassociates.co.uk/book-an-appointment/">book an appointment</a>&nbsp;with our experienced team and our members will help and guide you to follow the UKVI latest visa application procedures.</p>
                        </div>
                    </b-card-text>
                </b-card-body>
            </b-collapse>
        </b-card>
        <b-card no-body>
            <b-card-header header-tag="header">
                <b-button block v-b-toggle.accordion-8 class="faq-title">VISA GUIDANCE</b-button>
            </b-card-header>
            <b-collapse id="accordion-8" accordion="my-accordion">
                <b-card-body>
                    <b-card-text>
                      <p>Once you meet all the requirements and university accepted&nbsp;your application, you are required to apply for a Tier 4 student visa in order to study in the&nbsp;UK. It might be a complicated process but you don’t have to worry while you get AHZ Associates next to you. Our&nbsp;experts&nbsp;will guide you all the way till you get a decision about your visa application.</p>
                    </b-card-text>
                </b-card-body>
            </b-collapse>
        </b-card>
    </div>
</template>

<script>
    export default {
        name: 'Accordion',
    };
</script>