import HomeBranding from '../pages/HomeBranding'
import Courses from '../pages/Courses.vue'
import CoursesDetails from '../pages/CoursesDetails.vue'
import Contact from '../pages/Contact.vue'
import HeadOffice from '../pages/HeadOffice.vue'
import Branches from '../pages/Branches.vue'
import HelpandSupport from '../pages/HelpandSupport.vue'
import Universities from '../pages/Universities.vue'
import UniversitiesDetails from '../pages/UniversitiesDetails.vue'
import Scholarship from '../pages/Scholarship.vue'
import AddmissionService from '../pages/AddmissionService.vue'
import AdmissionProcess from '../pages/AdmissionProcess.vue'
import AcomodationService from '../pages/AcomodationService.vue'
import PartnerUniversity from '../pages/PartnerUniversity.vue'
import SchoolCollegeService from '../pages/SchoolCollegeService.vue'
import ServiceCompanies from '../pages/ServiceCompanies.vue'
import AdvicePartner from '../pages/AdvicePartner.vue'
import ServiceEndToEnd from '../pages/ServiceEndToEnd.vue'
import MessageDirector from '../pages/MessageDirector.vue'
import MessageAdvisor from '../pages/MessageAdvisor.vue'
import TeamMember from '../pages/TeamMember.vue'
import Achivemnets from '../pages/Achivemnets.vue'
import ProfessionalRecognition from '../pages/ProfessionalRecognition.vue'
import Testimonials from '../pages/Testimonials.vue'
import JobOppotunities from '../pages/JobOppotunities.vue'
import WhoWeAre from '../pages/WhoWeAre.vue'
import MessageComlpience from '../pages/MessageComlpience.vue'

import Test from '../pages/Test'
import ContactMessages from '../pages/ContactMessages'
import Dashboard from '../pages/Dashboard'
import SignIn from '../pages/SignIn'
import AllServices from '../pages/AllServices'
import Blog from '../pages/BlogAndNews'
import BlogDetails from '../pages/BlogAndNewsDetails'

import NotFound from '../pages/elements/404'

export const routes = [
    {
        path: '/test',
        name: 'Test',
        component: Test
    },
    {
        path: '/contact-messages',
        name: 'ContactMessages',
        component: ContactMessages
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard
    },
    {
        path: '/signin',
        name: 'SignIn',
        component: SignIn
    },
    {
        path: '/',
        name: 'HomeBranding',
        component: HomeBranding
    },
    {
        path: '/courses',
        name: 'Courses',
        component: Courses
    },
    {
        path: '/courses-details/:id',
        name: 'CoursesDetails',
        component: CoursesDetails
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact
    },
    {
        path: '/uk-head-office',
        name: 'HeadOffice',
        component: HeadOffice
    },
    {
        path: '/global-branches',
        name: 'Branches',
        component: Branches
    },
    {
        path: '/universities',
        name: 'Universities',
        component: Universities
    },
    {
        path: '/universities-details/:id',
        name: 'UniversitiesDetails',
        component: UniversitiesDetails
    },
    {
        path: '/scholarship',
        name: 'Scholarship',
        component: Scholarship
    },
    {
        path: '/help-support',
        name: 'HelpandSupport',
        component: HelpandSupport
    },
    {
        path: '/service/:id',
        name: 'AllService',
        component: AllServices
    },
    {
        path: '/admission-service',
        name: 'AddmissionService',
        component: AddmissionService
    },
    {
        path: '/admission-process',
        name: 'AdmissionProcess',
        component: AdmissionProcess
    },
    {
        path: '/student-accommodation-service',
        name: 'AcomodationService',
        component: AcomodationService
    },
    {
        path: '/partner-university-services',
        name: 'PartnerUniversity',
        component: PartnerUniversity
    },
    {
        path: '/services-overseas-school-college',
        name: 'SchoolCollegeService',
        component: SchoolCollegeService
    },
    {
        path: '/services-gov-private-companies',
        name: 'ServiceCompanies',
        component: ServiceCompanies
    },
    {
        path: '/advice-for-parents',
        name: 'AdvicePartner',
        component: AdvicePartner
    },
    {
        path: '/end-end-service',
        name: 'ServiceEndToEnd',
        component: ServiceEndToEnd
    },
    {
        path: '/message-from-the-ceo',
        name: 'MessageDirector',
        component: MessageDirector
    },
    {
        path: '/message-from-the-adviser',
        name: 'MessageAdvisor',
        component: MessageAdvisor
    },
    {
        path: '/job-opportunity',
        name: 'JobOppotunities',
        component: JobOppotunities
    },
    {
        path: '/who-we-are',
        name: 'WhoWeAre',
        component: WhoWeAre
    },
    {
        path: '/team-member',
        name: 'TeamMember',
        component: TeamMember
    },
    {
        path: '/achivement',
        name: 'Achivemnets',
        component: Achivemnets
    },
    {
        path: '/professional-recognition',
        name: 'ProfessionalRecognition',
        component: ProfessionalRecognition
    },
    {
        path: '/testimonials',
        name: 'Testimonials',
        component: Testimonials
    },
    {
        path: '/message-from-the-director',
        name: 'MessageComlpience',
        component: MessageComlpience
    },
    {
        path: '/blog-news',
        name: 'Blog',
        component: Blog
    },
    {
        path: '/blog-news-details/:id',
        name: 'BlogDetails',
        component: BlogDetails
    },
    {
        path: '*',
        name: 'NotFound',
        component: NotFound
    },
];