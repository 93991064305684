<template>
  <div>
    <HeaderElement/>
    <div class="banner-cutom">
      <img src="../assets/my-img/home/banner.png" alt="" width="100%">
    </div>
    <div class="section-title-wrapper text-center mb-1 mt-3">
        <h2 class="section-title mb-30">Wilor Education Ltd. Achievements for UK Student Admissions</h2>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-8">
        <!--
          <div class="row mb-5">
            <div class="col-md-4 my-3">
              <img width="100%" src="https://ahzassociates.co.uk/wp-content/uploads/2020/09/British-Council-Certification-400x566.jpg" alt="">
            </div>
            <div class="col-md-4 my-3">
              <img width="100%" src="https://ahzassociates.co.uk/wp-content/uploads/2020/09/British-Council-Certification-400x566.jpg" alt="">
            </div>
            <div class="col-md-4 my-3">
              <img width="100%" src="https://ahzassociates.co.uk/wp-content/uploads/2020/09/British-Council-Certification-400x566.jpg" alt="">
            </div>
            <div class="col-md-4 my-3">
              <img width="100%" src="https://ahzassociates.co.uk/wp-content/uploads/2020/09/British-Council-Certification-400x566.jpg" alt="">
            </div>
            <div class="col-md-4 my-3">
              <img width="100%" src="https://ahzassociates.co.uk/wp-content/uploads/2020/09/British-Council-Certification-400x566.jpg" alt="">
            </div>
            <div class="col-md-4 my-3">
              <img width="100%" src="https://ahzassociates.co.uk/wp-content/uploads/2020/09/British-Council-Certification-400x566.jpg" alt="">
            </div>
          </div>
          --->
        </div>
    </div>
    </div>

      <!-- footer section -->
      <FooterMain />

      <!-- offcanvas search component -->
      <OffcanvasSearchBox />
  </div>
</template>

<script>
import HeaderElement from '@/components/HeaderElementOthers'
import CourseBoxOne from '@/components/CourseBoxOne'
import CourseBoxTwo from '@/components/CourseBoxTwo'
import UniversityTab from '@/components/UniversityTab'
import Newsletter from '@/components/Newsletter'
import FooterMain from '@/components/FooterMain'
import OffcanvasSearchBox from '@/components/OffcanvasSearchBox'
import Accordion from '@/components/AdmissionAcordin'
import SideBarArea from '@/components/SideBarArea'
export default {
  components:{
    HeaderElement,
    CourseBoxOne,
    CourseBoxTwo,
    UniversityTab,
    Newsletter,
    FooterMain,
    OffcanvasSearchBox,
    Accordion,
    SideBarArea,
  }
}
</script>

<style scoped>
  .section-title-wrapper h2{
    font-size: 2.3rem !important;
  }
</style>
