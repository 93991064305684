<template>
  <div>
    <HeaderElement />
    <div class="banner-cutom">
      <img src="../assets/my-img/home/banner.png" alt="" width="100%" />
    </div>
    <div class="section-title-wrapper text-center mb-1 mt-3">
      <h2 class="section-title mb-30">
        Message from our Adviser
      </h2>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <div class="row">
            <div class="col-md-4">
              <div class="ht-team-member text-center">
                <div>
                  <!-- team-image Start -->
                  <div class="team-image">
                    <img
                      class="img-fluid"
                      src="../assets/img/team/avatar-01-140x140.png"
                      alt="avatar 01"
                    />
                  </div>
                  <!-- team-image End -->
                  <!-- team-info Start -->
                  <div class="team-info text-center">
                    <h5 class="name">Roger Brewer</h5>

                    <div class="position">Adviser</div>

                    <div class="social-networks-rounded mt-10">
                      <ul class="list ht-social-networks solid-rounded-icon">
                        <li class="item">
                          <a
                            target="_blank"
                            href="#"
                            class="social-link hint--bounce hint--top hint--primary"
                            aria-label="Facebook"
                            ><i class="fab fa-facebook"></i
                          ></a>
                        </li>
                        <li class="item">
                          <a
                            target="_blank"
                            href="#"
                            class="social-link hint--bounce hint--top hint--primary"
                            aria-label="Twitter"
                            ><i class="fab fa-twitter"></i
                          ></a>
                        </li>
                        <li class="item">
                          <a
                            target="_blank"
                            href="#"
                            class="social-link hint--bounce hint--top hint--primary"
                            aria-label="Instagram"
                            ><i class="fab fa-instagram"></i
                          ></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- team-info End -->
                </div>
              </div>
            </div>
            <div class="col-md-8 mb-5">
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius illo natus officiis asperiores corporis doloremque voluptate maiores animi repudiandae. Perspiciatis eveniet nulla nisi atque, error placeat eaque natus magnam culpa, blanditiis doloremque optio necessitatibus dolores asperiores velit aut id dolore autem veniam praesentium quam itaque deserunt. Natus, cupiditate. Obcaecati, maxime?
              </p>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius illo natus officiis asperiores corporis doloremque voluptate maiores animi repudiandae. Perspiciatis eveniet nulla nisi atque, error placeat eaque natus magnam culpa, blanditiis doloremque optio necessitatibus dolores asperiores velit aut id dolore autem veniam praesentium quam itaque deserunt. Natus, cupiditate. Obcaecati, maxime?
              </p>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius illo natus officiis asperiores corporis doloremque voluptate maiores animi repudiandae. Perspiciatis eveniet nulla nisi atque, error placeat eaque natus magnam culpa, blanditiis doloremque optio necessitatibus dolores asperiores velit aut id dolore autem veniam praesentium quam itaque deserunt. Natus, cupiditate. Obcaecati, maxime?
              </p>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius illo natus officiis asperiores corporis doloremque voluptate maiores animi repudiandae. Perspiciatis eveniet nulla nisi atque, error placeat eaque natus magnam culpa, blanditiis doloremque optio necessitatibus dolores asperiores velit aut id dolore autem veniam praesentium quam itaque deserunt. Natus, cupiditate. Obcaecati, maxime?
              </p>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius illo natus officiis asperiores corporis doloremque voluptate maiores animi repudiandae. Perspiciatis eveniet nulla nisi atque, error placeat eaque natus magnam culpa, blanditiis doloremque optio necessitatibus dolores asperiores velit aut id dolore autem veniam praesentium quam itaque deserunt. Natus, cupiditate. Obcaecati, maxime?
              </p>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius illo natus officiis asperiores corporis doloremque voluptate maiores animi repudiandae. Perspiciatis eveniet nulla nisi atque, error placeat eaque natus magnam culpa, blanditiis doloremque optio necessitatibus dolores asperiores velit aut id dolore autem veniam praesentium quam itaque deserunt. Natus, cupiditate. Obcaecati, maxime?
              </p>

            </div>
          </div>
        </div> 
      </div>
    </div> 

    <!-- footer section -->
    <FooterMain />

    <!-- offcanvas search component -->
    <OffcanvasSearchBox />
  </div>
</template>

<script>
import HeaderElement from "@/components/HeaderElementOthers";
import CourseBoxOne from "@/components/CourseBoxOne";
import CourseBoxTwo from "@/components/CourseBoxTwo";
import UniversityTab from "@/components/UniversityTab";
import Newsletter from "@/components/Newsletter";
import FooterMain from "@/components/FooterMain";
import OffcanvasSearchBox from "@/components/OffcanvasSearchBox";
import Accordion from "@/components/AdmissionAcordin";
import SideBarArea from "@/components/SideBarArea";
export default {
  components: {
    HeaderElement,
    CourseBoxOne,
    CourseBoxTwo,
    UniversityTab,
    Newsletter,
    FooterMain,
    OffcanvasSearchBox,
    Accordion,
    SideBarArea,
  },
};
</script>

<style scoped>
.section-title-wrapper h2 {
  font-size: 2.3rem !important;
}
</style>