<template>
  <div>
    <HeaderElement />
    <div class="banner-cutom">
      <img src="../assets/img/bg/branch.jpeg" alt="" width="100%" />
    </div>
    <div class="section-title-wrapper text-center mb-1 mt-3">
      <h2 class="section-title mb-30">
        Global Branches of Wilor Education Ltd
      </h2>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <button
            v-for="(value, index) in branchList"
            :key="index + 'cnt'"
            class="ht-btn ht-btn-md mb-2 mt-2 btn-block text-left"
            :class="
              currentBranch && value.id == currentBranch.id ? 'btn-active' : ''
            "
            @click="setCurrentBranch(value)"
          >
            <img :src="value.country_flag" alt="" style="height: 20px" />
            &nbsp; &nbsp; {{ value.country }}
          </button>
        </div>
        <div class="col-md-8 mx-auto">
          <div class="row mb-5 pb-5">
            <div class="col-md-12 contact-title" v-if="currentBranch">
              <img :src="currentBranch.logo" alt="" srcset="" width="100%" />
              <h2>{{ currentBranch.name }}</h2>
              <p>
                <i class="fas fa-map-marker-alt"></i>
                {{ currentBranch.address }}
              </p>

              <p v-if="currentBranch.city">
                <i class="fas fa-city"></i> {{ currentBranch.city }}
              </p>
              <p v-if="currentBranch.post_code">
                <i class="fas fa-map-marker-alt"></i>
                {{ currentBranch.post_code }}
              </p>
              <p v-if="currentBranch.country">
                <i class="fas fa-map-marked-alt"></i>
                {{ currentBranch.country }}
              </p>
              <p v-if="currentBranch.telephone">
                <i class="fas fa-phone-alt"></i> {{ currentBranch.telephone }}
              </p>
              <p v-if="currentBranch.email">
                <i class="fas fa-envelope-open-text"></i>
                {{ currentBranch.email }}
              </p>

              <div v-if="currentBranch.telephone == '+8801717229723'">
                <iframe
                class="mt-1"           
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3652.7277892888087!2d90.41931207594197!3d23.72141298988734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b851fe0bf80f%3A0xf6add458350af175!2s1st%20Floor%2C%20Property%20Heights%20Bhabon%2C%2012%20Ram%20Krishna%20Mission%20Rd%2C%20Dhaka%201203!5e0!3m2!1sen!2sbd!4v1700376763842!5m2!1sen!2sbd"
                width="100%"
                height="300"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
              ></iframe>
              </div>
              <div v-if="currentBranch.telephone == '+8801817279727'">
                <iframe
                class="mt-1"           
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3651.8517662492504!2d90.43854447594265!3d23.752664888688216!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b80563e5ee67%3A0xa44842beb85ad461!2s365%20Tech%20World!5e0!3m2!1sen!2sbd!4v1700378437720!5m2!1sen!2sbd"
                width="100%"
                height="300"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
              ></iframe>
              </div>
              <!-- <iframe
                class="mt-1"
                v-if="currentBranch.map_url"
                :src="currentBranch.map_url"
                width="100%"
                height="300"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
              ></iframe> -->
               <iframe
                class="mt-1"           
                :src="currentBranch.map_url"
                width="100%"
                height="300"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- footer section -->
    <FooterMain />

    <!-- offcanvas search component -->
    <OffcanvasSearchBox />
  </div>
</template>

<script>
import HeaderElement from "@/components/HeaderElementOthers";
import CourseBoxOne from "@/components/CourseBoxOne";
import CourseBoxTwo from "@/components/CourseBoxTwo";
import CourseTab from "@/components/CourseTab";
import Newsletter from "@/components/Newsletter";
import FooterMain from "@/components/FooterMain";
import OffcanvasSearchBox from "@/components/OffcanvasSearchBox";
export default {
  components: {
    HeaderElement,
    CourseBoxOne,
    CourseBoxTwo,
    CourseTab,
    Newsletter,
    FooterMain,
    OffcanvasSearchBox,
  },
  data() {
    return {
      branchList: [],
      currentBranch: null,
    };
  },
  created() {
    this.getBranch();
  },

  methods: {
    getBranch() {
      axios.get("country-branch-list").then((response) => {
        this.branchList = response.data.application.country_branch_list;
        if (response.data.application.country_branch_list.length > 0) {
          this.currentBranch = response.data.application.country_branch_list[0];
        }
      });
    },

    setCurrentBranch(branch) {
      this.currentBranch = branch;
    },
  },
};
</script>

<style scoped>
.section-title-wrapper h2 {
  font-size: 2.3rem !important;
}
.contact-title h2 {
  font-size: 1.5rem !important;
}
.contact-title p {
  margin-bottom: 0px !important;
}
.contact-title img {
  width: 60%;
}
.btn-active {
  box-shadow: 0px 5px 10px rgb(68, 66, 66);
}
</style>
