<template>
  <div>
    <HeaderElement />
    <div class="banner-cutom">
      <img src="../assets/img/bg/whyus.jpeg" alt="" width="100%" />
    </div>
    <div class="section-title-wrapper text-center mb-1 mt-3">
      <h2 class="section-title mb-30">Professional Recognition</h2>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-3" v-for="logo in logo_list" :key="logo.id">
          <!-- <p><b>British Council: Education UK Agents Training Certificate</b></p> -->
          <a href="" @click.prevent="showModal(logo.image)">
            <img
              :src="logo.image"
              class="img-thumbnail mt-2 mb-2"
              alt=""
              srcset=""
            />
          </a>
        </div>

        <b-modal id="modal-multi-1" size="lg" title="" ok-only no-stacking>
          <img :src="currentImage" alt="" />
        </b-modal>
      </div>
    </div>

    <!-- footer section -->
    <FooterMain />

    <!-- offcanvas search component -->
    <OffcanvasSearchBox />
  </div>
</template>

<script>
import HeaderElement from "@/components/HeaderElementOthers";
import CourseBoxOne from "@/components/CourseBoxOne";
import CourseBoxTwo from "@/components/CourseBoxTwo";
import UniversityTab from "@/components/UniversityTab";
import Newsletter from "@/components/Newsletter";
import FooterMain from "@/components/FooterMain";
import OffcanvasSearchBox from "@/components/OffcanvasSearchBox";
import Accordion from "@/components/AdmissionAcordin";
import SideBarArea from "@/components/SideBarArea";
export default {
  components: {
    HeaderElement,
    CourseBoxOne,
    CourseBoxTwo,
    UniversityTab,
    Newsletter,
    FooterMain,
    OffcanvasSearchBox,
    Accordion,
    SideBarArea,
  },
  data() {
    return {
      logo_list: [],
      currentImage: "",
    };
  },
  mounted() {
    this.getWhyUsLogo();
  },
  methods: {
    getWhyUsLogo() {
      axios
        .get("professional-recognition?title=professional_recognition")
        .then((response) => {
          this.logo_list = response.data.data.pr_logo;
        });
    },

    showModal(image) {
      this.currentImage = image;
      this.$bvModal.show("modal-multi-1");
    },
  },
};
</script>

<style scoped>
.section-title-wrapper h2 {
  font-size: 2.3rem !important;
}
</style>
