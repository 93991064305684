<template>
  <div class="col-md-4 text-center">
      <div>
        <img src="https://ahzassociates.co.uk/wp-content/uploads/2020/09/Find-UK-Universities@2x.png" alt="">
      </div>
      <div>
          <img src="https://ahzassociates.co.uk/wp-content/uploads/2020/09/Select-Your-Course@2x.png" alt="">
      </div>
      <div>
          <img src="https://ahzassociates.co.uk/wp-content/uploads/2020/09/IELTS-Registration@2x.png" alt="">
      </div>
      <div>
          <img src="https://ahzassociates.co.uk/wp-content/uploads/2020/09/English-UK@2x.png" alt="">
      </div>
      <div>
          <img src="https://ahzassociates.co.uk/wp-content/uploads/2020/09/BCEU@2x-300x113.png" alt="">
      </div>
      <div>
          <img src="https://ahzassociates.co.uk/wp-content/uploads/2020/09/UCAS@2x-1-300x113.png" alt="">
      </div>
      <div>
          <img src="https://ahzassociates.co.uk/wp-content/uploads/2020/09/UK-Study-Guide@2x-300x113.png" alt="">
      </div>
      <div>
          <img src="" alt="">
      </div>
      <div>
          <img src="" alt="">
      </div>
      <div>
          <img src="" alt="">
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>