<template>
  <div class="faq-wrapper section-space--pt_90">
    <div class="section-elements-title mb-30">
      <form @submit.prevent="submit()">
        <h4 style="font-size: 1.7rem">Application Form</h4>
        <div class="progress-bar--one">
          <!-- Start Single Progress Charts -->
          <div class="progress-charts wow move-up">
            <h6 class="heading mb-5 mt-3">Step {{ currentStep }} of 4</h6>
            <div class="progress">
              <div
                class="progress-bar gradient-1"
                role="progressbar"
                :style="{ width: currentPercentage + '%' }"
                :aria-valuenow="currentPercentage"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <span class="percent-label">{{ currentPercentage }}%</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="step0">
            <div
              class="col-md-12"
              v-if="validation_error"
              style="margin-top: 20px"
            >
              <div class="form-group">
                <div>
                  <ul>
                    <li class="text-danger" v-for="error in validation_error">
                      {{ error[0] }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div v-if="currentStep == 1" class="step1">
            <div class="form-group">
              <label for="sel1"><b>Month</b></label>
              <select
                class="form-control"
                id="sel1"
                v-model="application_form.month"
              >
                <option
                  v-for="value in month_list"
                  :key="value.month"
                  :value="value.month"
                >
                  {{ value.name }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="sel1"><b>Year</b></label>
              <select
                class="form-control"
                id="sel1"
                v-model="application_form.year"
              >
                <option
                  v-for="year in getYearList"
                  :value="year"
                  :key="year + 'year'"
                >
                  {{ year }}
                </option>
              </select>
            </div>
          </div>

          <div v-if="currentStep == 2" class="step2">
            <div class="form-group">
              <label for="sel1"><b>UKVI IELTS?*</b></label> <br />
              <input
                type="radio"
                name="ukvi"
                id=""
                v-model="application_form.ukvi"
                :value="1"
              />
              Yes <br />
              <input
                type="radio"
                name="ukvi"
                id=""
                v-model="application_form.ukvi"
                :value="2"
              />
              No
            </div>
            <div class="form-group" v-if="application_form.ukvi === 1">
              <label for="sel1"><b>Your Grade *</b></label>
              <select
                class="form-control"
                id="sel1"
                v-model="application_form.overall_band"
              >
                <option value="">Please Choose Score</option>
                <option value="4">Overall Band Score 4</option>
                <option value="4.5">Overall Band Score 4.5</option>
                <option value="5">Overall Band Score 5</option>
                <option value="5.5">Overall Band Score 5.5</option>
                <option value="6">Overall Band Score 6</option>
                <option value="6.5">Overall Band Score 6.5</option>
                <option value="7">Overall Band Score 7</option>
                <option value="7.5">Overall Band Score 7.5</option>
                <option value="8">Overall Band Score 8</option>
                <option value="8.5">Overall Band Score 8.5</option>
                <option value="9">Overall Band Score 9</option>
              </select>
            </div>

            <div class="form-group" v-if="application_form.ukvi === 2">
              <label for="sel1"><b>Planned Exam Date *</b></label>
              <input
                type="text"
                v-model="application_form.plan_date"
                name=""
                id=""
                class="form-control"
                placeholder="Planned Exam Date"
              />
            </div>

            <div class="form-group">
              <label for="sel1"><b>Current Country *</b></label>
              <select
                class="form-control"
                id="sel1"
                v-model="application_form.country"
                @change="uniList()"
              >
                <option value="">Please Choose a Country</option>
                <option
                  v-for="(country, index) in countryList"
                  :value="country.country"
                  :key="index + 'country'"
                >
                  {{ country.country }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label for="sel1"
                ><b>University you are interested in * </b></label
              >
              <select
                class="form-control"
                id="sel1"
                v-model="application_form.university"
                @change="getCourseList()"
              >
                <option value="">Please Select</option>
                <option
                  v-for="uni in universityList"
                  :key="uni.institution_id"
                  :value="uni.institution_id"
                >
                  {{ uni.name }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label for="sel1"><b>Course you are Interested in *</b></label>
              <select
                class="form-control"
                id="sel1"
                v-model="application_form.course"
                @change="getCoursLevel()"
              >
                <option value="">Please Select</option>
                <option
                  v-for="course in courseList"
                  :key="'crs' + course.id"
                  :value="course.title"
                >
                  {{ course.title }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label for="sel1"><b>Interested Course Level *</b></label>
              <select
                class="form-control"
                id="sel1"
                v-model="application_form.course_level"
              >
                <option value="">Please Select</option>
                <option
                  v-for="course in coureLevel"
                  :key="'crs_lve' + course.id"
                  :value="course.level"
                >
                  {{ course.level }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label for="sel1"><b>Current Qualification *</b></label>
              <input
                class="form-control"
                v-model="application_form.current_qualification"
                type="text"
                name=""
                id=""
                placeholder="Bachelor of Computer Science & Engineering"
              />
            </div>
          </div>
          <div v-if="currentStep == 3" class="step2">
            <div class="form-group">
              <label for="sel1"><b>First Name *</b></label>
              <input
                class="form-control"
                v-model="application_form.first_name"
                type="text"
                name=""
                id=""
                placeholder="First Name"
              />
            </div>
            <div class="form-group">
              <label for="sel1"><b>Last Name *</b></label>
              <input
                class="form-control"
                v-model="application_form.last_name"
                type="text"
                name=""
                id=""
                placeholder="Last Name"
              />
            </div>
            <div class="form-group">
              <label for="sel1"><b>Phone *</b></label>
              <input
                class="form-control"
                v-model="application_form.phone"
                type="text"
                name=""
                id=""
                placeholder="Phone"
              />
            </div>
            <div class="form-group">
              <label for="sel1"><b>Email * </b></label>
              <input
                class="form-control"
                v-model="application_form.email"
                type="email"
                name=""
                id=""
                placeholder="Email"
              />
            </div>
          </div>
          <div v-if="currentStep == 4" class="step2">
            <div class="form-group">
              <label for="sel1"><b>Upload any Important Documents </b></label>
              <input
                class="form-control"
                type="file"
                placeholder="Upload"
                @change="onImageChange"
                id="application_file"
              />
            </div>
            <div class="form-group">
              <label for="sel1"><b>Your Comment </b></label>
              <textarea
                v-model="application_form.comment"
                class="form-control"
                placeholder="Pleae write down if you have any comment"
              ></textarea>
            </div>
          </div>
        </div>

        <a
          class="ht-btn ht-btn-sm mr-1"
          v-if="currentStep > 1"
          @click.prevent="perviousStep()"
          >Previous</a
        >
        <a
          class="ht-btn ht-btn-sm"
          v-if="currentStep < 4"
          @click.prevent="nextStep()"
          >NEXT</a
        >

        <button
          @click="submit()"
          class="ht-btn ht-btn-sm"
          v-if="currentStep == 4"
        >
          <span v-if="isLoading">Requesting.....</span>
          <span> Request to Review Application </span>
        </button>
        <!-- <span>Save and Cont</span> -->
      </form>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      application_form: {
        month: "00",
        year: new Date().getFullYear(),
        ukvi: "",
        country: "",
        course_level: "",
        course: "",
        university: "",
        current_qualification: "",
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        comment: "",
        upload_file: "",
        overall_band: "",
        plan_date: "",
        upload_file: "",
      },

      formData: new FormData(),

      month_list: [
       { month: "", name: "" },
         { month: "01", name: "January" },
        // { month: "02", name: "February" },
        // { month: "03", name: "March" },
        // { month: "04", name: "April" },
        { month: "05", name: "May" },
        { month: "06", name: "June" },
        { month: "07", name: "July" },
        // { month: "08", name: "August" },
         { month: "09", name: "September" },
         { month: "10", name: "October" },
        // { month: "11", name: "November" },
        // { month: "12", name: "December" },
      ],

      countryList: [],
      universityList: [],
      courseList: [],
      coureLevel: [],

      currentStep: 1,
      currentPercentage: 25,
      validation_error: [],
      isLoading: false,

      stepTwoError: {},
    };
  },

  mounted() {
    this.getCountryList();
  },
  methods: {
    nextStep() {
      // step 2 validation
      if (this.currentStep === 2) {
        if (
          this.application_form.ukvi == "" ||
          this.application_form.country == "" ||
          this.application_form.university == "" ||
          this.application_form.course == "" ||
          this.application_form.course_level == "" ||
          this.application_form.current_qualification == ""
        ) {
          this.completeMessage({
            status: "error",
            message: "All field with * are required",
          });
          return false;
        }

        // check ukvi

        if (
          this.application_form.ukvi === 2 &&
          this.application_form.plan_date == ""
        ) {
          this.completeMessage({
            status: "error",
            message: "Please fill Plan Exam Date",
          });
          return false;
        }
        if (
          this.application_form.ukvi === 1 &&
          this.application_form.overall_band == ""
        ) {
          this.completeMessage({
            status: "error",
            message: "Please Choose Your Grade Score",
          });
          return false;
        }
      }
      if (this.currentStep === 3) {
        if (
          this.application_form.first_name == "" ||
          this.application_form.last_name == "" ||
          this.application_form.phone == "" ||
          this.application_form.email == ""
        ) {
          this.completeMessage({
            status: "error",
            message: "All field with * are required",
          });
          return false;
        }
      }
      if (this.currentStep != 4) {
        this.currentStep += 1;
        this.currentPercentage += 25;
      }
    },
    perviousStep() {
      this.currentStep -= 1;
      this.currentPercentage -= 25;
    },

    getCountryList() {
      axios.get("country-list").then((response) => {
        this.countryList = response.data.data;
      });
    },
    uniList() {
      this.application_form.university = "";
      this.application_form.course = "";
      this.application_form.course_level = "";
      axios
        .get("university-list?country=" + this.application_form.country)
        .then((response) => {
          this.universityList = response.data.data.all_university;
        });
    },

    getCourseList() {
      this.application_form.course = "";
      this.application_form.course_level = "";
      axios
        .get("course-list?university_id=" + this.application_form.university)
        .then((response) => {
          this.courseList = response.data.data.course_list;
        });
    },

    getCoursLevel() {
      this.application_form.course_level = "";
      axios
        .get("course-level?course_title=" + this.application_form.course)
        .then((response) => {
          this.coureLevel = response.data.data.course_level;
        });
    },

    onImageChange(e) {
      if (e == "") return false;
      // this.operator.picture = this.$refs.file.files[0];
      this.application_form.upload_file = document.getElementById(
        "application_file"
      ).files[0];
    },
    prepareFields() {
      this.formData.append("first_name", this.application_form.first_name);
      this.formData.append("last_name", this.application_form.last_name);
      this.formData.append("phone", this.application_form.phone);
      this.formData.append("email", this.application_form.email);
      this.formData.append("month", this.application_form.month);
      this.formData.append("year", this.application_form.year);
      this.formData.append("is_ukvi_ielts", this.application_form.ukvi);
      this.formData.append("overall_band", this.application_form.overall_band);
      this.formData.append("plan_date", this.application_form.plan_date);
      this.formData.append("current_country", this.application_form.country);
      this.formData.append(
        "interested_course_level",
        this.application_form.course_level
      );
      this.formData.append("course", this.application_form.course);
      this.formData.append("university", this.application_form.university);
      this.formData.append(
        "latest_qualification",
        this.application_form.current_qualification
      );
      this.formData.append("upload_file", this.application_form.upload_file);
    },

    submit() {
      this.prepareFields();
      this.isloading = true;
      axios
        .post("application-form", this.formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          this.isloading = false;
          if (response.data.status === "success") {
            this.completeMessage(response.data);
            this.formReset();
          }
        })
        .catch((err) => {
          this.isloading = false;
          if (err.response.status == 422) {
            this.validation_error = err.response.data.errors;
            this.completeMessage({
              status: "error",
              message: "validation error",
            });
          } else {
            this.completeMessage(err.data);
            this.isloading = false;
          }
        });
    },

    completeMessage(data) {
      Swal.fire({
        icon: data.status,
        title: data.status == "error" ? "Oops..." : "Success",
        text: data.message,
      });
    },

    formReset() {
      this.application_form = {
        month: "01",
        year: new Date().getFullYear() + 1,
        ukvi: "",
        country: "",
        course_level: "",
        course: "",
        university: "",
        current_qualification: "",
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        comment: "",
        upload_file: "",
        overall_band: "",
        plan_date: "",
        upload_file: "",
      };
      this.validation_error = [];
      this.isloading = false;
    },
  },

  computed: {
    getYearList() {
      let currentYear = new Date().getFullYear();
      let startYear = Number(currentYear + 0);
      let stopYear = Number(currentYear + 5);
      let yearArray = [];
      for (startYear; startYear < stopYear; startYear++) {
        yearArray.push(startYear);
      }
      return yearArray;
    },
  },
};
</script>
